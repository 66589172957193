import { stringifyUrl } from "query-string";
import type {
  SandwichMarketOverviewParams,
  SandwichMarketOverview,
  SandwichMarketOverviewResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichMarketOverview(
  options: SandwichMarketOverviewParams
) {
  const url = "/api/v1/dataservice/sandwich/marketOverview/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichMarketOverviewResponseJson,
    SandwichMarketOverview
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
