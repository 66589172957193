import { stringifyUrl } from "query-string";
import type {
  LendingTopEventParams,
  LendingTopEvent,
  LendingTopEventResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLendingTopEvents(options: LendingTopEventParams) {
  const url = "/api/v1/dataservice/lending/topLendings/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LendingTopEventResponseJson,
    {
      data: Array<LendingTopEvent>;
      totalCount: number;
    }
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
