import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationLiquidatorGasPriceResponseJson,
  LiquidationLiquidatorGasPrice,
  LiquidationLiquidatorGasPriceParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";
import { compressByTimestamp } from "../../Components/Utils";

export async function fetchLiquidationLiquidatorGasPrice(
  options: LiquidationLiquidatorGasPriceParams
) {
  const url = "/api/v1/dataservice/liquidation/liquidatorGasPrice/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationLiquidatorGasPriceResponseJson,
    {
      rows: Array<LiquidationLiquidatorGasPrice>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    const list = (data?.result?.data ?? []).map((item) => ({
      ...item,
      key: uuid(),
    }));

    const filterList =
      list.length > 300
        ? compressByTimestamp(list, {
            keyName: "gasprice",
            sampling: "MIN&MAX",
          })
        : list;
    // console.log("gasprice sampling:", list.length, filterList.length);

    return {
      rows: filterList as Array<LiquidationLiquidatorGasPrice>,
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
