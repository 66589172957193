import React, { useEffect } from "react";
import {
  Box,
  Group,
  Paper,
  Header as MHeader,
  Menu,
  Space,
  ActionIcon,
  Text,
  Burger,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { useIsSmallerThanMD, useIsSmallScreen } from "../Components/Utils";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";
import { Link as InterLink, useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "@mantine/core";
import NavTabs from "./Component/NavTabs";
import { useGlobalStatusContext } from "../GlobalStore";
import LoginWrapper from "./Component/LoginWrapper";
import GoogleLoginButton from "./Component/GoogleLoginButton/GoogleLoginButton";

const NavbarEnter = () => {
  const {
    setNavExpand,
    user,
    navVisibleOnSmallScreen,
    setNavVisibleOnSmallScreen,
  } = useGlobalStatusContext();

  if (user) {
    return (
      <Box
        onClick={() => {
          setNavVisibleOnSmallScreen(!navVisibleOnSmallScreen);
          setNavExpand(true);
        }}
      >
        <ActionIcon w={40} h={40} color="cyan" variant="filled">
          <img src={user.photoURL!} style={{ borderRadius: 6 }} alt="" />
        </ActionIcon>
      </Box>
    );
  }

  return (
    <Burger
      size={"sm"}
      opened={navVisibleOnSmallScreen}
      onClick={() => {
        setNavVisibleOnSmallScreen(!navVisibleOnSmallScreen);
        setNavExpand(true);
      }}
    />
  );
};

export default function Header({ isExtend }: { isExtend: boolean }) {
  const isSmallScreen = useIsSmallScreen();
  const isSmallerThanMD = useIsSmallerThanMD();

  const { ref } = useElementSize();
  const { setNavExpand, setUser, user } = useGlobalStatusContext();
  const { navVisibleOnSmallScreen, setNavVisibleOnSmallScreen } =
    useGlobalStatusContext();

  const firebaseAuth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // 修复移动端滚动穿透的问题
  useEffect(() => {
    if (isSmallScreen) {
      if (navVisibleOnSmallScreen) {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "overflow:hidden;position:fixed;width:100%;top:-" + scrollTop + "px;";
      } else {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
        body.style.cssText = "overflow:auto";
      }
    }
  }, [isSmallScreen, navVisibleOnSmallScreen]);

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user: User | null) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("User is signed in");
        setUser(user);
      } else {
        // User is signed out
        console.log("User is signed out");
        setUser(null);
      }
    });
  }, []);

  return (
    <MHeader
      height={isSmallScreen ? 60 : 80}
      ref={ref}
      zIndex={201}
      sx={{
        position: "relative",
        backgroundColor: "transparent",
        borderBottom: "1px solid rgba(255,255,255,0.13)",
      }}
    >
      <Paper
        shadow="sm"
        component="nav"
        py="md"
        px={20}
        sx={{
          borderRadius: 0,
          backgroundColor: "transparent",
          maxWidth: 1200,
          margin: "0 auto",
        }}
      >
        <Group
          position="apart"
          noWrap
          sx={{ gap: 10, height: isSmallScreen ? 26 : 46 }}
        >
          <Box>
            <Group spacing={40} noWrap>
              <InterLink
                style={{
                  textDecoration: "none",
                }}
                to="/"
                onClick={() => {
                  document.getElementById("top")?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                  });
                }}
              >
                <img
                  src="/eigenapi/logo.svg"
                  height={isSmallScreen ? 26 : 41}
                  width={(isSmallScreen ? 26 : 41) * 4.075}
                />
              </InterLink>
              {isSmallScreen ? null : (
                <NavTabs
                  value={location.pathname}
                  styles={{
                    tab: {
                      "&:hover": {
                        background: "rgba(126,130,153,0.16)",
                      },
                    },
                    tabLabel: {
                      fontSize: 15,
                    },
                  }}
                >
                  <Tabs.List>
                    {firebaseAuth.currentUser ? (
                      <Tabs.Tab
                        value="/apikey"
                        onClick={(el) => {
                          navigate("/apikey");
                        }}
                      >
                        API Key
                      </Tabs.Tab>
                    ) : null}
                    <Tabs.Tab
                      value="/apidocument"
                      onClick={(el) => {
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href =
                          "https://eigenphi-1.gitbook.io/eigenapi-user-guide/introduction/welcome";
                        a.target = "_blank";
                        a.rel = "noreferrer";
                        a.click();
                        a.remove();
                      }}
                    >
                      API Document
                    </Tabs.Tab>
                  </Tabs.List>
                </NavTabs>
              )}
            </Group>
          </Box>
        </Group>
      </Paper>
      <Group
        spacing={0}
        noWrap
        sx={{
          position: "absolute",
          right: isSmallerThanMD ? 16 : 40,
          top: 0,
          height: isSmallScreen ? 60 : 80,
        }}
      >
        {user ? (
          <Group spacing={4}>
            {isSmallScreen ? null : (
              <Menu trigger="hover" position="bottom-end">
                <Menu.Target>
                  <ActionIcon w={40} h={40}>
                    <img
                      src={user.photoURL!}
                      style={{ borderRadius: 6 }}
                      alt=""
                    />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item>
                    <Group noWrap>
                      <ActionIcon w={40} h={40}>
                        <img
                          src={user.photoURL!}
                          style={{ borderRadius: 6 }}
                          alt=""
                        />
                      </ActionIcon>
                      <Box>
                        <Text
                          sx={(t) => ({
                            color: t.colorScheme === "dark" ? t.white : t.black,
                          })}
                          fw={700}
                          size={16}
                        >
                          {user.displayName}
                        </Text>
                        <Text
                          sx={(t) => ({
                            color: t.colorScheme === "dark" ? t.white : t.black,
                          })}
                          size={14}
                        >
                          {user.email}
                        </Text>
                      </Box>
                    </Group>
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      navigate("/apikey");
                    }}
                  >
                    My API Key
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      signOut(firebaseAuth);
                      setUser(null);
                      navigate("/");
                    }}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>
        ) : (
          <LoginWrapper>
            {({ checkLogin }: any) => (
              <GoogleLoginButton onClick={checkLogin} />
            )}
          </LoginWrapper>
        )}
        {isSmallScreen ? <NavbarEnter /> : null}
      </Group>
    </MHeader>
  );
}
