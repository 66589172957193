import { stringifyUrl } from "query-string";
import type {
  ArbitrageDailyReport,
  ArbitrageDailyReportResponseJson,
  ArbitrageDailyReportParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchArbitrageDailyReport(
  options: ArbitrageDailyReportParams
) {
  const url = "/api/v1/report/dailyReport";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    ArbitrageDailyReportResponseJson,
    ArbitrageDailyReport
  >(fetch(endpoint), (data) => {
    const { code, message } = data as any;
    return { ...data?.result, code, message } ?? { code, message };
  });
}
