import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  SandwichAttackersLeaderboardParams,
  SandwichAttackersLeaderboardItem,
  SandwichAttackersLeaderboardResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichAttackersLeaderboard(
  options: SandwichAttackersLeaderboardParams
) {
  const url = "/api/v1/dataservice/sandwich/attackers/leaderboard/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichAttackersLeaderboardResponseJson,
    Array<SandwichAttackersLeaderboardItem>
  >(fetch(endpoint), (data) => {
    return (data?.result ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
