import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationTopLiquidatorParams,
  LiquidationTopLiquidator,
  LiquidationTopLiquidatorResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationLiqudators(
  options: LiquidationTopLiquidatorParams
) {
  const url = "/api/v1/dataservice/liquidation/topLiquidators/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationTopLiquidatorResponseJson,
    Array<LiquidationTopLiquidator>
  >(fetch(endpoint), (data) => {
    return (data?.result?.data ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
