import { stringifyUrl } from "query-string";
import type {
  GenericParams,
  Token,
  TokenResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchToken(options: GenericParams) {
  const url = "/api/v1/dataservice/token/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    TokenResponseJson,
    Token
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
