import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationDetail,
  LiquidationDetailParams,
  LiquidationDetailResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationDetails(
  options: LiquidationDetailParams
) {
  const url = "/api/v1/dataservice/tx/liquidations/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationDetailResponseJson,
    Array<LiquidationDetail>
  >(fetch(endpoint), (data) => {
    return (data?.result?.details ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid,
    }));
  });
}
