import { stringifyUrl } from "query-string";
import type {
  VictimSearchParams,
  VictimSearchResultItem,
  VictimSearchResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchVictimSearchResult(params: VictimSearchParams) {
  const url = "/api/v1/dataservice/victim_search/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    VictimSearchResponseJson,
    Array<VictimSearchResultItem>
  >(fetch(endpoint), (data) => {
    return data?.result?.items;
  });
}
