import React from "react";
import { Group, Box, Center, Text } from "@mantine/core";
import { FiAlertCircle } from "react-icons/fi";

export function EpAlert({
  height = 200,
  children,
  icon,
}: {
  height?: number;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}) {
  return (
    <Box
      sx={(theme) => ({
        background:
          theme.colorScheme === "light" ? theme.white : theme.colors.dark[7],
        margin: "20px 0",
        height,
        display: "flex",
        justifyContent: "center",
      })}
    >
      <Center>
        <Group position="center" sx={{ flexDirection: "column" }}>
          {icon ? icon : <FiAlertCircle size={37.5} color="#a6a6a6" />}
          <Text
            size={"md"}
            sx={(theme) => ({
              textAlign: "center",
              color:
                theme.colorScheme === "light"
                  ? theme.colors.dark[6]
                  : theme.white,
            })}
          >
            {children}
          </Text>
        </Group>
      </Center>
    </Box>
  );
}
