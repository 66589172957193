import { stringifyUrl } from "query-string";
import {
  Feedback,
  FeedbackParams,
  FeedbackResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchFeedback(params: FeedbackParams) {
  const url = "/api/v1/dataservice/feedback";

  const endpoint = stringifyUrl({ url, query: params });

  const feedback = await normalizeResponse<
    FeedbackResponseJson,
    Feedback
  >(fetch(endpoint), (data) => {
    return data.result;
  });

  return feedback;
}
