import { stringifyUrl } from "query-string";
import type {
  TokenPriceParams,
  TokenPriceItem,
  TokenPriceResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const tokenAddressMap: Array<{ token: string; address: string }> = [
  {
    token: "ETH",
    address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  },
];

const getQuery = (params: TokenPriceParams) => {
  const { chain, tokenTypes = ["ETH"], timestamps } = params;

  const tokenAddresses = tokenTypes.map((tokenType: string) => {
    const { address } =
      tokenAddressMap.find((item) => item.token === tokenType) || {};

    return address;
  });

  return {
    chainName: chain,
    timestamps: timestamps,
    tokenAddresses,
  } as any;
};

export async function fetchTokenPrice(params: TokenPriceParams) {
  const url = "/api/v1/arbitrage/token/price/";

  const endpoint = stringifyUrl({ url });

  return await normalizeResponse<TokenPriceResponseJson, Array<TokenPriceItem>>(
    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(getQuery(params)),
    } as RequestInit),
    (data) => {
      const result = data.result.map((item: TokenPriceItem) => {
        const { token } =
          tokenAddressMap.find(
            ({ address }) => address === item.tokenAddress
          ) || {};

        return {
          ...item,
          tokenType: token,
        } as TokenPriceItem;
      });

      return result;
    }
  );
}
