import React, { useState } from "react";
import {
  Group,
  Anchor,
  Tooltip,
  Skeleton,
  ActionIcon,
  Menu,
  Loader,
  Modal,
  Image,
  useMantineTheme,
  Divider,
  Box,
} from "@mantine/core";
import {
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  RedditIcon,
  RedditShareButton,
} from "react-share";
import { EtherScan, BscScan, Tenderly } from "./EpIcon";
import { useGlobalStatusContext } from "../GlobalStore";
import { useIsSmallScreen } from "../Components/Utils";
import html2canvas from "html2canvas";
import { MdDownloadForOffline } from "react-icons/md";
import { jsPDF } from "jspdf";
import { EpAlert } from "./EpMessage";
import { Link } from "./Link";

export type ShareBarOptions = {
  shareTitle?: string;
  shareUrl?: string;
  viewOnAddress?: string;
  viewOnTx?: string;
  hideAnalyseTool?: boolean;
  isLoading?: boolean;
  chain?: string;
  download?: {
    downloadPDFSelector?: string;
    downloadLinks?: {
      text: string;
      url: string;
    }[];
  };
};

export default function ShareBar({
  shareTitle,
  shareUrl,
  viewOnAddress,
  viewOnTx,
  hideAnalyseTool,
  isLoading,
  download,
  chain,
}: ShareBarOptions) {
  const { chain: chianGlobal } = useGlobalStatusContext();

  chain = chain ?? chianGlobal;
  const isSmallScreen = useIsSmallScreen();

  const [pdfGenerateOpened, setPdfGenerateOpened] = useState(false);

  const theme = useMantineTheme();
  const chainAddress = (
    <Anchor
      href={`https://${
        chain === "bsc" ? "bscscan.com" : "etherscan.io"
      }/address/${viewOnAddress}`}
      target="_blank"
    >
      {chain === "bsc" ? <BscScan /> : <EtherScan />}
    </Anchor>
  );

  const tenderlyContract = (
    <Anchor
      href={`https://dashboard.tenderly.co/contract/${
        chain === "bsc" ? "bsc" : "mainnet"
      }/${viewOnAddress}`}
      target="_blank"
    >
      <Tenderly />
    </Anchor>
  );

  const analysisToolTx = (
    <Anchor component={Link} to={`/eigentx/${viewOnTx}`} target="_blank">
      <Group
        noWrap
        spacing={4}
        sx={{
          padding: 4,
          borderRadius: 2,
          ":hover": {
            backgroundColor:
              theme.colorScheme === "light"
                ? theme.colors.gray[1]
                : theme.colors.dark[5],
          },
        }}
      >
        <Image src="/images/eigentx/analysisTools.png" height={16} width={16} />
      </Group>
    </Anchor>
  );

  const chainTx = (
    <Anchor
      href={`https://${chain === "bsc" ? "bscscan.com" : "etherscan.io"}/tx/${
        viewOnTx?.split(",")[0]
      }`}
      target="_blank"
    >
      <ActionIcon
        sx={{
          padding: 4,
          borderRadius: 2,
          ":hover": {
            backgroundColor:
              theme.colorScheme === "light"
                ? theme.colors.gray[1]
                : theme.colors.dark[5],
          },
        }}
      >
        {chain === "bsc" ? <BscScan /> : <EtherScan />}
      </ActionIcon>
    </Anchor>
  );

  const tenderlyTx = (
    <Anchor
      href={`https://dashboard.tenderly.co/tx/${
        chain === "bsc" ? "bsc" : "mainnet"
      }/${viewOnTx?.split(",")[0]}`}
      target="_blank"
    >
      <ActionIcon
        sx={{
          padding: 4,
          borderRadius: 2,
          ":hover": {
            backgroundColor:
              theme.colorScheme === "light"
                ? theme.colors.gray[1]
                : theme.colors.dark[5],
          },
        }}
      >
        <Tenderly />
      </ActionIcon>
    </Anchor>
  );

  const twitter = (
    <TwitterShareButton
      title={shareTitle}
      url={shareUrl!}
      style={{ display: "flex" }}
    >
      <TwitterIcon size={16} round={true} style={{ verticalAlign: "bottom" }} />
    </TwitterShareButton>
  );

  const telegram = (
    <TelegramShareButton
      title={shareTitle}
      url={shareUrl!}
      style={{ display: "flex" }}
    >
      <TelegramIcon
        size={16}
        round={true}
        style={{ verticalAlign: "bottom" }}
      />
    </TelegramShareButton>
  );

  const reddit = (
    <RedditShareButton
      title={shareTitle}
      url={shareUrl!}
      style={{ display: "flex" }}
    >
      <RedditIcon size={16} round={true} style={{ verticalAlign: "bottom" }} />
    </RedditShareButton>
  );

  const downloadMenu = (
    <Menu trigger="hover">
      <Menu.Target>
        <ActionIcon>
          <MdDownloadForOffline size={18} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {download?.downloadPDFSelector ? (
          <Menu.Item
            onClick={() => {
              setPdfGenerateOpened(true);
              //hack for html2canvas
              document
                .querySelectorAll(
                  ".mantine-Paper-root>div>.mantine-Group-root>.mantine-Group-root>.mantine-Text-root"
                )
                .forEach((titleItem: any) => {
                  titleItem.style.overflow = "visible";
                });

              const el =
                (document.querySelector(
                  download?.downloadPDFSelector ?? "body"
                ) as HTMLElement) || document.body;
              html2canvas(el)
                .then((canvas) => {
                  canvas.style.display = "none";
                  document.body.appendChild(canvas);
                  return canvas;
                })
                .then((canvas) => {
                  /*
                  let ctx = canvas.getContext("2d");
                  const water = document.createElement("canvas");
                  water.width = 260;
                  water.height = 300;
                  const waterCtx = water.getContext("2d");
                  if (waterCtx) {
                    waterCtx.font = "30px Verdana";
                    waterCtx.fillStyle = "rgba(128, 128, 128, 0.2)";
                    waterCtx?.fillText("EigenPhi", 50, 50);
                    const pat = ctx?.createPattern(water, "repeat");
                    if (ctx && pat) {
                      const svg = document.createElementNS(
                        "http://www.w3.org/2000/svg",
                        "svg"
                      );
                      const matrix = svg.createSVGMatrix();
                      pat.setTransform(matrix.scale(1.5).rotate(-45));
                      ctx.fillStyle = pat;
                      ctx.fillRect(0, 0, canvas.width, canvas.height);
                    }
                  }
                  */

                  const image = canvas.toDataURL("image/png");
                  /*
                    .replace("image/png", "image/octet-stream");
                  const a = document.createElement("a");
                  a.setAttribute(
                    "download",
                    `${window.location.host}${window.location.pathname}.png`
                  );
                  a.setAttribute("href", image);
                  a.setAttribute("type", "image/png");
                  a.click();
                  */
                  var doc = new jsPDF({
                    orientation: "p",
                    unit: "px",
                    format: [canvas.width, canvas.height],
                    hotfixes: ["px_scaling"],
                    compress: true,
                  });
                  doc.addImage(
                    image,
                    "PNG",
                    0,
                    0,
                    doc.internal.pageSize.getWidth(),
                    doc.internal.pageSize.getHeight()
                  );
                  doc.save(
                    `${window.location.host}${window.location.pathname}.pdf`
                  );
                  canvas.remove();
                  setPdfGenerateOpened(false);
                });
            }}
          >
            Download PDF
          </Menu.Item>
        ) : null}
        {download?.downloadLinks
          ? download?.downloadLinks.map((link) => (
              <Menu.Item
                onClick={() => {
                  const a = document.createElement("a");
                  a.setAttribute(
                    "download",
                    `${window.location.host}${window.location.pathname}.csv`
                  );
                  a.setAttribute("href", link.url ?? "");
                  a.click();
                }}
              >
                {link.text}
              </Menu.Item>
            ))
          : null}
      </Menu.Dropdown>
    </Menu>
  );

  return (
    <Group spacing={6} noWrap>
      <Modal
        opened={pdfGenerateOpened}
        onClose={() => setPdfGenerateOpened(false)}
      >
        <EpAlert icon={<Loader className="mantine-loader" />}>
          The pdf is being prepared, give me a second...
        </EpAlert>
      </Modal>
      {viewOnAddress ? (
        <Group noWrap spacing={2}>
          {isSmallScreen ? (
            chainAddress
          ) : (
            <Tooltip
              label={`View on ${chain === "bsc" ? "BscScan" : "EtherScan"}`}
              withArrow
            >
              {chainAddress}
            </Tooltip>
          )}
          {isSmallScreen ? (
            tenderlyContract
          ) : (
            <Tooltip label={`View on Tenderly`} withArrow>
              {tenderlyContract}
            </Tooltip>
          )}
        </Group>
      ) : null}
      {viewOnTx ? (
        <Group noWrap spacing={0}>
          {!hideAnalyseTool ? (
            isSmallScreen ? (
              analysisToolTx
            ) : (
              <Tooltip label={`View on EigenTx`} withArrow>
                <Box>{analysisToolTx}</Box>
              </Tooltip>
            )
          ) : null}
          {isSmallScreen ? (
            chainTx
          ) : (
            <Tooltip
              label={`View on ${chain === "bsc" ? "BscScan" : "EtherScan"}`}
              withArrow
            >
              {chainTx}
            </Tooltip>
          )}
          {isSmallScreen ? (
            tenderlyTx
          ) : (
            <Tooltip label={`View on Tenderly`} withArrow>
              {tenderlyTx}
            </Tooltip>
          )}
        </Group>
      ) : null}

      {(viewOnTx || viewOnAddress) && shareTitle && shareUrl ? (
        <Divider
          orientation="vertical"
          style={{ height: 20, marginTop: 3, marginRight: 5 }}
          color={theme.colorScheme === "light" ? "#CED4DA" : "#373A40"}
        />
      ) : null}

      {shareTitle && shareUrl ? (
        isLoading ? (
          <Skeleton width="100px" height={16} />
        ) : (
          <Group noWrap spacing={"sm"}>
            {isSmallScreen ? (
              twitter
            ) : (
              <Tooltip label="Share on Twitter" withArrow>
                {twitter}
              </Tooltip>
            )}
            {isSmallScreen ? (
              telegram
            ) : (
              <Tooltip label="Share on Telegram" withArrow>
                {telegram}
              </Tooltip>
            )}
            {isSmallScreen ? (
              reddit
            ) : (
              <Tooltip label="Share on Reddit" withArrow>
                {reddit}
              </Tooltip>
            )}
          </Group>
        )
      ) : null}

      {download?.downloadLinks || download?.downloadPDFSelector
        ? downloadMenu
        : null}
    </Group>
  );
}
