import React, { memo } from "react";
import { Box } from "@mantine/core";
import ArbitrageBehaviorChart from "./ArbitrageBehaviorChart";
import { ArbitrageBehaviorResult } from "../api/type";
import { useGlobalStatusContext } from "../GlobalStore";
import { FromNow } from "../Components/FromNow";
import { useFireStoreQuery } from "../Components/useFireStoreQuery";

export default memo(function ArbitrageBehaviorWidget({
  chain,
  address,
  token,
  height,
  emptyHeight,
  showProfitCards,
}: {
  chain: string;
  address?: string;
  token?: string;
  height: number;
  emptyHeight?: number;
  showProfitCards?: boolean;
}) {
  const { period, navExpand } = useGlobalStatusContext();

  let dbPath = `/${chain.toLowerCase()}/${period}d/performance_mev_type`;
  if (address) {
    dbPath = `/${chain.toLowerCase()}/${period}d/contract_performance_mev_type/default/${address}`;
  }
  if (token) {
    dbPath = `/${chain}/${period}d/token_performance_mev_type/default/${token}`;
  }

  const { data, isLoading } = useFireStoreQuery<PerformanceMevTypeRoot>({
    path: dbPath,
  });

  /*
  const { data, isLoading } = useQuery<
    ArbitrageBehaviorResult,
    ArbitrageBehaviorParams
  >(
    [
      "fetchArbitrageBehavior",
      {
        period,
        chain,
        contract: address,
        token,
      },
    ],
    {
      refetchOnMount: false,
      retry: false,
    }
  );
  */

  return (
    <>
      <ArbitrageBehaviorChart
        height={height}
        emptyHeight={emptyHeight}
        isLoading={isLoading}
        data={data?.data!}
        navExpand={navExpand!}
        period={period!}
        showProfitCards={showProfitCards}
        chain={chain}
        summaries={data?.summaries!}
      />
      <Box
        sx={(theme) => ({
          padding: "9px 20px",
          borderTop:
            theme.colorScheme === "dark"
              ? `1px solid ${theme.colors.dark[6]}`
              : `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <FromNow
          isLoading={isLoading}
          updateTimestamp={data?.updateTimestamp!}
          updateBlockNumber={data?.updateBlockNumber!}
        />
      </Box>
    </>
  );
});
