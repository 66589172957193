import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  SandwichAttackerActivesParams,
  SandwichAttackerActive,
  SandwichAttackerActivesResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichAttackerActive(
  options: SandwichAttackerActivesParams
) {
  const url = "/api/v2/dataservice/sandwich/attacker/active/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichAttackerActivesResponseJson,
    {
      rows: Array<SandwichAttackerActive>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: (data?.result?.data ?? []).map((item) => ({
        ...item,
        key: uuid(),
      })),
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
