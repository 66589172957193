import { QueryClient } from "react-query";
import * as api from "./api";
import * as transferScanApi from "./TransferScanApp/api";

type TApi = typeof api;
type TKnownApiMethods = keyof TApi;
type ShadeKey<K extends TKnownApiMethods> = TApi[K];
type TQueryKey<K extends TKnownApiMethods> = K extends TKnownApiMethods
  ? [K, ShadeKey<K>]
  : never;

type TTransferScanApi = typeof transferScanApi;
type TTransferScanKnownApiMethods = keyof TTransferScanApi;
type TransferScanShadeKey<K extends TTransferScanKnownApiMethods> =
  TTransferScanApi[K];
type TTransferScanQueryKey<K extends TTransferScanKnownApiMethods> =
  K extends TTransferScanKnownApiMethods ? [K, TransferScanShadeKey<K>] : never;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey, pageParam }) => {
        // console.log("queryKey, pageParam", queryKey, pageParam);
        if (typeof queryKey === "string") {
          const response = await fetch(queryKey);
          return await response.json();
        } else if (
          Object.keys(
            process.env.REACT_APP_NAME === "arbitragescan"
              ? api
              : transferScanApi
          ).includes(queryKey[0] as string)
        ) {
          if (process.env.REACT_APP_NAME === "arbitragescan") {
            const [method, options] = queryKey as TQueryKey<TKnownApiMethods>;
            const json = await api[method]({
              ...options,
              ...pageParam,
            });
            return json;
          } else {
            const [method, options] =
              queryKey as TTransferScanQueryKey<TTransferScanKnownApiMethods>;
            return transferScanApi[method]({
              ...options,
              ...pageParam,
            });
          }
        } else {
          throw new Error("Invalid Query Key");
        }
      },
      refetchOnWindowFocus: false,
    },
  },
});
