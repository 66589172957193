import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { Fragment } from "react";
import { useGlobalStatusContext } from "../../GlobalStore";

const LoginWrapper = ({ children }: any) => {
  const { user, setUser } = useGlobalStatusContext();
  const provider = new GoogleAuthProvider();
  const firebaseAuth = getAuth();

  const checkLogin = (cb: () => void) => {
    if (!user) {
      signInWithPopup(firebaseAuth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential?.accessToken;
          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          console.log(token, user);

          setUser(user);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    } else {
      cb?.();
    }
  };

  return <Fragment>{children && children({ checkLogin })}</Fragment>;
};

export default React.memo(LoginWrapper);
