import { stringifyUrl } from "query-string";
import type {
  SandwichAttackerOverviewParams,
  SandwichAttackerOverview,
  SandwichAttackerOverviewResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichAttackerOverview(
  options: SandwichAttackerOverviewParams
) {
  const url = "/api/v1/dataservice/sandwich/attacker/overview/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichAttackerOverviewResponseJson,
    SandwichAttackerOverview
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
