import { stringifyUrl } from "query-string";
import type {
  LendingOverviewParams,
  LendingOverview,
  LendingOverviewResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLendingOverview(options: LendingOverviewParams) {
  const url = "/api/v1/dataservice/lending/overview/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<LendingOverviewResponseJson, LendingOverview>(
    fetch(endpoint),
    (data) => {
      return data?.result;
    }
  );
}
