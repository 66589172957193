import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  SandwichVictimActivesParams,
  SandwichVictimActive,
  SandwichVictimActivesResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichVictimActive(
  options: SandwichVictimActivesParams
) {
  const url = "/api/v2/dataservice/sandwich/victim/active/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichVictimActivesResponseJson,
    {
      rows: Array<SandwichVictimActive>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: (data?.result?.data ?? []).map((item) => ({
        ...item,
        key: uuid(),
      })),
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
