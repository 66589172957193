import {
  Group,
  Skeleton,
  Box,
  Grid,
  Col,
  useMantineTheme,
  ScrollArea,
  Tooltip,
  Center,
} from "@mantine/core";
import React, { memo } from "react";
import { useParams } from "react-router";
import Blockies from "react-blockies";
import { AmountDisplay } from "../Components/AmountDisplay";
import { TdText, ThText } from "../Components/EpTable";
import { useNavigate } from "../Components/Link";
import { EpTxTypeNew } from "../Components/EpTxType";
import { TweenOneGroup } from "rc-tween-one";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { toChecksumAddress, useIsSmallScreen } from "../Components/Utils";
import EpPopover from "../Components/EpPopover";
import { ArbitrageTransactionMessage } from "../Transaction/api/type";
import { ContractDisplay } from "../Components/ContractDisplay";

dayjs.extend(utc);

const columns = 11;

const fromNow = (timestamp: number) => {
  const time = new Date().getTime() - timestamp;

  // 小于1min展示 <1 min
  // 大于等于1min且小于2min展示 1 min
  // 大于等于2min且小于3min展示 2 mins
  // 大于1h后直接展示Tx发生时间：03:45（UTC时间）；

  const second = Math.floor(time / 1000);
  const min = Math.floor(second / 60);
  const hour = Math.floor(min / 60);

  let text = "";
  if (second < 60) text = "<1 min";
  if (min > 0 && min < 60) text = `${min} min${min === 1 ? "" : "s"}`;
  if (hour > 0) text = dayjs.utc(timestamp).format("HH:mm");

  return text;
};

const Time = ({ dark, timestamp }: { dark: boolean; timestamp: number }) => {
  return (
    <EpPopover
      position="right"
      target={
        <Box>
          <TdText
            sx={{
              whiteSpace: "nowrap",
              color: dark ? "#B5B5C3" : "#B5B5C3",
            }}
            size="xs"
          >
            {fromNow((timestamp ?? 0) * 1000)}
          </TdText>
        </Box>
      }
      withArrow
    >
      <TdText
        size="sm"
        sx={(theme) => ({
          color:
            theme.colorScheme === "dark" ? theme.white : theme.colors.dark[6],
        })}
      >
        {dayjs.utc((timestamp ?? 0) * 1000).format("MMM D, YYYY HH:mm:ss")}
        (UTC)
      </TdText>
    </EpPopover>
  );
};

export const TransactionDataGrid = memo(function ({
  input,
  contentHeight,
  showFullContract,
}: {
  input: Array<ArbitrageTransactionMessage>;
  contentHeight?: number;
  showFullContract?: boolean;
}) {
  const { chain } = useParams();
  const navigate = useNavigate();
  const isSmallScreen = useIsSmallScreen();

  const theme = useMantineTheme();
  const dark = theme.colorScheme === "dark";

  const onEnd = (e: any) => {
    const dom = e.targets;
    dom.style.height = "auto";
  };

  const enterAnim = [
    {
      opacity: 0,
      x: 30,
      backgroundColor: dark ? "#25262B" : "#fffeee",
      duration: 0,
    },
    {
      height: 0,
      duration: 200,
      type: "from",
      delay: 250,
      ease: "easeOutQuad",
      onComplete: onEnd,
    },
    {
      opacity: 1,
      x: 0,
      duration: 250,
      ease: "easeOutQuad",
    },
    { delay: 1000, backgroundColor: dark ? "rgb(26,27,30)" : theme.white },
  ];

  const leaveAnim = [
    { duration: 250, opacity: 0 },
    {
      height: 0,
      duration: 200,
      ease: "easeOutQuad",
      onComplete: (e: any) => (e.targets.style.display = "none"),
    },
  ];

  const rows = input.map((row) => {
    return (
      <Grid
        gutter={0}
        columns={columns}
        key={`${row.txHash + row.expiryTimestamp}`}
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: dark
              ? "rgb(44, 46, 51) !important"
              : "#F8F9FA !important",
          },
        }}
        onClick={(e) => {
          const { nodeName } = e.target as HTMLAnchorElement;
          const selection = window.getSelection();
          if (!selection?.isCollapsed) {
            return false;
          }

          if (nodeName !== "A") {
            if (row.type === "Liquidation") {
              navigate(`/${chain || row.chain}/liquidation/tx/${row.txHash}`);
            } else {
              navigate(`/${chain || row.chain}/tx/${row.txHash}`);
            }
          }
        }}
      >
        <Col
          span={5}
          style={{ borderBottom: "none" }}
          sx={{ padding: "5px 20px" }}
        >
          <Group spacing={10} noWrap align="flex-start">
            <Group
              align="flex-start"
              sx={{ flexDirection: "column" }}
              spacing={0}
            >
              <TdText size="xs" color="#3F4254">
                #{row.blockNumber}
              </TdText>
              <Time timestamp={row.timestamp} dark={dark} />
            </Group>

            <Group
              spacing={6}
              sx={{ marginTop: 6, flexDirection: "column" }}
              position="center"
            >
              <Box
                sx={{
                  width: 13,
                  height: 13,
                  borderRadius: "50%",
                  background: dark ? "#25262B" : "#FFFFFF",
                  border: "3px solid",
                  borderColor: dark ? "#868E96" : "#CED4DA",
                }}
              />
              <Box
                sx={{
                  width: 3,
                  height: 30,
                  background: dark ? "#373A40" : "#E5EAEE",
                  borderRadius: 8,
                }}
              />
            </Group>
            <EpTxTypeNew
              chain={chain || row.chain}
              tx={row!}
              direction="column"
              withinPortal
            />
          </Group>
        </Col>
        <Col span={isSmallScreen ? 4 : 2} sx={{ padding: "5px 20px" }}>
          <Group
            align="flex-end"
            position={showFullContract ? "left" : "right"}
            sx={{ height: "100%" }}
          >
            {/* <AmountDisplay
              value={((row.revenue - row.cost) / row.cost) * 100}
              suffix="%"
            /> */}
            {showFullContract ? (
              <ContractDisplay
                address={row.contract as string}
                // labelList={row.contractLabels}
                chain={row.chain}
              />
            ) : (
              <Tooltip label={toChecksumAddress(row.contract)}>
                <Center sx={{ height: 22 }}>
                  <Box
                    sx={{
                      height: 18,
                      width: 18,
                      borderRadius: 4,
                      overflow: "hidden",
                    }}
                  >
                    <Blockies
                      seed={toChecksumAddress(row.contract)}
                      size={6}
                      scale={3}
                    />
                  </Box>
                </Center>
              </Tooltip>
            )}
          </Group>
        </Col>
        <Col span={2} sx={{ padding: "5px 20px" }}>
          <Group align="flex-end" position="right" sx={{ height: "100%" }}>
            <AmountDisplay value={row.revenue - row.cost} prefix="$" />
          </Group>
        </Col>
        {isSmallScreen ? null : (
          <Col span={2} sx={{ padding: "5px 20px" }}>
            <Group align="flex-end" position="right" sx={{ height: "100%" }}>
              <AmountDisplay value={row.cost} prefix="$" />
            </Group>
          </Col>
        )}
      </Grid>
    );
  });

  let colcount = isSmallScreen ? 3 : 4;
  const spanArr = isSmallScreen ? [5, 4, 2] : [5, 2, 2, 2];

  return (
    <ScrollArea
      sx={{
        minWidth: isSmallScreen ? "100%" : 430,
        height: contentHeight || "auto",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <Grid
        gutter={0}
        columns={columns}
        sx={{
          position: "sticky",
          top: -1,
          zIndex: 219,
          background: dark ? "#1A1B1E" : "#FFFFFF",
        }}
      >
        <Col span={5} sx={{ padding: "2px 20px" }}>
          <ThText></ThText>
        </Col>
        <Col span={isSmallScreen ? 4 : 2} sx={{ padding: "2px 20px" }}>
          <Group
            position={showFullContract ? "left" : "right"}
            sx={{ height: "100%" }}
          >
            <ThText>Contract</ThText>
          </Group>
        </Col>
        <Col span={2} sx={{ padding: "2px 20px" }}>
          <Group position="right" sx={{ height: "100%" }}>
            <ThText>Profit</ThText>
          </Group>
        </Col>
        {isSmallScreen ? null : (
          <Col span={2} sx={{ padding: "2px 20px" }}>
            <Group position="right" sx={{ height: "100%" }}>
              <ThText>Cost</ThText>
            </Group>
          </Col>
        )}
      </Grid>
      <Box sx={{ overflow: "hidden" }}>
        {rows.length >= 20 ? (
          <TweenOneGroup
            component="div"
            enter={enterAnim as any}
            leave={leaveAnim}
            appear={false}
            exclusive
          >
            {rows}
          </TweenOneGroup>
        ) : (
          Array.from({ length: 15 }).map((_, rowIdx) => (
            <Grid gutter={0} key={`skeleton-row-${rowIdx}`} columns={columns}>
              {Array.from({ length: colcount }).map((_, columnIdx) => (
                <Col
                  span={spanArr[columnIdx]}
                  sx={{ padding: "5px 20px" }}
                  key={`skeleton-column-${columnIdx}`}
                >
                  <Group position="right" sx={{ height: "100%" }}>
                    <Skeleton width="100%" height={28} />
                  </Group>
                </Col>
              ))}
            </Grid>
          ))
        )}
      </Box>
    </ScrollArea>
  );
});
