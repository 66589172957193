import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  SandwichVictimsLeaderboardParams,
  SandwichVictimsLeaderboardItem,
  SandwichVictimsLeaderboardResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichVictimsLeaderboard(
  options: SandwichVictimsLeaderboardParams
) {
  const url = "/api/v1/dataservice/sandwich/victims/leaderboard/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichVictimsLeaderboardResponseJson,
    Array<SandwichVictimsLeaderboardItem>
  >(fetch(endpoint), (data) => {
    return (data?.result ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
