import { stringifyUrl } from "query-string";
import {
  MarketOverview,
  MarketOverviewParams,
  MarketOverviewResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchMarketOverview(params: MarketOverviewParams) {
  const url = "/api/v1/transfer/stat/overview/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<MarketOverviewResponseJson, MarketOverview>(
    fetch(endpoint),
    (data) => {
      return data.result;
    }
  );
}
