import { stringifyUrl } from "query-string";
import type {
  LendingTrendParams,
  LendingTrend,
  LendingTrendResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLendingTrend(options: LendingTrendParams) {
  const url = "/api/v1/dataservice/lending/trend/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LendingTrendResponseJson,
    {
      rows: Array<LendingTrend>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: data?.result?.data,
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
