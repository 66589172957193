import { stringifyUrl } from "query-string";
import type { RelatedTxsItem, RelatedTxsParams } from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchRelatedTxs(options: RelatedTxsParams) {
  const url = "https://get-related-txs.api.eigenphi.io";

  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<any, Array<RelatedTxsItem>>(
    fetch(endpoint),
    (data) => {
      return data;
    }
  );
}
