import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationBorrowerBehaviorResponseJson,
  LiquidationBorrowerBehaviorItem,
  LiquidationBorrowerBehaviorParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationBorrowerBehavior(
  options: LiquidationBorrowerBehaviorParams
) {
  const url = "/api/v1/dataservice/liquidation/borrowerBehaviors/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationBorrowerBehaviorResponseJson,
    {
      rows: Array<LiquidationBorrowerBehaviorItem>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: (data?.result?.data ?? []).map((item) => ({
        ...item,
        key: uuid(),
      })),
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
