import { stringifyUrl } from "query-string";
import {
  TransactionOverview,
  TransactionOverviewParams,
  TransactionOverviewResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchTransactionOverview(
  params: TransactionOverviewParams
) {
  const url = "/api/v1/arbitrage/stat/txProfit/";

  const endpoint = stringifyUrl({ url, query: params });

  const overview = await normalizeResponse<
    TransactionOverviewResponseJson,
    TransactionOverview
  >(fetch(endpoint), (data) => {
    return data?.result?.data; //FIX ME
  });

  return overview;
}
