import { stringifyUrl } from "query-string";
import type {
  LiquidationOverviewParams,
  LiquidationOverview,
  LiquidationOverviewResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationOverview(
  options: LiquidationOverviewParams
) {
  const url = "/api/v1/dataservice/liquidation/overview/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationOverviewResponseJson,
    LiquidationOverview
  >(fetch(endpoint), (data) => {
    return data?.result?.data;
  });
}
