import React, {
  memo,
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Widget from "../Components/Widget";
import {
  Grid,
  Col,
  Group,
  SegmentedControl,
  Center,
  Button,
  Box,
} from "@mantine/core";
import { Link } from "../Components/Link";
import { TransactionStream } from "./TransactionStreamWidget";
import { TranscationProfitDistribution } from "./TranscationProfitDistribution";
import { useIsSmallScreen } from "../Components/Utils";
import { useGlobalStatusContext } from "../GlobalStore";

import HomePageTitle from "./HomePageTitle";
import MaliciousTokenDownloadButton from "../Token/MaliciousTokenDownloadButton";
import { EpPageContext } from "../Components/EpPage";
import ArbitrageBehaviorWidget from "./ArbitrageBehaviorWidget";
import { WidgetLoadingFallback } from "../Components/LoadingFallback";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useElementSize } from "@mantine/hooks";
dayjs.extend(relativeTime);

const HomeTopWidget = memo(
  ({
    chain,
    isSmallScreen,
    width,
  }: {
    chain: string;
    isSmallScreen: boolean;
    width: number;
  }) => {
    const size = useElementSize();
    const isSmallWidget = width < 1000;

    return (
      <Box>
        <Grid>
          <Col span={isSmallWidget ? 12 : 7}>
            <Widget
              id="Performance of MEV Types"
              contentSx={{ height: "auto", overflow: "hidden" }}
              wrapSx={{ height: "calc(100% - 20px)", overflow: "hidden" }}
              hideFooter
              fullScreen={!isSmallScreen}
            >
              <ArbitrageBehaviorWidget
                chain={chain}
                height={355}
                emptyHeight={380}
                showProfitCards
              />
            </Widget>
          </Col>

          <Col span={isSmallWidget ? 12 : 5}>
            <Widget
              id="MEV Live-Stream"
              wrapSx={{ height: "calc(100% - 20px)" }}
              contentSx={{ height: "auto" }}
              more={
                <Button
                  component={Link}
                  to={`/${chain}/txr`}
                  size="xs"
                  sx={() => ({
                    height: 28,
                    fontWeight: 400,
                    backgroundColor: "transparent",
                    color: "#44957D",
                    "&:hover": {
                      backgroundColor: "rgba(68,215,182,0.10)",
                      color: "#49CE87",
                    },
                  })}
                >
                  More
                </Button>
              }
            >
              <Box ref={size.ref}>
                <TransactionStream
                  chain={chain}
                  bufferType="time"
                  maxCount={20}
                  contentHeight={430}
                  showFullContract={size.width > 600}
                />
              </Box>
            </Widget>
          </Col>
        </Grid>
      </Box>
    );
  }
);

export default function HomePage() {
  const isSmallScreen = useIsSmallScreen();

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const width = (ref?.current as any)?.clientWidth || 0;
    setWidth(width);
  }, []);
  // console.log("home page re-render");

  const LeaderBoard = React.lazy(() => {
    return import("./ContractProfitLeaderboard");
  });
  const HotLiquidityPoolWidget = React.lazy(() => {
    return import("./HotLiquidityPoolWidget");
  });
  const HotTokenWidget = React.lazy(() => {
    return import("./HotTokenWidget");
  });

  const TransactionLeaderBoard = React.lazy(() => {
    return import("./TransactionLeaderBoard");
  });
  const MaliciousTokenList = React.lazy(() => {
    return import("../Token/MaliciousTokenList");
  });

  const { chain, isSmallThanLG } = useGlobalStatusContext();

  const ctx = useContext(EpPageContext);

  // const { data } = useQuery<MarketOverviewType, MarketOverviewParams>([
  //   "fetchMarketOverview",
  //   { period, chain },
  // ]);

  // const pageDesc = `MEV Contracts on ${chain} have made a total profit of $${amount(
  //   data?.profit ?? 0,
  //   2
  // )} in ${formatPeriod(period)}, through ${
  //   data?.transactions
  // } MEV transactions.`;

  const previewUrl = encodeURIComponent(
    `${window.location.href}${
      window.location.href.includes("?") ? "&" : "?"
    }selector=.widget-performance-of-mev-types`
  );
  ctx.setPage({
    pageTitle: "MEV Data | EigenPhi｜Wisdom of DeFi",
    pageDesc:
      "Discover the most comprehensive live MEV data to reveal crypto earning strategies of bots and expose hidden risks of DeFi on Ethereum and BSC, updated in real-time. EigenPhi is a token flow-oriented data platform, focusing on bottom-up analysis starting from the transaction structures, such as arbitrage, sandwich, liquidation, and flash loan.",
    pageImage: `https://seo.eigenphi.io/page.png?url=${previewUrl}`,
  });

  return (
    <>
      <HomePageTitle />
      {/* {isSmallScreen ? <MarketOverview /> : null} */}
      <Box ref={ref}>
        {width ? (
          <>
            <HomeTopWidget
              chain={chain}
              isSmallScreen={isSmallScreen}
              width={width}
            />

            <Widget
              id="MEV Contract Profit Leaderboard"
              wrapSx={{ height: "calc(100% - 20px)" }}
              hideFooter
            >
              <Suspense fallback={<WidgetLoadingFallback />}>
                <LeaderBoard chain={chain} />
              </Suspense>
            </Widget>

            <Grid>
              <Col span={isSmallThanLG ? 12 : 5}>
                <Widget
                  id="MEV Transaction Profit Distribution"
                  contentSx={{ height: "auto" }}
                  wrapSx={{ height: "calc(100% - 20px)" }}
                  fullScreen
                  hideFooter
                >
                  <TranscationProfitDistribution chain={chain} />
                </Widget>
              </Col>
              <Col span={isSmallThanLG ? 12 : 7}>
                <Widget id="MEV Transaction Profit Leaderboard" hideFooter>
                  <Suspense fallback={<WidgetLoadingFallback />}>
                    <TransactionLeaderBoard
                      chain={chain}
                      hideRevenue
                      hideTx
                      showContract
                    />
                  </Suspense>
                </Widget>
              </Col>
            </Grid>

            <Grid>
              <Col span={isSmallThanLG ? 12 : 4}>
                <Suspense fallback={<WidgetLoadingFallback />}>
                  <HotTokenWidget />
                </Suspense>
              </Col>
              <Col span={isSmallThanLG ? 12 : 4}>
                <Suspense fallback={<WidgetLoadingFallback />}>
                  <HotLiquidityPoolWidget />
                </Suspense>
              </Col>
              <Col span={isSmallThanLG ? 12 : 4}>
                <Widget
                  id="Malicious Tokens"
                  more={
                    <Group spacing={4}>
                      <MaliciousTokenDownloadButton chain={chain} />
                      <Button
                        component={Link}
                        to={`/${chain}/tokens/malicious`}
                        size="xs"
                        sx={() => ({
                          height: 28,
                          fontWeight: 400,
                          backgroundColor: "transparent",
                          color: "#44957D",
                          "&:hover": {
                            backgroundColor: "rgba(68,215,182,0.10);",
                            color: "#49CE87",
                          },
                        })}
                      >
                        More
                      </Button>
                    </Group>
                  }
                >
                  <Suspense fallback={<WidgetLoadingFallback />}>
                    <MaliciousTokenList lite chain={chain} hideAddress />
                  </Suspense>
                </Widget>
              </Col>
            </Grid>
          </>
        ) : null}
      </Box>
    </>
  );
}
