import { stringifyUrl } from "query-string";
import type {
  TransactionLeaderBoardItem,
  TokenTransactionParams,
  TokenTransactionResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchTokenLatestTransaction(
  params: TokenTransactionParams
) {
  const url = "/api/v2/arbitrage/token/tx/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    TokenTransactionResponseJson,
    {
      list: Array<TransactionLeaderBoardItem>;
      total: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      list: data?.result?.realTimeTransactionDtos,
      total: data?.result?.totalCount,
    };
  });
}
