import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationLiquidatorPerformanceResponseJson,
  LiquidationLiquidatorPerformance,
  LiquidationLiquidatorPerformanceParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationLiquidatorPerformance(
  options: LiquidationLiquidatorPerformanceParams
) {
  const url = "/api/v1/dataservice/liquidation/liquidatorPerformance/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationLiquidatorPerformanceResponseJson,
    {
      rows: Array<LiquidationLiquidatorPerformance>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: (data?.result?.data ?? []).map((item) => ({
        ...item,
        key: uuid(),
      })),
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
