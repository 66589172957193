import { Button, Collapse } from "@mantine/core";
import React, { useState } from "react";

import { ErrorBoundary } from "react-error-boundary";
function ErrorFallback(errObj: any) {
  const [detailVisible, setDetailVisible] = useState(false);
  const { error } = errObj;

  console.log(errObj, error);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>

      <Button
        onClick={() => {
          setDetailVisible(!detailVisible);
        }}
      >
        Detail
      </Button>
      <Collapse in={detailVisible}>
        <pre>{error?.stack}</pre>
      </Collapse>
    </div>
  );
}

type PageInfo = {
  pageId?: string;
  pageTitle?: string;
  pageDesc?: string;
  pageImage?: string;
};
export const EpPageContext = React.createContext({
  setPage: (h: PageInfo): void => {},
  page: function (): PageInfo {
    return {};
  },
});

export function EpPageProvider({
  pageId,
  children,
}: {
  pageId?: string;
  children: React.ReactNode;
}) {
  function $$(selector: string) {
    return window.document.querySelector(selector);
  }

  const titleRef = $$("title");
  const ogTitleMetaRef = $$('meta[property="og:title"]');
  const twTitleMetaRef = $$('meta[name="twitter:title"]');

  const descMetaRef = $$('meta[name="description"]');
  const ogDescMetaRef = $$('meta[property="og:description"]');
  const twDescMetaRef = $$('meta[name="twitter:description"]');
  const ogImageAltMetaRef = $$('meta[property="og:image:alt"]');

  const twImageMetaRef = $$('meta[name="twitter:image"]');
  const ogImageMetaRef = $$('meta[property="og:image"]');

  const twCardMetaRef = $$('meta[name="twitter:card"]');
  const ogCardMetaRef = $$('meta[property="og:card"]');

  const pageIdRef = $$('meta[name="eigenphi:pageid"]');

  const ctx = {
    setPage: ({ pageId, pageTitle, pageDesc, pageImage }: PageInfo) => {
      if (pageId !== undefined) {
        if (pageIdRef) {
          pageIdRef.setAttribute("content", pageId);
        }
      }

      if (pageTitle !== undefined) {
        if (titleRef) {
          titleRef.innerHTML = pageTitle;
        }
        [ogTitleMetaRef, twTitleMetaRef].forEach((meta) => {
          if (meta) {
            meta.setAttribute("content", pageTitle);
          }
        });
      }

      if (pageDesc !== undefined) {
        [descMetaRef, ogDescMetaRef, twDescMetaRef, ogImageAltMetaRef].forEach(
          (meta) => {
            if (meta) {
              meta.setAttribute("content", pageDesc);
            }
          }
        );
      }

      if (pageImage !== undefined) {
        [twImageMetaRef, ogImageMetaRef].forEach((meta) => {
          if (meta) {
            meta.setAttribute("content", pageImage);
          }
        });
        [twCardMetaRef, ogCardMetaRef].forEach((meta) => {
          if (meta) {
            meta.setAttribute("content", "summary_large_image");
          }
        });
      }
    },
    page: () =>
      ({
        pageId: pageIdRef?.getAttribute("content"),
        pageTitle: titleRef?.innerHTML,
        pageDesc: descMetaRef?.getAttribute("content"),
        pageImage: twImageMetaRef?.getAttribute("content"),
      } as PageInfo),
  };

  ctx.setPage({
    pageId,
    pageTitle: "EigenPhi",
    pageImage: `https://seo.eigenphi.io/page.png?url=${window.location.href}`,
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <EpPageContext.Provider value={ctx}>{children}</EpPageContext.Provider>
    </ErrorBoundary>
  );
}
