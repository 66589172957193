import React from "react";
import { ActionIcon, Tooltip } from "@mantine/core";
import { FiDownload } from "react-icons/fi";

export const MaliciousTokenDownloadButton = React.memo(function ({
  chain,
}: {
  chain: string;
}) {
  const downloadData = () => {
    const a = document.createElement("a");
    a.setAttribute(
      "download",
      `${window.location.host}${window.location.pathname}.csv`
    );
    a.setAttribute(
      "href",
      `https://download-malicious-tokens-tepu46la6a-uw.a.run.app/?chain=${chain}`
    );
    a.click();
  };

  return (
    <Tooltip label="Download CSV" withArrow>
      <ActionIcon
        sx={(theme) => ({
          backgroundColor: "transparent",
          color: "#44957D",
          "&:hover": {
            color: "#44957D",
            backgroundColor: "rgba(68,215,182,0.10)",
          },
        })}
        onClick={() => {
          downloadData();
        }}
      >
        <FiDownload size={14} />
      </ActionIcon>
    </Tooltip>
  );
});

export default MaliciousTokenDownloadButton;
