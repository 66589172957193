import { stringifyUrl } from "query-string";
import { TxTags, TxTagsParams } from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchTxTags(options: TxTagsParams) {
  const url = "https://get-tx-tags.api.eigenphi.io";

  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<any, TxTags>(fetch(endpoint), (data) => {
    return data;
  });
}
