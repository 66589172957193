import { stringifyUrl } from "query-string";
import type {
  UnsecuredAssetsItem,
  UnsecuredAssetsParams,
  UnsecuredAssetsResponseJson,
  ApiWithPagination,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchUnsecuredAssets(params: UnsecuredAssetsParams) {
  const url = "/api/v2/dataservice/tag/token/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    UnsecuredAssetsResponseJson<typeof params.id>,
    ApiWithPagination<Array<UnsecuredAssetsItem>>
  >(
    fetch(endpoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }),
    (data) => {
      return {
        result: data?.result ?? [],
        nextPage: (params.pageNum ?? 0) + 1,
        pageSize: params.pageSize ?? 15,
      };
    }
  );
}
