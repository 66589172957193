import React, { memo, useState, useEffect, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip, ActionIcon } from "@mantine/core";
import { useIsSmallScreen } from "../Components/Utils";
import Icon from "./Icon";

function useInterval(fn: () => void, delay: number, options?: any) {
  const immediate = options?.immediate;
  const fnRef = useRef({} as any);
  fnRef.current = fn;

  useEffect(() => {
    if (typeof delay !== "number" || delay < 0) return;
    if (immediate) {
      fnRef.current();
    }
    const timer = setInterval(() => {
      fnRef.current();
    }, delay);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);
}

export const CopyWrapper = memo(
  ({
    children,
  }: {
    children: ({
      copied,
    }: {
      copied: boolean;
      copy: () => void;
    }) => React.ReactNode;
  }) => {
    const [copied, setCopied] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [status, setStatus] = useState("stop");

    useInterval(
      () => {
        const nextSeconds = seconds + 1;

        if (nextSeconds > 0.5) {
          setSeconds(0);
          setStatus("stop");
          setCopied(false);
        } else {
          setSeconds(nextSeconds);
        }
      },
      status === "start" ? 1000 : -1
    );

    const handleCopy = () => {
      setCopied(true);
      setStatus("start");
    };

    return <>{children && children({ copied, copy: handleCopy })}</>;
  }
);

export const CopyAddress = memo(
  ({
    color = "#54B498",
    onCopy,
    ...restProps
  }: {
    color?: string;
    text: string;
    onCopy?: () => void;
  }) => {
    const isSmallScreen = useIsSmallScreen();

    const copyIcon = (
      <ActionIcon size="sm" sx={{ color }}>
        <Icon name="icon-copy" />
      </ActionIcon>
    );

    return (
      <CopyWrapper>
        {({ copied, copy }) => {
          return copied ? (
            <Tooltip opened label="Copied">
              <ActionIcon size="sm" sx={{ color }}>
                <Icon name="icon-roundcheck" />
              </ActionIcon>
            </Tooltip>
          ) : (
            <CopyToClipboard
              onCopy={() => {
                onCopy?.();
                copy();
              }}
              {...restProps}
            >
              {isSmallScreen ? (
                copyIcon
              ) : (
                <Tooltip label="Copy">{copyIcon}</Tooltip>
              )}
            </CopyToClipboard>
          );
        }}
      </CopyWrapper>
    );
  }
);
