import { stringifyUrl } from "query-string";
import type {
  ContractMinerPerformance,
  ContractMinerPerformancesParams,
  ContractMinerPerformancesResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchContractMinerPerformanceDistribution(
  params: ContractMinerPerformancesParams
) {
  const url = "/api/v1/arbitrage/stat/miner/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    ContractMinerPerformancesResponseJson,
    {
      rows: Array<ContractMinerPerformance>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: data?.result?.data || [],
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
