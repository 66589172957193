import React, { useState, useRef, useEffect, memo } from "react";
import {
  Paper,
  Space,
  Box,
  SegmentedControl,
  Group,
  useMantineTheme,
  Grid,
  Col,
  Skeleton,
  Tooltip,
} from "@mantine/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { EpAlert } from "../Components/EpMessage";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useNavigate } from "./Link";
import { chartColors, leftYAxisColor } from "./Highcharts";
import Icon from "./Icon";
import { TdText } from "./EpTable";
import { amount, formatPeriod, nFormatter, useIsSmallScreen } from "./Utils";
import { AmountDisplay } from "./AmountDisplay";

dayjs.extend(utc);

const MevTypeCards = memo(
  ({
    period,
    series,
    isSmallScreen,
    isLoading,
    selectType,
    chain,
  }: {
    period: string;
    series?: Array<{
      type: string;
      totalProfit: number;
      totalProfitInLastPeroid: number;
      totalVolume: number;
      totalVolumeInLastPeroid: number;
    }>;
    isSmallScreen: boolean;
    isLoading: boolean;
    selectType: string;
    chain?: string;
  }) => {
    const theme = useMantineTheme();
    const navigator = useNavigate();

    const dark = theme.colorScheme === "dark";

    const list = [
      {
        name: "Arbitrage",
        icon: "icon-arbitrage",
        color: chartColors.blue,
      },
      {
        name: "Sandwich",
        icon: "icon-sandwich",
        color: "#44D7B6",
        onClick: () => {
          navigator(`/${chain}/sandwich`);
        },
      },
      chain === "ethereum" && {
        name: "Liquidation",
        icon: "icon-liquidation",
        color: chartColors.yellow,
        onClick: () => {
          navigator(`/${chain}/liquidation`);
        },
      },
    ].filter(Boolean);

    const renderCard = ({
      icon,
      name,
      serie,
      color,
      onClick,
    }: {
      icon: string;
      name: string;
      serie: any;
      color: string;
      onClick?: () => void;
    }) => {
      const total =
        selectType === "profit" ? serie?.totalProfit : serie?.totalVolume;
      const lastTotal =
        selectType === "profit"
          ? serie?.totalProfitInLastPeroid
          : serie?.totalVolumeInLastPeroid;
      const change = (total - lastTotal) / lastTotal;

      return (
        <Box
          sx={{
            borderRadius: 12,
            padding: "12px 10px 13px",
            background: dark ? "#25262B" : "#F8F9FA",
            overflow: "auto",
            cursor: onClick ? "pointer" : "",
          }}
          onClick={onClick}
        >
          <Group spacing={4} noWrap>
            <Icon
              name={icon}
              size={isSmallScreen ? 12 : 16}
              color={dark ? "#B5B5C3" : "#7E8299"}
            />
            <TdText
              sx={{
                width: 85,
                color: dark ? "#B5B5C3" : "#7E8299",
                fontSize: isSmallScreen ? 12 : 14,
              }}
            >
              {name}
              {!isSmallScreen ? `(${formatPeriod(period)})` : ""}
            </TdText>
          </Group>
          <Space h={10} />
          <Group
            position={isSmallScreen ? "left" : "right"}
            noWrap={!isSmallScreen}
            spacing={isSmallScreen ? 4 : 16}
          >
            {isLoading ? (
              <Skeleton width="100px" height={31} />
            ) : (
              <Tooltip label={amount(total || 0)}>
                <TdText size="xs" sx={{ color, fontWeight: 700, fontSize: 20 }}>
                  ${nFormatter(total, 2)}
                </TdText>
              </Tooltip>
            )}
            {change ? (
              <Group
                noWrap
                spacing={2}
                sx={{ width: 74 }}
                position={isSmallScreen ? "left" : "right"}
              >
                {lastTotal ? (
                  <>
                    <Icon
                      name={
                        change > 0
                          ? "icon-triangleupfill"
                          : "icon-triangledownfill"
                      }
                      size={12}
                      color={change > 0 ? chartColors.green : chartColors.red}
                    />
                    <AmountDisplay
                      suffix="%"
                      value={change * 100}
                      digits={2}
                      label={`${formatPeriod(period)} percentage change`}
                      style={{
                        color: change > 0 ? chartColors.green : chartColors.red,
                        fontSize: 12,
                      }}
                    />
                  </>
                ) : (
                  <TdText
                    size="xs"
                    sx={{
                      color,
                      fontSize: 20,
                      height: 18,
                      lineHeight: "18px",
                      width: 80,
                    }}
                  >
                    -
                  </TdText>
                )}
              </Group>
            ) : (
              <TdText
                size="xs"
                sx={{
                  color,
                  fontSize: 20,
                  height: 18,
                  lineHeight: "18px",
                  width: 80,
                }}
              >
                -
              </TdText>
            )}
          </Group>
        </Box>
      );
    };

    return (
      <Grid
        sx={{ width: "100%", marginTop: chain === "bsc" ? 30 : 0 }}
        gutter={0}
      >
        {list.map(({ name, icon, color, onClick }: any, idx) => {
          const serie = series?.find((item) => item.type === name);

          return (
            <Col
              key={idx}
              span={isSmallScreen ? (chain === "bsc" ? 6 : 4) : 12}
              sx={{
                padding: "0 4px",
                marginBottom: isSmallScreen ? 0 : chain === "bsc" ? 35 : 16,
              }}
            >
              {renderCard({ icon, name, serie, color, onClick })}
            </Col>
          );
        })}
      </Grid>
    );
  }
);

const ProfitVolumeChart = ({
  chain,
  data,
  isLoading,
  options,
  navExpand,
  period,
  emptyHeight,
  showProfitCards,
  summaries,
  getSeries,
  onChange,
}: {
  chain?: string;
  data: any;
  isLoading: boolean;
  options?: Highcharts.Options;
  navExpand: boolean;
  period: string;
  emptyHeight?: number;
  showProfitCards?: boolean;
  summaries?: Array<any>;
  getSeries?: (type: string, rows: any) => any;
  onChange?: (type: string) => void;
}) => {
  const [type, setType] = useState("profit");
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const isSmallScreen = useIsSmallScreen();
  const theme = useMantineTheme();

  useEffect(() => {
    chartComponentRef.current?.chart.reflow();
  }, [navExpand, chain]);

  useEffect(() => {
    if (isLoading) {
      chartComponentRef.current?.chart.showLoading();
    } else {
      chartComponentRef.current?.chart.hideLoading();
    }
  }, [isLoading]);

  let rows = [...(data || [])];
  rows?.reverse();

  // bsc去掉清算的显示
  if (chain === "bsc") {
    rows?.forEach((row) => {
      row.items = row?.items?.filter(
        (item: { type: string }) => item?.type !== "Liquidation"
      );
    });
  }

  const series = getSeries?.(type, rows);

  // https://www.highcharts.com/forum/viewtopic.php?t=33062#p116095
  const finalOptions: Highcharts.Options = {
    ...options,
    credits: {
      enabled: false,
    },
    chart: {
      alignTicks: false, // 解决多y轴，图表数据切换之后，前后显示不一致的问题
      ...options?.chart,
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        categories: rows?.map(
          (item: any) =>
            `${dayjs
              .utc(item.timestamp * 1000)
              .format(
                Number(period) === 1 ? "MMM D, YYYY HH:mm" : "MMM D, YYYY"
              )} (UTC)`
        ),
        labels: {
          formatter: function () {
            if (typeof this.value !== "string") return String(this.value);

            return dayjs(
              (this.value as string)?.substring(
                0,
                (this.value as string).length - 5
              )
            ).format(Number(period) === 1 ? "HH:mm" : "D. MMM");
          },
        },
        crosshair: true,
        tickInterval: Number(period) === 7 ? 1 : 4,
        reversed: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        title: {
          text: type === "profit" ? "Profit($)" : "Volume($)",
          style: {
            color: leftYAxisColor,
          },
        },
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          // format: "${value}",
          style: {
            color: leftYAxisColor,
          },
        },
        reversedStacks: false, // 让柱状堆叠图不反向
      },
      {
        // Secondary yAxis
        title: {
          text: "Count",
          style: {
            color:
              theme.colorScheme === "dark"
                ? chartColors.lightPurple
                : chartColors.purple,
          },
        },
        labels: {
          style: {
            color:
              theme.colorScheme === "dark"
                ? chartColors.lightPurple
                : chartColors.purple,
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      ...options?.tooltip,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
    },
    series,
  };

  return (
    <Paper sx={{ padding: "10px 0" }}>
      <Box sx={{ padding: "0 20px" }}>
        <SegmentedControl
          data={[
            { value: "profit", label: "By Profit" },
            { value: "volume", label: "By Volume" },
          ]}
          size="xs"
          onChange={(value: any) => {
            setType(value);
            onChange?.(value);
          }}
          value={type}
        />
      </Box>
      <Space h="lg" />
      {!isLoading && !data?.length ? (
        <EpAlert height={emptyHeight}>Not enough data to display</EpAlert>
      ) : isSmallScreen ? (
        <Box
          sx={{
            width: "100%",
            position: "relative",
            padding: "0 20px",
          }}
        >
          <Box>
            <HighchartsReact
              ref={chartComponentRef}
              highcharts={Highcharts}
              options={finalOptions}
            />
          </Box>
          {showProfitCards ? (
            <Box>
              <MevTypeCards
                isSmallScreen={isSmallScreen}
                period={period}
                series={summaries!}
                isLoading={isLoading}
                selectType={type}
                chain={chain}
              />
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            position: "relative",
            padding: "0 20px",
          }}
        >
          {showProfitCards ? (
            <Box
              style={{ width: "220px", position: "absolute", left: 16, top: 0 }}
            >
              <MevTypeCards
                isSmallScreen={isSmallScreen}
                period={period}
                series={summaries!}
                isLoading={isLoading}
                selectType={type}
                chain={chain}
              />
            </Box>
          ) : null}
          <Box sx={{ marginLeft: showProfitCards ? 220 : 0 }}>
            <HighchartsReact
              ref={chartComponentRef}
              highcharts={Highcharts}
              options={finalOptions}
            />
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default React.memo(ProfitVolumeChart);
