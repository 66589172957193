import "./wdyr"; // <--- first import
/// <reference types="@welldone-software/why-did-you-render" />
import React from "react";
import ReactDOM from "react-dom";
import { useMantineTheme } from "@mantine/core";
import ArbitrageScanApp from "./ArbitrageScanApp";
import TransferScanApp from "./TransferScanApp/TransferScanApp";

import "modern-css-reset";
import EigenApiApp from "./EigenApiApp/EigenApiApp";

const InitApp = () => {
  const theme = useMantineTheme();

  const tableCSS = `
    tr a {
      color: ${theme.colorScheme === "dark" ? "#44957D" : "#54B498"} !important;
    }
    tr:hover a {
      text-decoration: underline;
      color: #44D7B6 !important;
    }
    tr:hover a:hover {
      text-decoration: underline;
      color: #44D7B6 !important;
    }
    tr:hover a span{
      text-decoration: underline;
    }
  `;

  return (
    <>
      <style>{tableCSS}</style>
      <ArbitrageScanApp />
    </>
  );
};

if (process.env.REACT_APP_NAME === "arbitragescan") {
  ReactDOM.render(<InitApp />, document.getElementById("root"));
} else if (process.env.REACT_APP_NAME === "eigenapi") {
  ReactDOM.render(<EigenApiApp />, document.getElementById("root"));
} else {
  const tableCSS = `
    tr a {
      color: #44957D !important;
    }
    tr:hover a {
      text-decoration: underline;
      color: #44D7B6 !important;
    }
    tr:hover a:hover {
      text-decoration: underline;
      color: #44D7B6 !important;
    }
    tr:hover a span{
      text-decoration: underline;
    }
  `;

  ReactDOM.render(
    <>
      <style>{tableCSS}</style>
      <TransferScanApp />
    </>,
    document.getElementById("root")
  );
}
