import { memo, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsDependencywheel from "highcharts/modules/dependency-wheel";
import { useQuery } from "react-query";
import { FundFlowItem, FundFlowParams } from "./api/type";
import { useGlobalStatusContext } from "../GlobalStore";

HighchartsSankey(Highcharts);
HighchartsDependencywheel(Highcharts);
const FundFlowChart = memo(() => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const { period } = useGlobalStatusContext();

  const { data, isLoading } = useQuery<Array<FundFlowItem>, FundFlowParams>(
    [
      "fetchFundFlowAll",
      {
        period,
      },
    ],
    {
      refetchOnMount: false,
      retry: false,
    }
  );
  useEffect(() => {
    if (isLoading) {
      chartComponentRef.current?.chart.showLoading();
    } else {
      chartComponentRef.current?.chart.hideLoading();
    }
  }, [isLoading]);

  const rows: Highcharts.SeriesSankeyPointOptionsObject[] = (data || []).map(
    (item) => ({
      from: item.fromProtocol,
      to: item.toProtocol,
      weight: parseFloat(item.netFlow),
    })
  );

  const series: Highcharts.SeriesDependencywheelOptions = {
    keys: ["from", "to", "weight"],
    data: rows,
    type: "dependencywheel",
    dataLabels: {
      color: "#333",
    },
  };
  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    tooltip: {
      headerFormat: "",
    },
    plotOptions: {
      dependencywheel: {
        clip: false,
        dataLabels: {
          overflow: "allow",
        },
      },
    },
    series: [series],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
});

export default FundFlowChart;
