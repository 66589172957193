import { stringifyUrl } from "query-string";
import type {
  GlobalSearchParams,
  GlobalSearchItem,
  GlobalSearchResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchGlobalSearchResult(params: GlobalSearchParams) {
  const url = "/api/search";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    GlobalSearchResponseJson,
    {
      total: number;
      data: Array<GlobalSearchItem>;
    }
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
