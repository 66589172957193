import { stringifyUrl } from "query-string";
import {
  ContractOverview,
  ContractOverviewParams,
  ContractOverviewResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchContractOverview(params: ContractOverviewParams) {
  const url = "/api/v1/arbitrage/stat/contract/";

  const endpoint = stringifyUrl({ url, query: params });

  const overview = await normalizeResponse<
    ContractOverviewResponseJson,
    ContractOverview
  >(fetch(endpoint), (data) => {
    return data.result;
  });

  if (!overview.transactions) {
    throw new Error("contract not found");
  }

  return overview;
}
