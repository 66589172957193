import { stringifyUrl } from "query-string";
import type {
  LiquidationLatestLiquidationItem,
  LiquidationLatestLiquidationParams,
  LiquidationLatestLiquidationResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationLatestLiquidations(
  options: LiquidationLatestLiquidationParams
) {
  const url = "/api/v1/dataservice/liquidation/latestLiquidations/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationLatestLiquidationResponseJson,
    {
      data: Array<LiquidationLatestLiquidationItem>;
      totalCount: number;
      updateTimestamp: number;
    }
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
