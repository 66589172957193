import { stringifyUrl } from "query-string";
import {
  MarketTopFlowAddressItem,
  MarketTopFlowAddressParams,
  MarketTopFlowAddressResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchMarketTopFlowAddress(
  params: MarketTopFlowAddressParams
) {
  const url = "/api/v1/transfer/stat/address/leaderboard/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    MarketTopFlowAddressResponseJson,
    Array<MarketTopFlowAddressItem>
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
