import React, { Suspense, useContext, useEffect, useState } from "react";
import { GlobalStatusProvider, useGlobalStatusContext } from "../GlobalStore";
import { EpPageContext, EpPageProvider } from "../Components/EpPage";

import {
  AppShell,
  Box,
  ColorSchemeProvider,
  MantineProvider,
  Image,
} from "@mantine/core";
import Footer from "./Footer";
import Header from "./Header";
import Navbar, { NAVBAR_COLLAPSE_WIDTH, NAVBAR_EXPAND_WIDTH } from "./Navbar";
import {
  useIsSmallerThanLG,
  useIsSmallerThanMD,
  useIsSmallScreen,
} from "../Components/Utils";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { PageLoadingFallback } from "../Components/LoadingFallback";
import HomePage from "./HomePage";
import ApiDocumentPage from "./ApiDocument/Index";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase-config";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

const BgToImage = () => {
  const location = useLocation();
  const isSmallerThanMD = useIsSmallerThanMD();

  return location?.pathname === "/" ? (
    <Image
      src="./eigenapi/home-bg-top2.svg"
      width="100%"
      height={isSmallerThanMD ? 340 : 500}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
      }}
    />
  ) : null;
};

function MainRouter() {
  const UserInfoPage = React.lazy(() => import("./UserInfoPage"));
  const ApiKeyPage = React.lazy(() => import("./ApiKeyPage"));
  const ApiKeyManagePage = React.lazy(
    () => import("./Manage/ApikeyManagePage")
  );

  const isSmallScreen = useIsSmallScreen();
  const isSmallerThanLG = useIsSmallerThanLG();

  const { navExpand, setNavExpand, navVisibleOnSmallScreen, chain } =
    useGlobalStatusContext();
  const isNavExpand = navExpand !== undefined ? navExpand : !isSmallerThanLG;
  const isNavVisible = isSmallScreen && navVisibleOnSmallScreen;

  const resize = () => {
    if (navExpand !== undefined && !isSmallScreen) {
      setNavExpand(undefined);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  });

  useEffect(() => {
    let classVal = document?.getElementById("root")?.getAttribute("class");

    // 删除root的class
    classVal = classVal?.replace("root-center", "");
    document?.getElementById("root")?.setAttribute("class", classVal || "");
  });

  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={
            <>
              <BgToImage />
              <AppShell
                header={<Header isExtend={isNavExpand} />}
                footer={<Footer />}
                navbar={
                  isNavVisible ? <Navbar isExtend={isNavExpand} /> : undefined
                }
                sx={(theme) => ({
                  main: {
                    padding: 0,
                    overflow: "hidden",
                  },
                })}
              >
                <Box
                  hidden={isSmallScreen && navVisibleOnSmallScreen}
                  sx={(theme) => {
                    return {
                      position: "relative",
                      zIndex: 10,
                    };
                  }}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Suspense fallback={<PageLoadingFallback />}>
                          <EpPageProvider pageId="EigenApiHome">
                            <HomePage />
                          </EpPageProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/center"
                      element={
                        <Suspense fallback={<PageLoadingFallback />}>
                          <EpPageProvider pageId="UserInfo">
                            <UserInfoPage />
                          </EpPageProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/apikey"
                      element={
                        <Suspense fallback={<PageLoadingFallback />}>
                          <EpPageProvider pageId="ApiKey">
                            <ApiKeyPage />
                          </EpPageProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/apikey-manage"
                      element={
                        <Suspense fallback={<PageLoadingFallback />}>
                          <EpPageProvider pageId="ApiKeyManage">
                            <ApiKeyManagePage />
                          </EpPageProvider>
                        </Suspense>
                      }
                    />
                    <Route
                      path="/apidocument"
                      element={
                        <Suspense fallback={<PageLoadingFallback />}>
                          <EpPageProvider pageId="ApiDocument">
                            <ApiDocumentPage />
                          </EpPageProvider>
                        </Suspense>
                      }
                    />
                  </Routes>
                </Box>
              </AppShell>
            </>
          }
        ></Route>
      </Routes>
    </Router>
  );
}

export default function EigenApiApp() {
  const app = initializeApp(firebaseConfig);
  const helmetCtx = useContext(EpPageContext);

  useEffect(() => {
    let classVal = document?.getElementById("root")?.getAttribute("class");

    // 删除root的class
    classVal = classVal?.replace("root-center", "");
    document?.getElementById("root")?.setAttribute("class", classVal || "");
  });

  helmetCtx.setPage({
    pageTitle: "User | EigenAPI",
  });

  return (
    <React.StrictMode>
      <GTMProvider
        state={{
          id: process.env.REACT_APP_GTM_ID,
        }}
      >
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: `'Nunito', sans-serif`,
            colorScheme: "dark",

            components: {
              Badge: {
                styles: (theme) => ({
                  root: {
                    background: "rgba(0,145,255,0.1)",
                    color: "rgba(0,163,255,1)",
                    fontSize: 14,
                    borderRadius: 6,
                  },
                }),
              },
              Code: {
                styles: (theme) => ({
                  root: {
                    border: `dashed 1px ${
                      theme.colorScheme === "dark"
                        ? theme.colors.gray[7]
                        : theme.colors.black
                    }`,
                    padding: 20,
                    background: "transparent",
                  },
                }),
              },
              Card: {
                defaultProps: {
                  radius: "md",
                  p: 30,
                },
                styles: (theme) => ({
                  root: {
                    background:
                      theme.colorScheme === "dark" ? theme.black : theme.white,
                  },
                }),
              },
            },

            globalStyles: (theme) => ({
              body: {
                background: "radial-gradient(#2f2931, #1c1c26);",
                color: theme.colorScheme === "dark" ? theme.white : theme.black,
              },
            }),
          }}
        >
          <style>
            @import
            url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
          </style>
          <GlobalStatusProvider>
            <MainRouter />
          </GlobalStatusProvider>
        </MantineProvider>
      </GTMProvider>
    </React.StrictMode>
  );
}
