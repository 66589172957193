import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  SandwichAttackerVictimsParams,
  SandwichAttackerVictim,
  SandwichAttackerVictimsResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichAttackerVictim(
  options: SandwichAttackerVictimsParams
) {
  const url = "/api/v1/dataservice/sandwich/attacker/victims/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichAttackerVictimsResponseJson,
    Array<SandwichAttackerVictim>
  >(fetch(endpoint), (data) => {
    return (data?.result ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
