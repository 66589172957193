import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  SandwichVictimAttackersParams,
  SandwichVictimAttacker,
  SandwichVictimAttackersResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichVictimAttacker(
  options: SandwichVictimAttackersParams
) {
  const url = "/api/v1/dataservice/sandwich/victim/attackers/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichVictimAttackersResponseJson,
    Array<SandwichVictimAttacker>
  >(fetch(endpoint), (data) => {
    return (data?.result ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
