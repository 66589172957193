import React from "react";
import { Button, ButtonProps } from "@mantine/core";

interface Props extends ButtonProps {
  onClick?: () => void;
}

function GradientButton(props: Props) {
  return (
    <Button
      {...props}
      sx={{
        height: 42,
        fontSize: 16,
        background:
          "linear-gradient(270deg,#148ee0, #00b591 73%, #44d7b6 100%);",
        border: "none",
        ...props.sx,
      }}
    ></Button>
  );
}

export default GradientButton;
