import React, { useRef, useState } from "react";
import {
  Text,
  Navbar as MNavbar,
  Divider,
  Box,
  Tooltip,
  ActionIconVariant,
  ActionIcon,
  Anchor,
  MantineTheme,
  ScrollArea,
  Image,
} from "@mantine/core";
import { FaSun, FaMoon } from "react-icons/fa";
import { UnstyledButton, Group, useMantineColorScheme } from "@mantine/core";
// import { useNavigate, Link, ROUTER_BASE } from "../Components/Link";
import { useGlobalStatusContext } from "../GlobalStore";
import { useIsSmallScreen } from "../Components/Utils";
import Icon from "../Components/Icon";
import { useNavigate } from "react-router";
import { getAuth, signOut } from "firebase/auth";
// import { ChainSwitch } from "./Components/ChainSwitch";

interface MainLinkProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  href?: string;
  isExtend?: boolean;
  navExpandOnClickDid?: boolean;
  onClick?: React.ReactEventHandler;
  alt?: string;
  external?: boolean;
  inline?: boolean;
  iconVariant?: ActionIconVariant;
  id?: string;
  preload?: Function;
}

function BottomLink({
  icon,
  label,
  href,
  isExtend,
  navExpandOnClickDid,
  onClick,
  alt,
  external,
  inline,
  iconVariant = "transparent",
  id,
  preload,
}: MainLinkProps) {
  const [tooltipOpened] = useState(false);

  const iconWrap = (
    <ActionIcon
      variant={iconVariant}
      sx={(theme) => ({
        borderRadius: "50%",
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[2],
        color: theme.colors.gray[6],
        "&:hover": {
          color: theme.white,
          backgroundColor: "#44D7B6",
        },
      })}
    >
      {icon}
    </ActionIcon>
  );

  const sx = (theme: MantineTheme) => ({
    display: "block",
    width: "100%",
    textDecoration: "none",
    padding: theme.spacing.xs,
    borderRadius: "50%",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    ...(href &&
    (window.location.pathname + window.location.hash).endsWith(href) &&
    !external
      ? {
          backgroundColor: "red",
        }
      : {
          "&:hover": {
            backgroundColor: "rgba(68,215,182,0.10)",
            textDecoration: "none",
          },
        }),
  });

  const btn = (
    <Anchor href={href} target="_blank" sx={sx}>
      {iconWrap}
    </Anchor>
  );

  return (
    <Tooltip
      opened={tooltipOpened}
      label={alt}
      position={inline ? (isExtend ? "top" : "right") : "right"}
      // placement="center"
      withArrow
      // styles={{
      //   body: {
      //     pointerEvents: "none",
      //     textAlign: "center",
      //   },
      //   root: {
      //     display: inline && isExtend ? "inline-block" : "block",
      //   },
      // }}
    >
      {btn}
    </Tooltip>
  );
}

function MainLink({
  icon,
  label,
  href,
  isExtend,
  navExpandOnClickDid,
  onClick,
  alt,
  external,
  inline,
  iconVariant = "transparent",
  id,
  preload,
}: MainLinkProps) {
  const navigate = useNavigate();
  const { navExpand, setNavExpand } = useGlobalStatusContext();
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { setNavVisibleOnSmallScreen } = useGlobalStatusContext();

  const ref = useRef(null);

  const onClickFunc: React.MouseEventHandler<HTMLButtonElement> = (...args) => {
    setTooltipOpened(false);
    if (!inline && !external && id !== "expand") {
      setNavVisibleOnSmallScreen(false);
    }
    if (href && !external) {
      if (window.location.pathname.startsWith("/eigentx/")) {
        window.location.href = href;
      } else {
        navigate(href);
      }
    }
    if (navExpandOnClickDid !== undefined) {
      setNavExpand(!!navExpandOnClickDid);
    }
    if (onClick) {
      onClick?.apply(ref, args);
    }
  };

  const sx = (theme: MantineTheme) => ({
    display: "block",
    width: "100%",
    textDecoration: "none",
    padding: theme.spacing.xs,
    borderRadius: theme.radius.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    ...(href &&
    (window.location.pathname + window.location.hash).endsWith(href) &&
    !external
      ? {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[2],
          ".iconfont": {
            color: theme.colorScheme === "dark" ? "#44D7B6" : "#54B498",
          },
        }
      : {
          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[5]
                : theme.colors.gray[1],
            textDecoration: "none",
            ".iconfont": {
              color: theme.colorScheme === "dark" ? "#44D7B6" : "#54B498",
            },
          },
        }),
  });
  const iconWrap = (
    <Group position={isExtend ? "left" : "center"}>
      {icon ? (
        <ActionIcon
          variant={iconVariant}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
            color:
              theme.colorScheme === "dark"
                ? theme.colors.gray[6]
                : theme.colors.gray[inline ? 6 : 8],
            "&:hover": {
              color: "#44D7B6",
            },
          })}
        >
          {icon}
        </ActionIcon>
      ) : null}
      {isExtend && !inline ? <Text size="sm">{label}</Text> : null}
    </Group>
  );

  const btn = external ? (
    <Anchor href={href} target="_blank" sx={sx}>
      {iconWrap}
    </Anchor>
  ) : (
    <UnstyledButton
      ref={ref}
      onTouchStart={() => {
        setIsMobile(true);
      }}
      onMouseEnter={(event: React.MouseEvent<HTMLButtonElement>) => {
        if (isMobile) {
          onClickFunc.call(ref, event);
        } else {
          if (navExpand === false || !isExtend || inline) {
            setTooltipOpened(true);
          } else {
            setTooltipOpened(false);
          }
          preload && preload();
        }
      }}
      onMouseLeave={() => {
        setTooltipOpened(false);
      }}
      onClick={onClickFunc}
      sx={sx}
    >
      {iconWrap}
    </UnstyledButton>
  );
  return alt ? (
    <Tooltip
      opened={tooltipOpened}
      withinPortal
      label={alt}
      position={inline ? (isExtend ? "top" : "right") : "right"}
      withArrow
      styles={{
        tooltip: {
          display: inline && isExtend ? "inline-block" : "block",
        },
      }}
    >
      {btn}
    </Tooltip>
  ) : (
    btn
  );
}

export const NAVBAR_EXPAND_WIDTH = 260;
export const NAVBAR_COLLAPSE_WIDTH = 60;
export function MainLinks({ isExtend }: { isExtend?: boolean }) {
  const { user, setUser } = useGlobalStatusContext();
  const ref = useRef<HTMLDivElement>(null);
  const isSmallScreen = useIsSmallScreen();
  const firebaseAuth = getAuth();
  const navigate = useNavigate();

  const linkData1: Array<MainLinkProps> = [
    user
      ? {
          label: (
            <Group noWrap>
              <ActionIcon w={40} h={40}>
                <img src={user.photoURL!} style={{ borderRadius: 6 }} alt="" />
              </ActionIcon>
              <Box>
                <Text
                  sx={(t) => ({
                    color: t.colorScheme === "dark" ? t.white : t.black,
                  })}
                  fw={700}
                  size={16}
                >
                  {user.displayName}
                </Text>
                <Text
                  sx={(t) => ({
                    color: t.colorScheme === "dark" ? t.white : t.black,
                  })}
                  size={14}
                >
                  {user.email}
                </Text>
              </Box>
            </Group>
          ),
        }
      : null,
    user
      ? {
          // icon: <Icon name="icon-sandwich" size={16} />,
          label: "API Key",
          alt: "ApiKey",
          href: `/apikey`,
        }
      : null,
    {
      // icon: <Icon name="icon-sandwich" size={16} />,
      label: "API Document",
      alt: "ApiDocument",
      href: "https://eigenphi-1.gitbook.io/eigenapi-user-guide/introduction/welcome",
      external: true,
    },
  ].filter(Boolean) as any;

  const linkData3: Array<MainLinkProps> = [
    user
      ? {
          // icon: <Icon name="icon-sandwich" size={16} />,
          label: "Logout",
          alt: "Logout",
          onClick: () => {
            signOut(firebaseAuth);
            setUser(null);
            navigate("/");
          },
        }
      : null,
  ].filter(Boolean) as any;

  const links1 = linkData1.map((link) => (
    <MainLink
      {...link}
      key={link.label?.toString()}
      isExtend={isExtend}
      navExpandOnClickDid={link.navExpandOnClickDid}
    />
  ));
  const links3 = linkData3.map((link) => (
    <MainLink
      {...link}
      key={link.label?.toString()}
      isExtend={isExtend}
      navExpandOnClickDid={link.navExpandOnClickDid}
    />
  ));

  return (
    <Group position="center" sx={{ flexDirection: "column" }} ref={ref}>
      <Box
        sx={{
          width: isSmallScreen
            ? window.innerWidth - 20
            : isExtend === false
            ? 50
            : 240,
        }}
      >
        {links1}
        {/* <Divider
          sx={(theme) => ({
            borderTopColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[2],
            marginLeft: 10,
            marginRight: 10,
            marginTop: 15,
            marginBottom: 15,
          })}
        /> */}

        {/* {isSmallScreen ? (
          <>
            <Divider
              sx={(theme) => ({
                borderTopColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[2],
                marginLeft: 10,
                marginRight: 10,
                marginTop: 15,
                marginBottom: 15,
              })}
            />
            <MainLink
              key="theme"
              isExtend={isExtend}
              icon={dark ? <FaSun /> : <FaMoon />}
              label={dark ? "Light Theme" : "Dark Theme"}
              alt={dark ? "Light Theme" : "Dark Theme"}
              onClick={() => {
                toggleColorScheme();
              }}
            />
          </>
        ) : null} */}
        {isSmallScreen && user ? (
          <>
            <Divider
              sx={(theme) => ({
                borderTopColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.gray[2],
                marginLeft: 10,
                marginRight: 10,
                marginTop: 15,
                marginBottom: 15,
              })}
            />
            {links3}
          </>
        ) : null}
      </Box>
    </Group>
  );
}

export function BottomLinks({ isExtend }: { isExtend?: boolean }) {
  const isSmallScreen = useIsSmallScreen();
  const inlineRef = useRef<HTMLDivElement>(null);

  const linkData4: Array<MainLinkProps> = [
    {
      icon: <Icon name="icon-twitter" size={14} />,
      label: "Twitter",
      alt: "Twitter",
      href: `https://twitter.com/eigenphi`,
      external: true,
      inline: true,
    },
    {
      icon: <Icon name="icon-discord" size={14} />,
      label: "Discord",
      alt: "Discord",
      href: `https://discord.gg/2B96UzwpHB`,
      external: true,
      inline: true,
    },
    {
      icon: <Icon name="icon-medium" size={14} />,
      label: "Medium",
      alt: "Medium",
      href: `https://medium.com/@eigenphi/`,
      external: true,
      inline: true,
    },
    {
      icon: <Icon name="icon-mirror" size={14} />,
      label: "Mirror",
      alt: "Mirror",
      href: `https://mirror.xyz/0xc19565163aFdEe3783FC970E4Bd0275B11848d34`,
      external: true,
      inline: true,
    },
  ];
  const links4 = linkData4.map((link) => (
    <BottomLink
      {...link}
      key={link.label?.toString()}
      isExtend={isExtend}
      navExpandOnClickDid={link.navExpandOnClickDid}
    />
  ));
  return (
    <>
      {isExtend ? null : (
        <Divider
          sx={(theme) => ({
            borderTopColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[2],
            marginLeft: 10,
            marginRight: 10,
            marginTop: 15,
            marginBottom: 15,
          })}
        />
      )}
      <Box
        sx={(theme) => ({
          width: isSmallScreen
            ? "100%"
            : isExtend
            ? NAVBAR_EXPAND_WIDTH
            : NAVBAR_COLLAPSE_WIDTH,
          background:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
          padding: "10px 10px 20px 10px",
          borderRight: `1px solid ${
            theme.colorScheme === "dark"
              ? theme.colors.dark[5]
              : theme.colors.gray[2]
          }`,
        })}
        ref={inlineRef}
      >
        <Group position="center" spacing={"xs"}>
          {links4}
        </Group>
      </Box>
    </>
  );
}

export default function Navbar({ isExtend }: { isExtend?: boolean }) {
  const isSmallScreen = useIsSmallScreen();

  return (
    <MNavbar
      hiddenBreakpoint={"sm"}
      width={{
        base: isSmallScreen
          ? "100%"
          : isExtend
          ? NAVBAR_EXPAND_WIDTH
          : NAVBAR_COLLAPSE_WIDTH,
      }}
      sx={{
        marginTop: 60,
        top: 0,
        paddingBottom: isSmallScreen ? 100 : 0,
      }}
      fixed
    >
      {/* {isSmallScreen ? null : (
        <MNavbar.Section
          sx={{
            width: isSmallScreen
              ? "100%"
              : isExtend
              ? NAVBAR_EXPAND_WIDTH
              : NAVBAR_COLLAPSE_WIDTH,
            background:
              theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
            padding: isExtend ? 10 : 5,
          }}
        >
          <ChainSwitch isCollapse={!isExtend} />
        </MNavbar.Section>
      )} */}
      <MNavbar.Section mt="xs" grow component={ScrollArea}>
        <MainLinks isExtend={isExtend}></MainLinks>
      </MNavbar.Section>
      {/* <MNavbar.Section>
        <BottomLinks isExtend={isExtend}></BottomLinks>
      </MNavbar.Section> */}
    </MNavbar>
  );
}
