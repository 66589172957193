import { stringifyUrl } from "query-string";
import type {
  AnalyseTransaction,
  AnalyseTransactionResponseJson,
  AnalyseTransactionParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchAnalyseTransaction(
  options: AnalyseTransactionParams
) {
  const url = "/api/v1/analyseTransaction";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    AnalyseTransactionResponseJson,
    Array<AnalyseTransaction>
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
