import React from "react";
import { Tabs, TabsProps } from "@mantine/core";
function NavTabs(props: TabsProps) {
  return (
    <Tabs
      styles={(theme) => ({
        tab: {
          color:
            theme.colorScheme === "dark" ? theme.white : theme.colors.gray[9],
          padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
          cursor: "pointer",
          fontSize: theme.fontSizes.sm,
          display: "flex",
          alignItems: "center",
          "&[data-active]": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            color: theme.white,
          },
        },

        tabIcon: {
          marginRight: theme.spacing.xs,
          display: "flex",
          alignItems: "center",
        },

        tabsList: {
          display: "flex",
          gap: 10,
          flexWrap: "nowrap",
          border: "none",
        },
      })}
      color="gray"
      variant="pills"
      {...props}
    />
  );
}

export default NavTabs;
