import { stringifyUrl } from "query-string";
import type {
  NewAssetsItem,
  NewAssetsParams,
  NewAssetsResponseJson,
  ApiWithPagination,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchNewPools(params: NewAssetsParams) {
  const url = "/api/v1/dataservice/newAssets/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    NewAssetsResponseJson<typeof params.id>,
    ApiWithPagination<Array<NewAssetsItem>>
  >(fetch(endpoint), (data) => {
    return {
      result: data?.result ?? [],
      nextPage: (params.pageNum ?? 0) + 1,
      pageSize: params.pageSize ?? 15,
    };
  });
}
