import { stringifyUrl } from "query-string";
import {
  ProtocolFundFlowItem,
  ProtocolFundFlowsParams,
  ProtocolFundFlowsResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchFoudFlows(params: ProtocolFundFlowsParams) {
  const url = "/api/v1/protocol/stat/fund-flow/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    ProtocolFundFlowsResponseJson,
    Array<ProtocolFundFlowItem>
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
