import { stringifyUrl } from "query-string";
import {
  FeedbackCommentParams,
  FeedbackCommentResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchFeedbackComment(params: FeedbackCommentParams) {
  const url = "/api/v1/dataservice/feedback/comment";

  const endpoint = stringifyUrl({ url, query: params });
  const feedback = await normalizeResponse<
    FeedbackCommentResponseJson,
    string
  >(fetch(endpoint, {
    method: 'POST',
  }), (data) => {
    return data.id;
  });

  return feedback;
}
