import React, { useContext, useState } from "react";
import { useGlobalStatusContext } from "../GlobalStore";
import { EpPageContext } from "../Components/EpPage";

import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {
  Accordion,
  Anchor,
  Box,
  Button,
  Card,
  Center,
  Grid,
  Group,
  Image,
  MantineProvider,
  Space,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import GradientButton from "./Component/GradientButton";
import GrayButton from "./Component/GrayButton";
import { useIsSmallerThanMD } from "../Components/Utils";
import { useScrollIntoView } from "@mantine/hooks";
import LoginWrapper from "./Component/LoginWrapper";

const HomeBox = (props: any) => {
  const isSmallerThanMD = useIsSmallerThanMD();

  return (
    <Box
      sx={{ background: props.showBgColor ? "#1C1C26" : "" }}
      px={isSmallerThanMD ? 10 : 0}
      {...props}
    >
      <Box sx={{ maxWidth: 996, margin: "0 auto" }}>{props.children}</Box>
    </Box>
  );
};

const IntroContent = () => {
  const isSmallerThanMD = useIsSmallerThanMD();

  const list = [
    {
      text: "Full-scale real-time MEV data",
      image: "./eigenapi/home-card0-noun-realtime.png",
    },
    {
      text: "State-of-the-art MEV identification algorithm",
      image: "./eigenapi/home-card0-noun-algorithm.png",
    },
    {
      text: "Latest P/L calculation methods",
      image: "./eigenapi/home-card0-noun-diversification.png",
    },
  ];

  const content = (
    <Group
      w="100%"
      sx={{
        flexDirection: "column",
        alignItems: "self-start",
        position: "relative",
      }}
      spacing={36}
    >
      {isSmallerThanMD ? null : (
        <Group
          spacing={40}
          sx={{
            position: "absolute",
            left: 30,
            top: -10,
            flexDirection: "column",
            alignItems: "self-start",
          }}
        >
          {list.map((item) => {
            return (
              <Image key={item.text} width={56} height={56} src={item.image} />
            );
          })}
        </Group>
      )}
      {list.map((item) => {
        return (
          <Group
            key={item.text}
            spacing={10}
            noWrap
            w="100%"
            pl={30}
            pr={20}
            py={15}
            sx={{
              background:
                "linear-gradient(90deg, rgba(255, 88, 208, 0.2), rgba(100, 173, 255, 0.2))",
              borderRadius: 10,
              position: "relative",
            }}
          >
            {isSmallerThanMD ? (
              <Box w={50}>
                <Image width={50} height={50} src={item.image} />
              </Box>
            ) : null}
            <Text
              color="#FFFFFF"
              size={isSmallerThanMD ? 16 : 20}
              ml={isSmallerThanMD ? 0 : 80}
            >
              {item.text}
            </Text>
          </Group>
        );
      })}
    </Group>
  );

  return (
    <Box
      sx={{
        maxWidth: isSmallerThanMD ? 700 : "",
        margin: "0 auto",
        backgroundImage: "url(/eigenapi/home-pattern2.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: isSmallerThanMD ? "850px 250px" : "1290px 379px",
        backgroundPositionX: "center",
      }}
    >
      <Group
        position="center"
        w="100%"
        spacing={80}
        noWrap
        px={isSmallerThanMD ? 0 : 50}
      >
        {content}
        {isSmallerThanMD ? null : (
          <Box>
            <Image width={260} src="./eigenapi/home-card-0.png" />
          </Box>
        )}
      </Group>
    </Box>
  );
};

export function StartedContent() {
  const isSmallerThanMD = useIsSmallerThanMD();
  const { user } = useGlobalStatusContext();

  const content = (
    <Box>
      <Group position="apart">
        <Box>
          <Title order={6} size={18} color="white">
            Pro Plan
          </Title>
          <Space h={10} />
          <Text size={14} color="white">
            Best Settings for Business
          </Text>
        </Box>
        <Group align={"baseline"} spacing={4}>
          <Text
            size={16}
            color="white"
            sx={{ fontFamily: "Verdana, Verdana-Regular" }}
          >
            $
          </Text>
          <Text size={38} color="white">
            999
          </Text>
          <Text size={12} color="white">
            / mo
          </Text>
        </Group>
      </Group>

      <Space h={26} />
      <Grid columns={5}>
        <Grid.Col span={4}>
          <Text size={14} color="white">
            <Text color="#44D7B6" sx={{ display: "inline-block" }}>
              EigenPhi
            </Text>{" "}
            is the leading MEV and liquidity data analysis platform in the DeFi
            industry.
          </Text>
        </Grid.Col>
        <Grid.Col span={1}></Grid.Col>
        <Grid.Col span={4}>
          <Text size={14} color="white">
            As an{" "}
            <Text color="#44D7B6" sx={{ display: "inline-block" }}>
              EigenAPI
            </Text>{" "}
            user, you'll have access to the latest version of our API and
            real-time MEV data.
          </Text>
        </Grid.Col>
        <Grid.Col span={1}></Grid.Col>
        <Grid.Col span={5}>
          <Space h={7} />
        </Grid.Col>
      </Grid>
      <Space h={20} />
      <Center>
        <Group noWrap spacing={20}>
          <Anchor href="https://commerce.coinbase.com/checkout/5b136e47-2180-4373-995d-bba399e28b66">
            <GradientButton
              w={isSmallerThanMD ? 140 : 184}
              sx={{
                fontSize: 14,
              }}
            >
              Purchase
            </GradientButton>
          </Anchor>
          {user ? (
            <Anchor href="https://docs.google.com/forms/d/e/1FAIpQLSctOW0d0ipkb4M6BShyWmZdDBsjE31ZVpAWehaNPyclrBEaRw/viewform?usp=sf_link">
              <GradientButton
                w={isSmallerThanMD ? 140 : 184}
                sx={{
                  fontSize: 14,
                }}
              >
                Contact Us
              </GradientButton>
            </Anchor>
          ) : (
            <LoginWrapper>
              {({ checkLogin }: any) => (
                <GradientButton
                  onClick={checkLogin}
                  w={isSmallerThanMD ? 140 : 184}
                  sx={{
                    fontSize: 14,
                  }}
                >
                  Contact Us
                </GradientButton>
              )}
            </LoginWrapper>
          )}
        </Group>
      </Center>
    </Box>
  );

  if (isSmallerThanMD) {
    return (
      <Box
        p={20}
        sx={{
          maxWidth: 700,
          margin: window.location.pathname === "/apikey" ? "" : "0 auto",
          background: "#141517",
          borderRadius: 20,
        }}
      >
        {content}
      </Box>
    );
  }

  return (
    <Card
      p={0}
      sx={{
        width: isSmallerThanMD ? "100%" : 996,
        background: isSmallerThanMD
          ? ""
          : "url(/eigenapi/home-card-1p.png) no-repeat",
        borderRadius: 20,
      }}
    >
      <Group noWrap>
        {isSmallerThanMD ? null : <Space h={400} w={531} />}
        <Box pr={67}>{content}</Box>
      </Group>
    </Card>
  );
}

const GithubDemoContent = () => {
  const isSmallerThanMD = useIsSmallerThanMD();

  const content = (
    <Box w="100%">
      <Text
        size={isSmallerThanMD ? 24 : 32}
        sx={{ textAlign: isSmallerThanMD ? "center" : "left" }}
      >
        Check Out the Demo
      </Text>
      <Space h={30} />
      <Box
        px={20}
        py={10}
        sx={{
          border: "1px solid",
          borderImage:
            "linear-gradient(162deg, #d4088c 0%, #8d139b 29%, #6ee1f8 64%, rgba(110,232,252,0.62) 99%, #6ee8fc 100%) 1 1",
          borderRadius: 10,
        }}
      >
        <Text size={isSmallerThanMD ? 14 : 18}>
          Open source code repository
        </Text>
      </Box>
      <Space h={20} />
      <Box
        px={20}
        py={10}
        sx={{
          border: "1px solid",
          borderImage:
            "linear-gradient(162deg, #d4088c 0%, #8d139b 29%, #6ee1f8 64%, rgba(110,232,252,0.62) 99%, #6ee8fc 100%) 1 1",
          borderRadius: 10,
        }}
      >
        <Text size={isSmallerThanMD ? 14 : 18}>
          Scenario-based demonstration
        </Text>
      </Box>
      <Space h={20} />
      <Box
        px={20}
        py={10}
        sx={{
          border: "1px solid",
          borderImage:
            "linear-gradient(162deg, #d4088c 0%, #8d139b 29%, #6ee1f8 64%, rgba(110,232,252,0.62) 99%, #6ee8fc 100%) 1 1",
          borderRadius: 10,
        }}
      >
        <Text size={isSmallerThanMD ? 14 : 18}>Real-life User Cases</Text>
      </Box>
      <Space h={20} />
      <Box sx={{ textAlign: isSmallerThanMD ? "center" : "left" }}>
        <Anchor
          href="https://github.com/eigenphi/EigenAPI-demo"
          target={"_blank"}
          rel="noreferrer"
        >
          <GradientButton w={184}>Try Out On Github</GradientButton>
        </Anchor>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ maxWidth: isSmallerThanMD ? 700 : "", margin: "0 auto" }}>
      <Group
        position="center"
        w="100%"
        spacing={150}
        noWrap
        px={isSmallerThanMD ? 0 : 50}
      >
        {content}
        {isSmallerThanMD ? null : (
          <Box>
            <Image
              width={374}
              height={308}
              src="./eigenapi/home-card-3p@2x.png"
            />
          </Box>
        )}
      </Group>
    </Box>
  );
};

export default function HomePage() {
  const isSmallerThanMD = useIsSmallerThanMD();
  const [keys, setKeys] = useState<string[]>([]);
  const firebaseAuth = getAuth();
  const provider = new GoogleAuthProvider();
  const helmetCtx = useContext(EpPageContext);
  helmetCtx.setPage({
    pageTitle: "Home | EigenAPI",
  });
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>();

  return (
    <MantineProvider
      inherit
      theme={{
        globalStyles: (theme) => ({
          html: {
            backgroundImage:
              "radial-gradient(circle at 11% 4.5%, #2F2931 0%, #1C1C26 46%)",
            backgroundColor: theme.black,
          },
          // body: {
          //   backgroundImage: "url(/eigenapi/home-bg-top.svg)",
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: isSmallerThanMD ? "auto 340px" : "auto 500px",
          //   backgroundPositionX: "center",
          // },
        }),
      }}
    >
      <Box
        sx={{
          backgroundImage: "url(/eigenapi/home-pattern.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: isSmallerThanMD ? "850px 250px" : "1290px 379px",
          backgroundPositionX: "center",
        }}
      >
        <Space h={isSmallerThanMD ? 50 : 86} />
        <Center>
          <Text size={isSmallerThanMD ? 20 : 32}>
            Build Precise & Reliable Apps with
          </Text>
        </Center>
        <Space h={20} />
        <Center>
          <Image
            width={isSmallerThanMD ? 225 : 357}
            height={isSmallerThanMD ? 38 : 60}
            src="/eigenapi/logo-color.svg"
          />
        </Center>
        <Space h={isSmallerThanMD ? 36 : 55} />
        <Center>
          <Group>
            <GradientButton
              w={isSmallerThanMD ? 160 : 204}
              sx={{ fontSize: 18 }}
              onClick={() => scrollIntoView({ alignment: "center" })}
            >
              Get Started
            </GradientButton>
            <Anchor
              href="https://eigenphi-1.gitbook.io/eigenapi-user-guide/introduction/welcome"
              target={"_blank"}
              rel="noreferrer"
            >
              <GrayButton w={isSmallerThanMD ? 160 : 204} sx={{ fontSize: 18 }}>
                API Document
              </GrayButton>
            </Anchor>
          </Group>
        </Center>
        <Space h={isSmallerThanMD ? 60 : 110} />
      </Box>
      <Box>
        <HomeBox
          py={isSmallerThanMD ? 32 : 80}
          sx={{
            backgroundImage:
              "linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.20) 100%)",
          }}
        >
          <IntroContent />
        </HomeBox>
        <HomeBox showBgColor py={isSmallerThanMD ? 36 : 60}>
          <Box>
            <Center>
              <Text size={isSmallerThanMD ? 24 : 32}>
                Ready to get started?
              </Text>
            </Center>
            <Center>
              <Text
                size={isSmallerThanMD ? 14 : 18}
                sx={{ textAlign: "center" }}
              >
                Select the plan that best suits you or contact us for a custom
                option.
              </Text>
            </Center>
          </Box>
          <Space h={isSmallerThanMD ? 20 : 50} />
          <Box
            sx={{
              padding: 0,
            }}
            ref={targetRef}
          >
            <StartedContent />
          </Box>
        </HomeBox>
        <HomeBox
          py={isSmallerThanMD ? 36 : 80}
          sx={{ background: "rgba(0,0,0,0.16)" }}
        >
          <GithubDemoContent />
        </HomeBox>
        <HomeBox
          showBgColor
          pt={isSmallerThanMD ? 36 : 43}
          pb={isSmallerThanMD ? 30 : 128}
        >
          <Box>
            <Center>
              <Text size={isSmallerThanMD ? 24 : 32}>
                Frequently Asked Questions
              </Text>
            </Center>
            <Space h={14} />
            <Center>
              <Text
                size={isSmallerThanMD ? 14 : 18}
                sx={{ textAlign: isSmallerThanMD ? "center" : "left" }}
              >
                Select the plan that best suits you or contact us for a custom
                option.
              </Text>
            </Center>
          </Box>
          <Space h={isSmallerThanMD ? 30 : 50} />

          <Accordion
            variant="separated"
            defaultValue="customization"
            sx={{ maxWidth: isSmallerThanMD ? 700 : "", margin: "0 auto" }}
            styles={{
              item: {
                background: "rgba(255,255,255,0.03)",
                borderRadius: 20,
                "&:hover": {
                  backgroundImage:
                    "linear-gradient(270deg, rgba(116,235,213,0.06) 0%, rgba(159,172,230,0.06) 100%)",
                },
              },
            }}
          >
            {[
              {
                q: "How can I sign up for EigenAPI services?",
                a: `
                To subscribe to our API service, simply click on the "Contact Us" button on our website, and fill out the form with your contact information and details about your business needs. Once we receive your request, our team will respond immediately to provide you with all the information you need to get started.
                  `,
              },

              {
                q: "How can I pay for the service?",
                a: `
                We're currently in the process of implementing a direct payment method for our customers, and the payment method will be available very soon.
In the meantime, if you're interested in using our services, please click on the "Contact Us" button on our website and fill out the form with your contact information. Our team will then be in touch with you to discuss your payment options and provide you with all the information you need to subscribe.
We offer flexible payment options to meet your specific needs, and will work closely with you to provide a payment solution that fits your budget and timeline.If you have any questions about our payment options or need help subscribing to our services, don't hesitate to contact us.

                  `,
              },

              {
                q: "What should I do once I get the API key?",
                a: `
                Once you receive the API key, the first recommended step is to carefully read the API documentation to understand the detailed instructions on how to access and use the API.
If you encounter any challenges or have questions while going through the documentation, don't hesitate to reach out to our team for assistance. We are available to guide you through each step of the process and ensure that you can successfully retrieve and use the data.
                  `,
              },

              {
                q: "How does the renewal work for API pro accounts?",
                a: `
                We offer flexible subscription plans tailored to meet your specific needs, and you can choose the plan that suits you best. Please contact us to discuss the available subscription options and choose the one that matches your target service.
                  `,
              },
            ].map((item) => (
              <Accordion.Item value={item.q}>
                <Accordion.Control>{item.q}</Accordion.Control>
                <Accordion.Panel>{item.a}</Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </HomeBox>
      </Box>
    </MantineProvider>
  );
}
