import { Popover, PopoverProps, useMantineTheme } from "@mantine/core";
import { cloneElement, memo, useState } from "react";

interface IPopoverProps extends Omit<PopoverProps, "opened"> {
  opened?: boolean;
  trigger?: "click" | "hover";
  target: React.ReactNode;
}

const EpPopover = memo((props: IPopoverProps) => {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();

  let target = props.target;
  if (!props.onClose) {
    target = cloneElement(
      props.target as any,
      props.trigger === "click"
        ? {
            onClick: (e: any) => {
              e.stopPropagation();
              setOpened(!opened);
            },
          }
        : {
            onMouseEnter: () => setOpened(true),
            onMouseLeave: () => setOpened(false),
          }
    );
  }

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      {...props}
      position={"right-start"}
      styles={{
        ...props.styles,
        dropdown: {
          boxShadow:
            theme.colorScheme === "dark"
              ? "0px 4px 12px 0px #000000"
              : "0px 4px 12px 0px #e9ecef",
          borderRadius: 8,
          overflow: "hidden",
          ...(props?.styles as any)?.dropdown,
        },
      }}
    >
      <Popover.Target>{target}</Popover.Target>
      <Popover.Dropdown>{props.children}</Popover.Dropdown>
    </Popover>
  );
});

export default EpPopover;
