import { stringifyUrl } from "query-string";
import type {
  TokenLpsParams,
  TokenLpsPagination,
  TokenLpsResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchTokenLps(params: TokenLpsParams) {
  const url = "/api/v1/arbitrage/stat/token/Lps/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<TokenLpsResponseJson, TokenLpsPagination>(
    fetch(endpoint),
    (data) => {
      return data.result;
    }
  );
}
