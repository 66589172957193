import { stringifyUrl } from "query-string";
import type {
  ContractArbitrageTypeDistributionItem,
  LPTransactionParams,
  ContractArbitrageTypeDistributionResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchContractArbitrageTypeDistribution(
  params: LPTransactionParams
) {
  const url = "/api/v1/arbitrage/stat/arbitrageTypeDistribution/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    ContractArbitrageTypeDistributionResponseJson,
    ContractArbitrageTypeDistributionItem
  >(fetch(endpoint), (data) => {
    return data?.result?.data;
  });
}
