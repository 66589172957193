import React, { memo } from "react";
import { TransactionDataGrid } from "./TransactionDataGrid";
import { useLiveStream } from "../Components/useLiveStream";

export const TransactionStream = memo(function ({
  chain,
  bufferType,
  contentHeight,
  maxCount,
  showFullContract,
}: {
  chain: string;
  bufferType: "time" | "count";
  contentHeight?: number;
  maxCount?: number;
  showFullContract?: boolean;
}) {
  const [filterSucceed /*, setFilterSucceed*/] = React.useState(false);

  /*
  const [rows] = useTransactionStream({
    filterSucceed,
    chain,
    bufferType,
    maxCount,
  });
  */

  const rows = useLiveStream({});

  /*
  getDocs(aQuery).then(function (snapshot) {
    const list = snapshot.docs.map((doc) => {
      const message = doc.data();
      message.key = uuid();
      return message;
    });

    setRows(list as unknown as ArbitrageTransactionMessage[]);
  });
  */

  return (
    <div>
      {/*
        <Group position="right">
          <Text>Success Only</Text>
          <Switch
            arir-label="Success Only"
            checked={filterSucceed}
            onChange={(event) => setFilterSucceed(event.currentTarget.checked)}
          />
        </Group>
      */}
      <TransactionDataGrid
        input={rows}
        contentHeight={contentHeight}
        showFullContract={showFullContract}
      />
    </div>
  );
});
