import React, { memo, useState } from "react";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import {
  MantineSize,
  ThemeIcon,
  Badge,
  Group,
  Text,
  CSSObject,
  Tooltip,
} from "@mantine/core";
import { Token } from "../api/type";
import { shrink } from "./Utils";
import { Link } from "./Link";
import Blockies from "react-blockies";
import { TdText } from "./EpTable";
import { TokenIcon, VirtualTokenBase64 } from "./EpIcon";
import EpPopover from "./EpPopover";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { firebaseEnv } from "../firebase-config";

export type ShowMoreLabelStyle = "hover-label" | "hover-address";

let TokenCache: { [address: string]: any } = {};

export const resetTokenCache = () => {
  TokenCache = {};
};
export async function fillTokenInfo(chain: string, token: any) {
  let data;

  const addressPrefix = token?.address?.includes(":")
    ? token?.address?.split(":")[0]
    : "";

  if (TokenCache[token.address]) {
    data = TokenCache[token.address];
  } else {
    const realAddress = token.address.includes(":")
      ? token.address.split(":")[1]
      : token.address;

    const path = `/${firebaseEnv}/${chain}/labels/${realAddress}`;

    const snapshot = await getDoc(doc(getFirestore(), path));
    data = snapshot.data();

    TokenCache[token.address] = data;
  }

  token.name = `${addressPrefix}${data?.token?.name}`;

  token.symbol = `${addressPrefix}${data?.token?.symbol}`;

  token.decimals = data?.token?.decimals;
  return token;
}

const TokenIconWrapper = memo(
  ({
    address,
    chain,
    name,
    virtual,
    iconSize,
    roundIcon,
  }: {
    address: string;
    chain: string;
    name: string;
    virtual: boolean;
    iconSize: string;
    roundIcon?: boolean;
  }) => {
    const iconSizeMap: { [key: string]: number } = {
      xs: 16,
      sm: 22,
      md: 24,
      lg: 28,
      xl: 32,
    };

    const size = iconSizeMap[iconSize] || iconSize;

    return (
      <TokenIcon
        src={
          virtual
            ? VirtualTokenBase64
            : `https://storage.googleapis.com/eigenphi-token-icons/${chain}/${address}.png`
        }
        size={size}
        alt={name ?? shrink(address)}
        roundIcon={roundIcon}
      />
    );
  }
);

const LabelPopover = memo(
  ({
    target,
    opened,
    setOpened,
    labelList,
  }: {
    target?: React.ReactNode;
    opened: boolean;
    setOpened: (a: boolean) => void;
    labelList: Array<string>;
  }) => {
    return (
      <EpPopover
        opened={opened}
        onClose={() => setOpened(false)}
        position="right"
        // placement="center"
        withArrow
        transition="pop-top-left"
        target={target ?? null}
        // styles={{ body: { pointerEvents: "none" } }}
      >
        {labelList?.map((label) => (
          <Badge
            color="gray"
            variant="light"
            size="xs"
            sx={(theme) => ({
              marginLeft: "6px",
              fontFamily: '"Consolas", Menlo, monospace',
              fontWeight: 400,
              backgroundColor: "rgba(84,180,152,0.10)",
              color: "#54B498",
            })}
            key={`bdg-${label}`}
          >
            {label}
          </Badge>
        ))}
      </EpPopover>
    );
  }
);

const DisplayAddress = memo(function (props) {
  const {
    iconSize,
    token,
    showIcon,
    showName,
    fullAddress,
    roundIcon,
    ...restProps
  } = props as any;

  return token.name ? (
    <Group spacing={4} noWrap>
      {showIcon ? (
        <TokenIconWrapper
          address={token.address}
          chain={token.chain}
          name={token.name}
          virtual={token.virtual}
          iconSize={iconSize}
          roundIcon={roundIcon}
        />
      ) : null}
      {showName ? (
        <Text size="xs" {...restProps} title={token.name}>
          {token.name.length > 10 ? shrink(token.name, 6, 4) : token.name}
        </Text>
      ) : null}
    </Group>
  ) : (
    <Group spacing={0} noWrap>
      <Blockies
        seed={token.address}
        size={6}
        scale={3}
        className="blockie"
      ></Blockies>
      <TdText size={"xs"} {...restProps}>
        {fullAddress ? token.address : shrink(token.address)}
      </TdText>
    </Group>
  );
});

export const TokenDisplay = memo(function TokenDisplay({
  token,
  showIcon = true,
  showName = true,
  showOpener = false,
  iconSize = "xs",
  labelList,
  showMoreLabelStyle = "hover-label",
  bold = false,
  hideLink,
  browser,
  fullAddress,
  roundIcon,
}: {
  token: Token;
  showIcon?: boolean;
  showName?: boolean;
  showOpener?: boolean;
  iconSize?: MantineSize | number;
  labelList?: Array<string>;
  showMoreLabelStyle?: ShowMoreLabelStyle;
  bold?: boolean;
  hideLink?: boolean;
  browser?: boolean | { etherscanName: string; etherscanUrlPrefix: string };
  fullAddress?: boolean;
  roundIcon?: boolean;
}) {
  const [opened, setOpened] = useState(false);

  const renderToken = () => {
    const baseProps = {
      size: "sm" as MantineSize,
      sx: {
        whiteSpace: "nowrap",
        fontFamily: '"Consolas", Menlo, monospace',
        ...(bold ? { fontWeight: "bold" } : { fontWeight: "normal" }),
      } as CSSObject,
      iconSize,
      token,
      showIcon,
      showName,
      fullAddress,
      roundIcon,
    };

    const tokenProps = {
      ...baseProps,
      component: Link,
      to: `/${token.chain}/token/${token.address}`,
      onClick: (e: any) => {
        e.stopPropagation();
      },
    };

    let href = `https://${
      token.chain === "bsc" ? "bscscan.com" : "etherscan.io"
    }/token/${token.address}`;

    if (typeof browser === "object") {
      href = `${browser.etherscanUrlPrefix}/token/${token.address}`;
    }
    const browserProps = {
      ...baseProps,
      component: "a",
      rel: "noreferrer",
      target: "_blank",
      href,
      onClick: (e: any) => {
        e.stopPropagation();
      },
    };

    let props = tokenProps;

    if (hideLink) {
      props = baseProps as any;
    }

    if (browser) {
      props = browserProps as any;
    }

    if (token.virtual) {
      props = baseProps as any;
    }

    return (
      <>
        {showMoreLabelStyle === "hover-label" || !labelList?.length ? (
          <DisplayAddress {...props} />
        ) : (
          <LabelPopover
            target={
              <span
                onMouseEnter={() =>
                  setOpened((labelList as Array<string>).length > 1)
                }
                onMouseLeave={() => setOpened(false)}
              >
                <DisplayAddress {...props} />
              </span>
            }
            setOpened={setOpened}
            opened={opened}
            labelList={labelList}
          />
        )}
        {showOpener ? (
          <ThemeIcon color="gray" variant="light" size="xs">
            <ExternalLinkIcon />
          </ThemeIcon>
        ) : null}
      </>
    );
  };

  const wrap = (
    <Group spacing={0} noWrap>
      {renderToken()}
      {showMoreLabelStyle === "hover-label" && (labelList || []).length >= 1 ? (
        <LabelPopover
          setOpened={setOpened}
          opened={opened}
          labelList={labelList || []}
          target={
            <Badge
              color="gray"
              variant="light"
              size="xs"
              sx={(theme) => ({
                marginLeft: "6px",
                cursor: "pointer",
                fontFamily: '"Consolas", Menlo, monospace',
                fontWeight: 400,
                backgroundColor: "rgba(84,180,152,0.10)",
                color: "#54B498",
              })}
              onMouseEnter={() =>
                setOpened((labelList as Array<string>).length > 1)
              }
              onMouseLeave={() => setOpened(false)}
            >
              {(labelList as Array<string>)[0]}{" "}
              {(labelList as Array<string>).length > 1 ? "..." : ""}
            </Badge>
          }
        />
      ) : null}
    </Group>
  );

  let label = `${token.chain === "bsc" ? "BscScan" : "EtherScan"}`;
  if (typeof browser === "object") {
    label = `${browser.etherscanName}`;
  }
  return browser ? (
    <Tooltip
      label={`View on ${label}`}
      position={"top"}
      withArrow
      // styles={{
      //   body: {
      //     pointerEvents: "none",
      //     textAlign: "center",
      //   },
      // }}
    >
      {wrap}
    </Tooltip>
  ) : (
    wrap
  );
});
