import React from "react";
import { Center, Loader, Space, Text, Group } from "@mantine/core";
export const WidgetLoadingFallback = () => {
  return (
    <>
      <Center sx={{ marginTop: "100px" }}>
        <Loader className="mantine-loader" />
      </Center>
      <Space h="lg" />
      <Center sx={{ marginBottom: "100px" }}>
        <Text
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark" ? theme.white : theme.colors.dark[6],
            textAlign: "center",
          })}
        >
          FETCHING TRANSACTION DATA...
        </Text>
      </Center>
    </>
  );
};
export const PageLoadingFallback = () => {
  return (
    <Group sx={{ flexDirection: "column" }} align="center">
      <Center sx={{ marginTop: `${window.innerHeight / 3}px` }}>
        <Loader className="mantine-loader" />
      </Center>
      <Space h="lg" />
      <Center sx={{ marginBottom: "100px" }}>
        <Text
          sx={(theme) => ({
            color:
              theme.colorScheme === "dark" ? theme.white : theme.colors.dark[6],
            textAlign: "center",
          })}
        >
          FETCHING TRANSACTION DATA...
        </Text>
      </Center>
    </Group>
  );
};
