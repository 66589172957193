import React from "react";
import { Button } from "@mantine/core";
import normalImg from "./images/btn_google_signin_dark_normal_web@2x.png";
import focusImg from "./images/btn_google_signin_dark_focus_web@2x.png";
// import pressedImg from './images/btn_google_signin_dark_pressed_web@2x.png';

function GoogleLoginButton(props: any) {
  return (
    <Button
      {...props}
      variant="subtle"
      sx={{
        height: 46,
        width: 191,
        cursor: "pointer",
        backgroundImage: `url(${normalImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "192px 46px",

        "&:hover": {
          backgroundImage: `url(${focusImg})`,
        },
      }}
    />
  );
}

export default GoogleLoginButton;
