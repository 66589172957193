import { stringifyUrl } from "query-string";
import type {
  SandwichDistributionParams,
  SandwichDistributionItem,
  SandwichDistributionResponseJson,
} from "../type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchSandwichDistribution(
  options: SandwichDistributionParams
) {
  const url = "/api/v1/dataservice/sandwich/txProfitDistribution/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichDistributionResponseJson,
    {
      rows: Array<SandwichDistributionItem>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: data?.result?.data,
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
