import { SegmentedControl, Select, useMantineTheme } from "@mantine/core";
import React from "react";
import { useNavigate } from "./Link";
import { useIsSmallScreen } from "./Utils";
import queryString from "query-string";
import { TPeriod } from "../api/type";
import { useGlobalStatusContext } from "../GlobalStore";

export type PeriodSwitchType = "tabs" | "select";

export function PeriodSwitch({
  type,
  affix,
}: {
  type?: PeriodSwitchType;
  affix?: boolean;
}) {
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const qs: any = queryString.parse(window.location.search);
  const { period, setPeriod } = useGlobalStatusContext();
  const theme = useMantineTheme();

  type = type || (isSmallScreen ? "select" : "tabs");

  const data = [
    {
      label: "24H",
      value: "1",
    },
    {
      label: "7D",
      value: "7",
    },
    {
      label: "30D",
      value: "30",
    },
  ];

  const handleChange = (period: TPeriod) => {
    if (qs["period"]) {
      qs["period"] = period;
      navigate(`${window.location.pathname}?${queryString.stringify(qs)}`);
    } else {
      setPeriod(period);
    }
  };

  return type === "select" ? (
    <Select
      sx={{
        width: "60px",
      }}
      data={data}
      value={period}
      onChange={handleChange}
      size={isSmallScreen ? "xs" : "md"}
    ></Select>
  ) : (
    <SegmentedControl
      className="period-switch"
      data={data}
      size="sm"
      onChange={handleChange}
      value={period}
      transitionDuration={0}
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        boxShadow: affix
          ? theme.colorScheme === "dark"
            ? "0 4px 12px 0 rgba(0,0,0,0.50)"
            : "0 4px 12px 0 #E9ECEF"
          : "",
      })}
      styles={{
        root: {
          alignItems: "center",
          backgroundColor: affix
            ? theme.colorScheme === "dark"
              ? "#25262B !important"
              : "#FFFFFF !important"
            : "",
        },
        controlActive: {
          backgroundColor: "#44D7B6",
        },
        label: {
          padding: isSmallScreen ? "2px 3px !important" : "5px 10px !important",
        },
        labelActive: {
          padding: isSmallScreen ? "2px 3px !important" : "2px 10px !important",
          color: "#FFF !important",
        },
        active: {
          backgroundColor: "transparent",
          "box-shadow": "none",
        },
      }}
    />
  );
}
