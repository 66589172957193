import React, { memo } from "react";
import Highcharts from "highcharts";
import { ArbitrageBehaviorItem } from "../api/type";
import ProfitVolumeChart from "../Components/ProfitVolumeChart";
import { chartColors, columnColors } from "../Components/Highcharts";
import { useMantineTheme } from "@mantine/core";

const getArbitrageSeries = (
  data: Array<ArbitrageBehaviorItem>,
  bType: string
) => {
  let arbitrageSeries = [] as Array<Highcharts.SeriesColumnOptions>;

  data.forEach(({ items }, index) => {
    items.forEach(({ type, profit, tradingVolume }) => {
      const item = arbitrageSeries.find((item: any) => item.name === type);

      if (!item) {
        const newToken: Highcharts.SeriesColumnOptions = {
          name: type,
          type: "column",
          stack: 1,
          yAxis: 0,
          zIndex: 5,
          data: Array.from({ length: data.length }).map(() => 0),
          tooltip: {
            valuePrefix: "$",
            valueDecimals: 2,
          },
        };

        (newToken.data as Array<number>)[index] =
          bType === "profit"
            ? parseFloat(profit.toFixed(2))
            : parseFloat(tradingVolume.toFixed(2));

        arbitrageSeries.push(newToken);
      } else {
        (item.data as Array<number>)[index] =
          bType === "profit"
            ? parseFloat(profit.toFixed(2))
            : parseFloat(tradingVolume.toFixed(2));
      }
    });
  });

  type arbitrageTypes = "arbitrage" | "sandwich" | "liquidation";
  const colorTypeHash = {
    arbitrage: 0,
    sandwich: 1,
    liquidation: 2,
  };

  // 设置颜色/顺序/求和
  arbitrageSeries.forEach((item: Highcharts.SeriesColumnOptions) => {
    item.color =
      columnColors &&
      columnColors[colorTypeHash[item.name?.toLowerCase() as arbitrageTypes]];

    (item as any).order =
      colorTypeHash[item.name?.toLowerCase() as arbitrageTypes];

    (item as any).totalData = item.data?.reduce(
      (total: number, cur) => (total = total + Number(cur)),
      0
    );
  });

  // 排序
  arbitrageSeries.sort((p: any, n: any) => p.order - n.order);

  return arbitrageSeries;
};

const getData = (data: Array<ArbitrageBehaviorItem>) => {
  const countData = [] as any;
  const profitData = [] as any;
  const volumeData = [] as any;

  data.forEach(({ items }) => {
    const totalCount = items.reduce((total, item) => (total += item.count), 0);
    const totalProfit = items.reduce(
      (total, item) => (total += item.profit * 100),
      0
    );
    const totalVolume = items.reduce(
      (total, item) => (total += item.tradingVolume * 100000),
      0
    );

    countData.push(totalCount);
    profitData.push(parseFloat((totalProfit / 100).toFixed(2)));
    volumeData.push(parseFloat((totalVolume / 100000).toFixed(2)));
  });

  return {
    profitData,
    countData,
    volumeData,
  };
};

export default memo(function ArbitrageBehaviorChart({
  height,
  emptyHeight,
  isLoading,
  data,
  navExpand,
  period,
  showProfitCards,
  chain,
  summaries,
}: {
  height?: number;
  emptyHeight?: number;
  isLoading: boolean;
  data: Array<PerformanceMevType>;
  navExpand: boolean;
  period?: string;
  showProfitCards?: boolean;
  chain?: string;
  summaries?: Array<any>;
}) {
  const theme = useMantineTheme();

  // https://www.highcharts.com/forum/viewtopic.php?t=33062#p116095
  const options: Highcharts.Options = {
    chart: {
      height,
    },
  };

  const getSeries = (type: string, rows: Array<ArbitrageBehaviorItem>) => {
    const arbitrageSeries = getArbitrageSeries(rows || [], type);
    const { countData } = getData(rows || []);

    return [
      ...arbitrageSeries,
      {
        name: "Count",
        type: "spline",
        yAxis: 1,
        zIndex: 9,
        data: countData,
        tooltip: {
          valueSuffix: "",
        },
        color:
          theme.colorScheme === "dark"
            ? chartColors.lightPurple
            : chartColors.purple,
      },
    ];
  };

  return (
    <ProfitVolumeChart
      chain={chain}
      data={data}
      isLoading={isLoading}
      options={options}
      getSeries={getSeries}
      navExpand={navExpand}
      period={period!}
      emptyHeight={emptyHeight}
      showProfitCards={showProfitCards}
      summaries={summaries}
    />
  );
});
