import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationTopBorrowerParams,
  LiquidationTopBorrower,
  LiquidationTopBorrowerResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationBorrowers(
  options: LiquidationTopBorrowerParams
) {
  const url = "/api/v1/dataservice/liquidation/topLiquidatedBorrowers/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationTopBorrowerResponseJson,
    Array<LiquidationTopBorrower>
  >(fetch(endpoint), (data) => {
    return (data?.result?.data ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid,
    }));
  });
}
