import { stringifyUrl } from "query-string";
import type {
  TxLabelResult,
  TxLabelResultResponseJson,
  TxLabelParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchTxLabel(options: TxLabelParams) {
  const url = "/api/v1/dataservice/tx/labels/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<TxLabelResultResponseJson, TxLabelResult>(
    fetch(endpoint),
    (data) => {
      return data.result;
    }
  );
}
