import React, { memo } from "react";
import { Group, Text, useMantineTheme, Center, Divider } from "@mantine/core";
import Icon from "../Components/Icon";
import { useElementSize } from "@mantine/hooks";
import { useIsSmallScreen } from "../Components/Utils";
import { Logo } from "../Components/Logo";

export default function Footer() {
  const size = useElementSize();

  const theme = useMantineTheme();
  const dark = theme.colorScheme === "dark";
  const isSmallScreen = useIsSmallScreen();

  const paddingTop = 42;
  const paddingBottom = 28;

  return (
    <>
      <Center h={87} sx={{ background: "rgba(17,17,22,0.90)" }}>
        <Group>
          <Logo size={20} fontScale={16 / 25} />
          <Text size={14} sx={{ color: "#868E96" }}>
            Copyright © 2023 EIGENPHI
          </Text>
        </Group>
      </Center>
    </>
  );
}
