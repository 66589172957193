import React, { memo } from "react";
import { Group, Title, Affix, Anchor } from "@mantine/core";
import { shrink, useIsSmallScreen } from "../Components/Utils";
import { PeriodSwitch } from "../Components/PeriodSwitch";
import ShareBar, { ShareBarOptions } from "../Components/ShareBar";
import { ThText } from "../Components/EpTable";
import { Info } from "../Components/EpIcon";
import { CopyAddress } from "../Components/CopyAddress";
import { useWindowScroll } from "@mantine/hooks";
import { usePageLanguage } from "../Language/useLanguage";

const PageTitle = memo(function ({
  title,
  share,
  period,
  subTitle,
  searchBox,
  address,
  info,
  chainPrefix,
}: {
  title?: string;
  subTitle?: {
    type:
      | "token"
      | "contract"
      | "lp"
      | "tx"
      | "tokenAddress"
      | "liquidator"
      | "borrower";
    address?: string;
    tx?: string;
    symbol?: string;
    blockNumber?: number;
    txIndex?: number;
  };
  share?: ShareBarOptions;
  period?: boolean;
  searchBox?: {
    placeholder: string;
    template: string;
  };
  address?: string;
  info?: React.ReactNode | string;
  chainPrefix?: string;
}) {
  const isSmallScreen = useIsSmallScreen();
  const [scroll] = useWindowScroll();
  const pageLanguage = usePageLanguage();

  if (pageLanguage) {
    if (!title) {
      title = pageLanguage.title;
    }
    if (!info) {
      info = pageLanguage.desc;
    }
  }

  return (
    <Group
      position="apart"
      noWrap
      align="flex-start"
      spacing={isSmallScreen && !searchBox ? 0 : "md"}
      sx={{
        padding: `0 ${isSmallScreen ? "15px" : "0"}`,
        flexDirection: isSmallScreen ? "column" : "row",
      }}
    >
      <Group
        spacing={isSmallScreen ? 8 : "md"}
        sx={isSmallScreen ? { width: "100%", flexDirection: "column" } : {}}
      >
        <Group
          position="apart"
          sx={
            isSmallScreen
              ? { width: "100%", alignItems: "flex-start" }
              : { alignItems: "flex-start" }
          }
          noWrap
        >
          <Group
            noWrap
            sx={{ flexDirection: "column" }}
            align="flex-start"
            spacing={4}
          >
            <Group noWrap>
              <Title
                order={3}
                sx={(theme) => ({
                  color:
                    theme.colorScheme === "dark"
                      ? theme.white
                      : theme.colors.dark[6],
                })}
              >
                {title}
              </Title>
              {info && <Info content={info} />}
              {!isSmallScreen && share ? (
                <Group noWrap spacing={8} sx={{ paddingTop: 5 }}>
                  <ShareBar {...share} />
                </Group>
              ) : null}
            </Group>
            <Group noWrap>
              {subTitle ? (
                <Group noWrap>
                  <ThText sx={{ fontSize: 12 }}>
                    For{" "}
                    {
                      {
                        contract: subTitle.address
                          ? shrink(subTitle.address)
                          : "",
                        lp: subTitle.address ? shrink(subTitle.address) : "",
                        tx: subTitle.tx ? shrink(subTitle.tx) : "",
                        token: subTitle.symbol,
                        borrower: subTitle.address
                          ? shrink(subTitle.address)
                          : "",
                        liquidator: subTitle.address
                          ? shrink(subTitle.address)
                          : "",
                        tokenAddress: subTitle.address
                          ? shrink(subTitle.address)
                          : "",
                      }[subTitle.type]
                    }
                  </ThText>
                  {chainPrefix && subTitle.blockNumber ? (
                    <Anchor
                      sx={{ fontSize: 12 }}
                      href={`${chainPrefix}/block/${subTitle.blockNumber}`}
                      target="_blank"
                    >
                      Block:{subTitle.blockNumber}
                    </Anchor>
                  ) : null}
                  {subTitle.txIndex !== undefined ? (
                    <ThText sx={{ fontSize: 12 }}>
                      Position: {subTitle.txIndex}
                    </ThText>
                  ) : null}
                </Group>
              ) : null}
              {!isSmallScreen && address ? (
                <Group spacing={4} position="right">
                  <CopyAddress text={address} />
                  <ShareBar viewOnAddress={address} />
                </Group>
              ) : null}
              {isSmallScreen && share ? (
                <Group noWrap spacing={8}>
                  <ShareBar {...share} />
                </Group>
              ) : null}
            </Group>
          </Group>
          {isSmallScreen && period ? (
            scroll.y > 79 ? (
              <Affix position={{ top: 80, right: 15 }}>
                <PeriodSwitch affix type="tabs" />
              </Affix>
            ) : (
              <PeriodSwitch type="tabs" />
            )
          ) : null}
        </Group>
      </Group>
      {isSmallScreen ? null : period ? (
        scroll.y > 79 ? (
          <Affix position={{ top: 80, right: 24 }}>
            <PeriodSwitch affix type="tabs" />
          </Affix>
        ) : (
          <PeriodSwitch type="tabs" />
        )
      ) : null}
      {isSmallScreen && address ? (
        <Group spacing={4} position="right" sx={{ marginTop: 4 }}>
          <CopyAddress text={address} />
          <ShareBar viewOnAddress={address} />
        </Group>
      ) : null}
    </Group>
  );
});

export default PageTitle;
