import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  SandwichVictimsContractsParams,
  SandwichVictimsContract,
  SandwichVictimsContractsResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichVictimsContracts(
  options: SandwichVictimsContractsParams
) {
  const url = "/api/v2/dataservice/sandwich/victims/contract/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichVictimsContractsResponseJson,
    {
      rows: Array<SandwichVictimsContract>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: (data?.result?.data ?? []).slice(0, 10).map((item) => ({
        ...item,
        key: uuid(),
      })),
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
