import { stringifyUrl } from "query-string";
import type {
  LpArbitrageBehaviorParams,
  LpArbitrageBehaviorResult,
  LpArbitrageBehaviorResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLpArbitrageBehavior(
  options: LpArbitrageBehaviorParams
) {
  const url = "/api/v1/arbitrage/stat/lp/arbitrageBehavior/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LpArbitrageBehaviorResponseJson,
    LpArbitrageBehaviorResult
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
