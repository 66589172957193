import { stringifyUrl } from "query-string";
import type {
  SandwichLatestAttackItem,
  SandwichLatestAttackParams,
  SandwichLatestAttackResponseJson,
} from "../type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchSandwichLatestAttack(
  options: SandwichLatestAttackParams
) {
  const url = "/api/v1/dataservice/sandwich/latestSandwichTxs/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichLatestAttackResponseJson,
    {
      data: Array<SandwichLatestAttackItem>;
      totalCount: number;
      updateTimestamp: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      data: data?.result?.realTimeTransactionDtos,
      ...data?.result,
    };
  });
}
