import { stringifyUrl } from "query-string";
import {
  ProtocolTransferTrendItem,
  ProtocolTransferTrendParams,
  ProtocolTransferTrendResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchProtocolTransferDaily(
  params: ProtocolTransferTrendParams
) {
  const url = "/api/v1/protocol/stat/daily/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    ProtocolTransferTrendResponseJson,
    Array<ProtocolTransferTrendItem>
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
