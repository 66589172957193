import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationTopProtocolParams,
  LiquidationTopProtocol,
  LiquidationTopProtocolResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationProtocol(
  options: LiquidationTopProtocolParams
) {
  const url = "/api/v1/dataservice/liquidation/protocolLiquidations/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationTopProtocolResponseJson,
    Array<LiquidationTopProtocol>
  >(fetch(endpoint), (data) => {
    return (data?.result?.data ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
