import React from "react";
import { Group, Skeleton, Text, MantineTheme } from "@mantine/core";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { IoIosTimer } from "react-icons/io";
import EpPopover from "./EpPopover";

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const FromNow = React.memo(
  ({
    children,
    isLoading,
    updateTimestamp,
    updateBlockNumber,
    hideIcon,
    timeSx,
  }: {
    children?: React.ReactNode;
    isLoading: boolean;
    updateTimestamp: number;
    updateBlockNumber?: number;
    hideIcon?: boolean;
    timeSx?: (theme: MantineTheme) => any;
  }) => {
    if (isLoading) {
      return <Skeleton width="200px" height={24} />;
    }

    if (!children && !updateTimestamp && !isLoading) {
      return null;
    }

    return (
      <EpPopover
        target={
          <Text
            size="xs"
            sx={(theme: MantineTheme) => ({
              color:
                theme.colorScheme === "dark" ? "#B5B5C3" : theme.colors.dark[2],
              whiteSpace: "nowrap",
              ...timeSx?.(theme),
            })}
          >
            <Group noWrap spacing={6}>
              {hideIcon ? null : <IoIosTimer />}
              {dayjs((updateTimestamp ?? 0) * 1000).fromNow()}
            </Group>
          </Text>
        }
        position="bottom-start"
        withArrow
      >
        {children ? (
          children
        ) : (
          <>
            <Text
              size="xs"
              sx={(theme) => ({
                color:
                  theme.colorScheme === "dark"
                    ? theme.white
                    : theme.colors.dark[6],
              })}
            >
              Updated:{" "}
              {dayjs
                .utc((updateTimestamp ?? 0) * 1000)
                .format("MMM D, YYYY HH:mm:ss")}
              (UTC)
            </Text>
            {updateBlockNumber ? (
              <Text
                size="xs"
                sx={(theme) => ({
                  color:
                    theme.colorScheme === "dark"
                      ? theme.white
                      : theme.colors.dark[6],
                })}
              >
                Block height: {updateBlockNumber}
              </Text>
            ) : null}
          </>
        )}
      </EpPopover>
    );
  }
);
