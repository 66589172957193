import React, {
  memo,
  useState,
  useEffect,
  DetailedHTMLProps,
  HTMLAttributes,
  forwardRef,
} from "react";
import {
  Text,
  TextProps,
  useMantineTheme,
  CSSObject,
  Group,
  Box,
} from "@mantine/core";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useNavigate } from "./Link";
import merge from "deepmerge";

export const TdText = memo(
  forwardRef(function TdText(props: TextProps, ref: any) {
    const { sx, ...restProps } = props;

    return (
      <Text
        size="sm"
        ref={ref}
        sx={(theme) => ({
          display: "inline-block",
          fontFamily: '"Consolas", Menlo, monospace',
          whiteSpace: "nowrap",
          color:
            theme.colorScheme === "dark" ? "#B5B5C3" : theme.colors.dark[6],
          fontWeight: 400,
          ...(sx as any),
        })}
        {...restProps}
      />
    );
  })
);

export const Td = memo(function Td(props: any) {
  let { style, fixed, to, ...restProps } = props;
  const theme = useMantineTheme();
  const dark = theme.colorScheme === "dark";

  const navigate = useNavigate();
  if (to) {
    restProps = merge(restProps, {
      style: { cursor: "pointer" },
      onClick: (e: any) => {
        if (window.getSelection()?.isCollapsed) {
          if ((e.target as HTMLAnchorElement).nodeName !== "A") {
            navigate(to);
          }
        }
      },
    });
  }

  let commonStyle: CSSObject = {
    height: 40,
    borderBottomColor: dark ? theme.colors.dark[6] : theme.colors.gray[2],
  };

  if (fixed) {
    commonStyle = {
      ...commonStyle,
      position: "sticky",
      left: 0,
      backgroundColor: dark ? theme.colors.dark[7] : theme.white,
      zIndex: 1,
      ...fixed,
    };
  }

  return (
    <td
      style={{
        ...commonStyle,
        ...style,
      }}
      {...restProps}
    />
  );
});

export const ThText = memo(function ThText(props: TextProps) {
  const { sx, ...restProps } = props;
  const theme = useMantineTheme();

  return (
    <Text
      size="sm"
      sx={{
        fontFamily: '"Consolas", Menlo, monospace',
        color: theme.colors.dark[2],
        fontWeight: 400,
        whiteSpace: "nowrap",
        ...sx,
      }}
      {...restProps}
    />
  );
});

export const Th = memo(function Th(props: any) {
  const { style, fixed, ...restProps } = props;
  const theme = useMantineTheme();

  let commonStyle: CSSObject = {
    height: 40,
    border: "none",
  };

  if (fixed) {
    commonStyle = {
      ...commonStyle,
      position: "sticky",
      left: 0,
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      zIndex: 1,
      ...fixed,
    };
  }

  return (
    <th
      style={{
        ...commonStyle,
        ...style,
      }}
      {...restProps}
    />
  );
});

export const SortTh = memo(
  ({
    itemName,
    itemKey,
    activeKey,
    position = "right",
    direc,
    defaultDirec = "desc",
    onClick,
    ...tailProps
  }: {
    itemName: string | React.ReactElement;
    itemKey: string;
    activeKey: string;
    position?: "left" | "right";
    direc?: boolean;
    defaultDirec?: "desc" | "asc";
    onClick: (itemKey: string, nextDirec: "desc" | "asc") => void;
    [key: string]: any;
  }) => {
    const [bySortingDirec, setBySortingDirec] = useState("");
    const theme = useMantineTheme();

    const isCurrentTh = activeKey === itemKey;

    useEffect(() => {
      setBySortingDirec(isCurrentTh ? defaultDirec : "");
    }, [isCurrentTh, defaultDirec]);

    const handleClick = () => {
      let nextDirec = defaultDirec;

      if (isCurrentTh && !bySortingDirec) {
        nextDirec = defaultDirec;
      } else {
        nextDirec = bySortingDirec === "desc" ? "asc" : "desc";
      }

      setBySortingDirec(nextDirec);
      onClick(itemKey, nextDirec);
    };

    return (
      <Th {...tailProps}>
        <Group
          position={position}
          spacing={4}
          noWrap
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          <ThText
            style={{
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {itemName}
          </ThText>
          <Group sx={{ flexDirection: "column" }} spacing={0} noWrap>
            {direc ? (
              <Box sx={{ display: "inline", lineHeight: 1, marginBottom: -6 }}>
                <AiOutlineCaretUp
                  size={10}
                  color={
                    isCurrentTh && bySortingDirec === "asc"
                      ? theme.colorScheme === "dark"
                        ? theme.white
                        : theme.colors.dark[4]
                      : theme.colorScheme === "dark"
                      ? theme.colors.dark[2]
                      : theme.colors.dark[0]
                  }
                />
              </Box>
            ) : null}
            <Box sx={{ display: "inline", lineHeight: 1 }}>
              <AiOutlineCaretDown
                size={10}
                color={
                  isCurrentTh && bySortingDirec === "desc"
                    ? theme.colorScheme === "dark"
                      ? theme.white
                      : theme.colors.dark[4]
                    : theme.colorScheme === "dark"
                    ? theme.colors.dark[2]
                    : theme.colors.dark[0]
                }
              />
            </Box>
          </Group>
        </Group>
      </Th>
    );
  }
);

export const Tr = memo(function Tr(props: any) {
  let { to, ...restProps } = props;
  const navigate = useNavigate();
  if (to) {
    restProps = merge(restProps, {
      style: { cursor: "pointer" },
      onClick: (e: any) => {
        if (window.getSelection()?.isCollapsed) {
          if ((e.target as HTMLAnchorElement).nodeName !== "A") {
            if (to.startsWith("http")) {
              var tmpA = document.createElement("a");
              tmpA.href = to;
              tmpA.target = "_blank";
              tmpA.click();
            } else {
              navigate(to);
            }
          }
        }
      },
    });
  }

  return <tr {...restProps}></tr>;
});
