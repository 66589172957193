// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAvFmPvlucS0n9Itaw3h9taenFHM3u_-Js",
  authDomain: "arbitragescan.firebaseapp.com",
  projectId: "arbitragescan",
  storageBucket: "arbitragescan.appspot.com",
  messagingSenderId: "277078002419",
  appId: "1:277078002419:web:95b4adedbca3c884074586",
  measurementId: "G-5ZKJHYB829",
};

export const firebaseEnv: "alpha" | "product" | "test" = "alpha";
