import { stringifyUrl } from "query-string";
import type {
  LiquidationBorrowerOverview,
  LiquidationBorrowerOverviewResponseJson,
  LiquidationBorrowerOverviewParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationBorrowerOverview(
  options: LiquidationBorrowerOverviewParams
) {
  const url = "/api/v1/dataservice/liquidation/borrowerOverview/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationBorrowerOverviewResponseJson,
    LiquidationBorrowerOverview
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
