import { useContext } from "react";
import { EpPageContext } from "../Components/EpPage";
import language from "./en-us.json";

export function usePageLanguage() {
  const pageCtx = useContext(EpPageContext);
  const { pageId } = pageCtx.page();

  return (language as any)[pageId!];
}

export function useWidgetLanguage(id: string) {
  const pageLanguage = usePageLanguage();

  return pageLanguage ? pageLanguage[id!] : undefined;
}

export function useCommonLanguage(id: string) {
  return (language as any)["Common"][id];
}
