import React, { memo, useEffect, useState } from "react";
import { stringifyUrl } from "query-string";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  doc,
  getDoc,
  OrderByDirection,
  WhereFilterOp,
  startAfter,
  endBefore,
  DocumentData,
  limitToLast,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { firebaseEnv } from "../firebase-config";
import { getAnalytics, logEvent } from "firebase/analytics";
import fetchJsonp from "fetch-jsonp";

export interface WhereItem {
  fieldName: string;
  opt: WhereFilterOp;
  value: any;
}
interface OrderByItem {
  fieldName: string;
  direction: OrderByDirection;
}
export function useFireStoreQuery<DataType>({
  path,
  where: qWhere,
  limit: qLimit,
  orderBy: qOrderBy,
  enable = true,

  refetch = true,
  refetchDelay = 1000,
  refetchLimit = 1,
}: {
  path: string;
  where?: WhereItem[];
  limit?: number;
  orderBy?: OrderByItem | OrderByItem[];
  enable?: boolean;
  refetch?: boolean;
  refetchDelay?: number;
  refetchLimit?: number;
}) {
  path = `/${firebaseEnv}` + path;

  const analytics = getAnalytics();
  const isCollection = path.split("/").length % 2 === 0;

  const [refetchCount, setRefetchCount] = useState(refetchLimit);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [lastItemAtLatestQuery, setLastItemAtLatestQuery] = useState<
    DocumentData | undefined
  >(undefined);
  const [firstItemAtLatestQuery, setFirstItemAtLatestQuery] = useState<
    DocumentData | undefined
  >(undefined);

  const [data, setData] = useState<DataType>();
  const [firstDoc, setFirstDoc] = useState<DocumentData>();
  const [lastDoc, setLastDoc] = useState<DocumentData>();

  const [isLastPage, setIsLastPage] = useState(false);

  const [noMoreData, setNoMoreData] = useState(false);

  const args: any = [];
  const whereConditions: any = [];
  qWhere?.forEach((w) => {
    if (w.value) {
      args.push(where(w.fieldName, w.opt, w.value));
      whereConditions.push(where(w.fieldName, w.opt, w.value));
    }
  });

  const reverseOrderConditions: any = [];
  if (qOrderBy) {
    if (qOrderBy instanceof Array) {
      qOrderBy.map((o) => {
        args.push(orderBy(o.fieldName, o.direction));
        reverseOrderConditions.push(
          orderBy(o.fieldName, o.direction === "asc" ? "desc" : "asc")
        );
      });
    } else {
      args.push(orderBy(qOrderBy.fieldName, qOrderBy.direction));
      reverseOrderConditions.push(
        orderBy(
          qOrderBy.fieldName,
          qOrderBy.direction === "asc" ? "desc" : "asc"
        )
      );
    }
  }
  if (lastItemAtLatestQuery) {
    args.push(startAfter(lastItemAtLatestQuery));
  }
  if (firstItemAtLatestQuery) {
    args.push(endBefore(firstItemAtLatestQuery));
    if (qLimit) {
      args.push(limitToLast(qLimit));
    }
  } else {
    if (qLimit) {
      args.push(limit(qLimit));
    }
  }
  useEffect(() => {
    resetPages();
  }, [path]);

  const queryMethod = () => {
    setIsLoading(true);
    (async function () {
      if (path.includes("{{latest_doc}}")) {
        const pathQuery = query(
          collection(
            getFirestore(),
            path.substring(0, path.indexOf("{{latest_doc}}"))
          ),
          orderBy("dummy", "desc"),
          limit(1)
        );

        const pathSnapshot = await getDocs(pathQuery);

        const latestDoc = pathSnapshot.docs.pop();

        if (latestDoc) {
          path = path.replace("{{latest_doc}}", latestDoc.id);
        }
      }

      if (isCollection) {
        const aQuery = query(collection(getFirestore(), path), ...args);

        logEvent(analytics, "firestore-query-collection", {
          path,
          args: JSON.stringify(args),
        });
        const snapshot = await getDocs(aQuery);
        const list = snapshot.docs.map((doc) => {
          const message = doc.data();
          message.key = uuid();
          message.docid = doc.id;
          return message;
        });

        if (list.length === 0) {
          if (isLoadingMoreData) {
            setNoMoreData(true);
          } else {
            try {
              const res = await fetchJsonp(
                stringifyUrl({
                  url: `https://on-demand-bigquery-tepu46la6a-uw.a.run.app/`,
                  query: { path },
                })
              );

              if (refetch) {
                console.log("report empty collection:", path, await res.json());
                setTimeout(() => {
                  if (refetchCount > 0) {
                    console.log(`refetch: ${path}, countdown: ${refetchCount}`);
                    setRefetchCount((cur) => {
                      return cur - 1;
                    });
                  }
                }, refetchDelay);
              }
            } catch (ex) {
              console.log(ex);
            }
          }
        }
        console.log(path, args, list);
        if (list.length) {
          setFirstDoc(snapshot.docs[0]);
          setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
          setData((prevList) => {
            if (prevList && isLoadingMoreData) {
              return (prevList as unknown as Array<any>)?.concat(
                list
              ) as unknown as DataType;
            } else {
              return list as unknown as DataType;
            }
          });
        }

        setIsLoading(false);
        setIsLoadingMoreData(false);

        //what's the end of docs? reverse all order conditions on a new query.
        const endDocQuery = query(
          collection(getFirestore(), path),
          ...whereConditions,
          ...reverseOrderConditions,
          limit(1)
        );
        const endDocSnapshot = await getDocs(endDocQuery);
        /*
        console.log(
          path,
          snapshot.docs[snapshot.docs.length - 1]?.id,
          endDocSnapshot.docs[0]?.id
        );
        */
        setIsLastPage(
          snapshot.docs[snapshot.docs.length - 1]?.id ===
            endDocSnapshot.docs[0]?.id
        );
      } else {
        logEvent(analytics, "firestore-query-doc", {
          path,
        });
        const snapshot = await getDoc(doc(getFirestore(), path));
        const message = snapshot.data();
        if (message) {
          message.key = uuid();
          message.docid = snapshot.id;
        }

        if (!snapshot.exists()) {
          try {
            const res = await fetchJsonp(
              stringifyUrl({
                url: `https://on-demand-bigquery-tepu46la6a-uw.a.run.app/`,
                query: { path },
              })
            );

            console.log("report empty doc:", path, await res.json());

            if (refetch) {
              setTimeout(() => {
                if (refetchCount > 0) {
                  console.log(`refetch: ${path}, countdown: ${refetchCount}`);
                  setRefetchCount((cur) => {
                    return cur - 1;
                  });
                }
              }, refetchDelay);
            }
          } catch (ex) {
            console.log(ex);
          }
        }
        console.log(path, message);

        setData(message as unknown as DataType);
        setIsLoading(false);
        setIsLoadingMoreData(false);
      }
    })();
  };

  useEffect(() => {
    if (enable) {
      queryMethod();
    }
  }, [path, JSON.stringify(args), enable, refetchCount]);

  function loadMoreData() {
    if (lastDoc !== lastItemAtLatestQuery) {
      setIsLoadingMoreData(true);
    }
    if (qOrderBy) {
      setLastItemAtLatestQuery(lastDoc);
    }
  }
  function loadPrevPage() {
    if (qOrderBy) {
      setLastItemAtLatestQuery(undefined);
      setFirstItemAtLatestQuery(firstDoc);
    }
    //setIsLoading(true);
  }
  function loadNextPage() {
    if (qOrderBy) {
      setFirstItemAtLatestQuery(undefined);
      setLastItemAtLatestQuery(lastDoc);
    }
    //setIsLoading(true);
  }
  function resetPages() {
    setFirstItemAtLatestQuery(undefined);
    setLastItemAtLatestQuery(undefined);
    setData(undefined);
    setNoMoreData(false);
  }
  return {
    isLoading,
    isLoadingMoreData,
    data,
    loadMoreData,
    loadNextPage,
    loadPrevPage,
    isLastPage,
    resetPages,
    noMoreData,
  };
}
