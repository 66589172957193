import { stringifyUrl } from "query-string";
import type {
  TokenTransactionParams,
  TransactionLeaderBoardItem,
  TokenTransactionResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchTokenTransaction(params: TokenTransactionParams) {
  const url = "/api/v1/arbitrage/stat/token/txProfitLeaderboard/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    TokenTransactionResponseJson,
    TransactionLeaderBoardItem
  >(fetch(endpoint), (data) => {
    return data.result.data;
  });
}
