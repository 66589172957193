import React, { useRef, useState } from "react";
import {
  Group,
  Box,
  Skeleton,
  Text,
  Paper,
  CSSObject,
  ActionIcon,
  Modal,
  Anchor,
} from "@mantine/core";
import { BiFullscreen } from "react-icons/bi";
import { Info } from "../Components/EpIcon";
import { Link } from "./Link";
import { useWidgetLanguage } from "../Language/useLanguage";

export default function Widget({
  title,
  customTitle,
  titleLink,
  info,
  actions,
  more,
  hideFooter,
  children,
  id,
  contentSx,
  wrapSx,
  fullScreen,
  footer,
}: {
  title?: React.ReactNode;
  customTitle?: React.ReactNode;
  titleLink?: string;
  info?: React.ReactNode;
  actions?: React.ReactNode;
  more?: React.ReactNode;
  hideFooter?: boolean;
  children?: React.ReactNode;
  id?: string;
  contentSx?: CSSObject;
  wrapSx?: CSSObject;
  fullScreen?: boolean;
  footer?: React.ReactNode;
}) {
  const widget = useRef<HTMLDivElement>(null);

  const widgetLanguage = useWidgetLanguage(id!);

  if (widgetLanguage) {
    if (!title) {
      title = widgetLanguage.title;
    }
    if (!info) {
      info = widgetLanguage.desc;
    }
  }

  function TitleBar() {
    const [fullScreenModal, setFullScreenModal] = useState(false);

    return (
      <>
        {fullScreen ? (
          <Modal
            size="100%"
            overflow="inside"
            opened={fullScreenModal}
            onClose={() => setFullScreenModal(false)}
            title={title}
            transitionDuration={0} // 隐藏动画，避免highcharts绘制错误
          >
            {children}
          </Modal>
        ) : null}
        <Box
          sx={(theme) => ({
            padding: "10px 10px 10px 20px",
            borderBottom:
              theme.colorScheme === "dark"
                ? `1px solid ${theme.colors.dark[6]}`
                : `1px solid ${theme.colors.gray[2]}`,
            position: "relative",
          })}
        >
          <Group position="apart" noWrap>
            <Group spacing={10} sx={() => ({ flex: 1 })} noWrap>
              {titleLink ? (
                <Anchor
                  component={Link}
                  size="sm"
                  to={titleLink}
                  sx={(theme) => ({
                    fontWeight: "bold",
                    color:
                      theme.colorScheme === "dark"
                        ? theme.white
                        : theme.colors.dark[6],
                  })}
                  lineClamp={1}
                >
                  {title}
                </Anchor>
              ) : (
                <Text
                  size="sm"
                  sx={(theme) => ({
                    fontWeight: "bold",
                    color:
                      theme.colorScheme === "dark"
                        ? theme.white
                        : theme.colors.dark[6],
                  })}
                  lineClamp={1}
                >
                  {title}
                </Text>
              )}
              {info && <Info content={info} />}
            </Group>
            <Group spacing={10} noWrap>
              {actions ? actions : null}
              {more ? <Text size="xs">{more}</Text> : null}
              {fullScreen ? (
                <ActionIcon
                  sx={(theme) => ({
                    backgroundColor: "transparent",
                    color: "#44957D",
                    "&:hover": {
                      color: "#44957D",
                      backgroundColor: "rgba(68,215,182,0.10)",
                    },
                  })}
                  onClick={() => setFullScreenModal(true)}
                >
                  <BiFullscreen size={16} />
                </ActionIcon>
              ) : null}
            </Group>
          </Group>
        </Box>
      </>
    );
  }

  return (
    <>
      <Paper
        className={`widget-${id?.replace(/\s/g, "-").toLowerCase()}`}
        radius={8}
        sx={(theme) => ({
          marginTop: "20px",
          boxShadow:
            theme.colorScheme === "light" ? "0px 4px 12px 0px #e9ecef" : "none",
          ...wrapSx,
        })}
        ref={widget}
      >
        {title ? <TitleBar /> : customTitle}
        <Box
          component="section"
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            maxWidth: "100vw",
            height: "calc(100% - 80px)",
            ...contentSx,
          }}
        >
          {children ? children : <Skeleton height={180} width="100%" />}
        </Box>
        {!hideFooter && (
          <Box
            sx={(theme) => ({
              padding: "16px 20px 19px",
              borderTop:
                theme.colorScheme === "dark"
                  ? `1px solid ${theme.colors.dark[6]}`
                  : `1px solid ${theme.colors.gray[2]}`,
            })}
          />
        )}
        {footer}
      </Paper>
    </>
  );
}
