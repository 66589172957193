import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  onSnapshot,
  limit,
  orderBy,
  getDoc,
  where,
  doc,
} from "firebase/firestore";
import { ArbitrageTransactionMessage } from "../Transaction/api/type";
import { firebaseEnv } from "../firebase-config";
import { useGlobalStatusContext } from "../GlobalStore";
import { useDocumentVisibility } from "@mantine/hooks";

export function useLiveStream({ contract }: { contract?: string }) {
  const { chain } = useGlobalStatusContext();
  const [rows, setRows] = useState<ArbitrageTransactionMessage[]>([]);
  const documentState = useDocumentVisibility();
  const wheres = [];
  if (contract) {
    wheres.push(where("contract", "==", contract));
  }

  const aQuery = query(
    collection(getFirestore(), `/${firebaseEnv}/${chain}/live-stream`),
    orderBy("blockNumber", "desc"),
    orderBy("blockPosition", "desc"),
    ...wheres,
    limit(50)
  );

  useEffect(() => {
    setRows([]);

    // tab is not active 则不获取实时流
    if (documentState === "hidden") {
      return;
    }

    const unsubscribe = onSnapshot(aQuery, function (snapshot) {
      let newMessages: ArbitrageTransactionMessage[] = [];

      Promise.all(
        snapshot.docChanges().map(async (message) => {
          if (message.type === "added") {
            const newMessage =
              message.doc.data() as ArbitrageTransactionMessage;

            /*
            const snapshot = await getDoc(
              doc(
                getFirestore(),
                `/${firebaseEnv}/${chain}/labels/${newMessage.contract}`
              )
            );
            const label = snapshot.data();

            const { data: label } = useFireStoreQuery<any>({
              path: `/${chain}/labels/${newMessage.contract}`,
            });

            newMessage.contractLabels = label?.tags?.labels;
            */

            newMessages.push(newMessage);
          }
        })
      ).finally(() => {
        if (newMessages.length) {
          setRows((prevRows) => {
            return newMessages
              .concat(prevRows)
              .slice(0, 50)
              .sort((a, b) => {
                if (a.blockNumber && b.blockNumber) {
                  return b.blockNumber - a.blockNumber;
                } else {
                  return 0;
                }
              });
          });
        }
      });
    });

    return () => unsubscribe();
  }, [chain, contract, documentState]);

  return rows;
}
