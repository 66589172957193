import constate from "constate";
import { User } from "firebase/auth";
import { useState } from "react";
import { TPeriod } from "./api/type";
import {
  useIsSmallerThanLG,
  useIsSmallerThanMD,
  useIsSmallScreen,
} from "./Components/Utils";

export function useGlobalStatus() {
  const isSmallScreen = useIsSmallScreen();
  const isSmallThanMD = useIsSmallerThanMD();
  const isSmallThanLG = useIsSmallerThanLG();
  const [chain, setChain] = useState("ethereum");
  const [period, setPeriod] = useState<TPeriod>(
    process.env.REACT_APP_NAME === "arbitragescan" ? "7" : "1"
  );
  const [navExpand, setNavExpand] = useState<boolean | undefined>(undefined);
  const [navVisibleOnSmallScreen, setNavVisibleOnSmallScreen] =
    useState<boolean>(false);
  const [hotLpActiveTab, setHotLPActiveTab] = useState<"table" | "chart">(
    "chart"
  );
  const [hotTokenActiveTab, setHotTokenActiveTab] = useState<"table" | "chart">(
    "chart"
  );
  const [hotLpSortBy, setHotLpSortBy] = useState<"volume" | "vroc">("volume");
  const [hotTokenSortBy, setHotTokenSortBy] = useState<"volume" | "vroc">(
    "volume"
  );

  const [
    latestUpdateLiveStreamTxTimestamp,
    setLatestUpdateLiveStreamTxTimestamp,
  ] = useState<number | undefined>(undefined);

  const [showNetAssetFlow, setShowNetAssetFlow] = useState(false);
  const [hideInter, setHideInter] = useState(false);
  const [allowDifferent, setAllowDifferent] = useState(false);
  const [disableReverseDebt, setDisableReverseDebt] = useState(false);
  const [rankdir, setRankDir] = useState(isSmallScreen ? "TB" : "LR");
  const [user, setUser] = useState<User | null>(null);

  return {
    period,
    setPeriod,
    chain,
    setChain,
    navExpand,
    setNavExpand,
    navVisibleOnSmallScreen,
    setNavVisibleOnSmallScreen,
    hotLpActiveTab,
    setHotLPActiveTab,
    hotTokenActiveTab,
    setHotTokenActiveTab,
    hotLpSortBy,
    setHotLpSortBy,
    hotTokenSortBy,
    setHotTokenSortBy,
    latestUpdateLiveStreamTxTimestamp,
    setLatestUpdateLiveStreamTxTimestamp,
    showNetAssetFlow,
    setShowNetAssetFlow,
    hideInter,
    setHideInter,
    allowDifferent,
    setAllowDifferent,
    disableReverseDebt,
    setDisableReverseDebt,
    rankdir,
    setRankDir,
    isSmallThanMD,
    isSmallThanLG,
    isSmallScreen,
    user,
    setUser,
  };
}

export const [GlobalStatusProvider, useGlobalStatusContext] =
  constate(useGlobalStatus);
