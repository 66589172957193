import { stringifyUrl } from "query-string";
import type {
  HotTokenItem,
  HotAssetsParams,
  HotTokenResponseJson,
  HotTokenV2ResponseJson,
  ApiWithPagination,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchHotToken(params: HotAssetsParams) {
  const url = "/api/v1/arbitrage/stat/token/hotToken/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    HotTokenResponseJson,
    ApiWithPagination<Array<HotTokenItem>>
  >(fetch(endpoint), (data) => {
    return {
      result: data.result,
      nextPage: (params.pageNum ?? 0) + 1,
      pageSize: params.pageSize ?? 15,
    };
  });
}

export async function fetchHotTokenV2(params: HotAssetsParams) {
  const url = "/api/v2/arbitrage/stat/token/hotToken/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    HotTokenV2ResponseJson,
    {
      rows: ApiWithPagination<Array<HotTokenItem>>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: {
        result: data.result.data,
        nextPage: (params.pageNum ?? 0) + 1,
        pageSize: params.pageSize ?? 15,
      },
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
