import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  GenericParams,
  LeaderBoardItem,
  LeaderBoardResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchLeaderboard(options: GenericParams) {
  const url = "/api/v1/arbitrage/stat/leaderboard/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LeaderBoardResponseJson<typeof options.period>,
    Array<LeaderBoardItem>
  >(fetch(endpoint), (data) => {
    return (data?.result?.data ?? []).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
