import React from "react";
import { Group, Tooltip } from "@mantine/core";
import { TdText } from "./EpTable";

export const AmountDisplay = React.memo(function AmountDisplay({
  value,
  digits = 2,
  label,
  prefix = "",
  suffix = "",
  icon,
  style,
  brackets = false,
  hideTip,
  className,
}: {
  value?: number;
  digits?: number;
  label?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  icon?: React.ReactNode;
  style?: any;
  brackets?: boolean;
  hideTip?: boolean;
  className?: string;
}) {
  if (typeof value === "undefined") {
    return null;
  }

  const renderAmount = ({
    value,
    prefix,
    suffix,
  }: {
    value: number;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
  }) => {
    const isNegative = value < 0;

    const minLimit = 1 / Math.pow(10, digits);
    if (Math.abs(value) < minLimit && Math.abs(value) > 0) {
      return `<${isNegative ? "-" : ""}${prefix}${minLimit}${suffix}`;
    }

    if (value || value === 0) {
      return (
        <>
          {isNegative ? "-" : ""}
          {prefix}
          {Math.abs(value).toLocaleString("zh-CN", {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
          })}
          {suffix}
        </>
      );
    }

    return null;
  };

  const wrap = (
    <TdText style={style} className={className}>
      {brackets ? "(" : null}
      {icon}
      {renderAmount({ value, prefix, suffix })}
      {brackets ? ")" : null}
    </TdText>
  );

  if (hideTip) return wrap;

  return <Tooltip label={label || value}>{wrap}</Tooltip>;
});
