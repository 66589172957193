import { Container, MantineProvider } from "@mantine/core";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "swagger-ui-themes/themes/3.x/theme-flattop.css";
import { useIsSmallScreen } from "../../Components/Utils";

export default function ApiDocumentPage() {
  const isSmallScreen = useIsSmallScreen();
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: "light",
      }}
    >
      <Container
        id="arbitrage-container"
        className="arbitrage-container"
        fluid
        sx={{
          background: "#fff",
          paddingTop: 44,
          paddingBottom: 24,
          minHeight: window.innerHeight - 230,
        }}
        px={isSmallScreen ? 0 : "150px"}
      >
        <SwaggerUI url="/eigenapi/apis/openapi.yaml" />
      </Container>
    </MantineProvider>
  );
}
