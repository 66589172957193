import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LPTransactionItem,
  LPTransactionLatestResponseJson,
  LPTransactionLeaderBoardResponseJson,
  LPTransactionParams,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchLPTransaction(
  params: LPTransactionParams
) {
  const url = params.type === 'latest' ? "/api/v1/arbitrage/lp/tx/" : '/api/v1/arbitrage/stat/lp/txProfitLeaderboard/';
  const endpoint = stringifyUrl({ url, query: params });

  if(params.type === 'latest'){
    return await normalizeResponse<
      LPTransactionLatestResponseJson,
      Array<LPTransactionItem>
    >(fetch(endpoint), (data) => {
      return (data?.result ?? []).slice(0, 10).map((item) => ({
        ...item,
        key: uuid(),
      }));
    });
  } else {
    return await normalizeResponse<
      LPTransactionLeaderBoardResponseJson<typeof params.period>,
      Array<LPTransactionItem>
    >(fetch(endpoint), (data) => {
      return (data?.result?.data ?? []).slice(0, 10).map((item) => ({
        ...item,
        key: uuid(),
      }));
    });

  }
}
