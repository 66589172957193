// url: "/api/v1/dataservice/arbitrage_transaction_flow/",

import { stringifyUrl } from "query-string";
import {
  TransactionFlowParams,
  TransactionFlowResponseJson,
  TransactionFlow,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchTransactionFlow(params: TransactionFlowParams) {
  const url = "/api/v1/dataservice/arbitrage_transaction_flow/";

  const endpoint = stringifyUrl({ url, query: params });

  const flow = await normalizeResponse<
    TransactionFlowResponseJson,
    TransactionFlow
  >(fetch(endpoint), (data) => {
    return data.result;
  });

  return flow;
}
