import React from "react";
import { Button, ButtonProps } from "@mantine/core";

function GrayButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      sx={{
        height: 42,
        fontSize: 16,
        background: "rgba(109,114,120,0.50)",
        "&:hover": {
          background: "rgba(109,114,120,0.50)",
        },
        ...props.sx,
      }}
      color="gray"
    ></Button>
  );
}

export default GrayButton;
