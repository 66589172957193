import { stringifyUrl } from "query-string";
import { normalizeResponse } from "../../api/_helpers";

const apiHostList = {
  Etherscan: "https://rpc.ankr.com/eth/",
  BscScan: "https://bsc-dataseed3.binance.org/",
  Arbiscan: "https://rpc.ankr.com/arbitrum/",
  FTMScan: "https://rpc.ftm.tools//",
  Optimism: "https://rpc.ankr.com/optimism/",
  Polygonscan: "https://rpc.ankr.com/polygon/",
  Cronos: "https://evm-cronos.crypto.org/",
  SNOWTRACE: "https://rpc.ankr.com/avalanche/",
} as any;

export async function fetchBlockByNumber(params: {
  chain: string;
  blockNumber: number;
  transactionsFull?: boolean;
}) {
  const url = apiHostList[params.chain] || "";
  const endpoint = stringifyUrl({ url });

  const body = {
    jsonrpc: "2.0",
    method: "eth_getBlockByNumber",
    params: [
      `0x${params?.blockNumber?.toString(16)}`,
      Boolean(params?.transactionsFull),
    ],
    id: 1,
  };

  return await normalizeResponse<any, any>(
    fetch(endpoint, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }),
    (data: any) => {
      return data.result;
    }
  );
}
