import React, { memo, useState } from "react";
import { isMinerText, shrink, toChecksumAddress } from "./Utils";
import {
  Badge,
  Box,
  Group,
  MantineSize,
  Tooltip,
  Image,
  Space,
  Anchor,
  useMantineTheme,
} from "@mantine/core";
import { Link } from "./Link";
import Blockies from "react-blockies";
import { TdText } from "./EpTable";
import { Bot } from "./EpIcon";
import { useIsSmallScreen } from "./Utils";
import { Protocol } from "../api/type";
import queryString from "query-string";
import EpPopover from "./EpPopover";

export type ShowMoreLabelStyle = "hover-label" | "hover-address";
export const ContractDisplay = memo(function ContractDisplay({
  address,
  chain,
  contract,
  borrower,
  external,
  lp,
  token,
  attacker,
  victim,
  middleAttacker,
  liquidator,
  liquidated,
  lendingUser,
  virtual,
  tag,
  prefix,
  fullAddress,
  labelList,
  protocol,
  showAllLabels = false,
  showMoreLabelStyle = "hover-label",
  name,
  showIcon = true,
  query,
  lpIcon,
  blockNumber,
}: {
  address: string;
  chain: string;
  contract?: boolean;
  borrower?: boolean;
  external?: boolean | { etherscanName: string; etherscanUrlPrefix: string };
  lp?: boolean;
  token?: boolean;
  attacker?: boolean;
  victim?: boolean;
  middleAttacker?: boolean;
  liquidator?: boolean;
  liquidated?: boolean;
  lendingUser?: boolean;
  virtual?: boolean;
  tag?: string;
  prefix?: string;
  fullAddress?: boolean;
  labelList?: Array<string>;
  protocol?: Protocol;
  showAllLabels?: boolean;
  showMoreLabelStyle?: ShowMoreLabelStyle;
  name?: string;
  showIcon?: boolean;
  query?: { [key: string]: any };
  lpIcon?: string;
  blockNumber?: number;
}) {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const isSmallScreen = useIsSmallScreen();

  if (!address) {
    return null;
  }

  address = toChecksumAddress(address);

  let lpName = "";
  if (lpIcon) {
    lpName = labelList ? labelList[0] : "";
  }

  const baseProps = {
    size: "sm" as MantineSize,
  };

  let href =
    `https://${chain === "bsc" ? "bscscan.com" : "etherscan.io"}` +
    `/address/${address}`;

  if (typeof external === "object") {
    href = `${external.etherscanUrlPrefix}/address/${address}`;
  }
  const externalProps = {
    ...baseProps,
    component: "a",
    rel: "noreferrer",
    target: "_blank",
    href,
  };

  const contractProps = {
    component: Link,
    to: `/${chain}/contract/${address}`,
  };

  const lpProps = {
    component: Link,
    to: `/${chain}/lp/${address}`,
  };

  const tokenProps = {
    component: Link,
    to: `/${chain}/token/${address}`,
  };

  const attackerProps = {
    component: Link,
    to: `/${chain}/sandwich/attacker/${address}`,
  };

  const victimProps = {
    component: Link,
    to: `/${chain}/sandwich/victim/${address}${
      query ? `?${queryString.stringify(query)}` : ""
    }`,
  };

  const liquidatorProps = {
    component: Link,
    to: `/${chain}/liquidation/liquidator/${address}`,
  };

  const liquidatedProps = {
    component: Link,
    to: `/${chain}/liquidation/borrower/${address}${
      query ? `?${queryString.stringify(query)}` : ""
    }`,
  };

  const lendingUserProps = {
    component: Link,
    to: `/${chain}/lending/user/${address}${
      query ? `?${queryString.stringify(query)}` : ""
    }`,
  };

  let props = baseProps;

  if (lp) {
    Object.assign(props, lpProps);
  }
  if (external) {
    Object.assign(props, externalProps);
  }
  if (contract) {
    Object.assign(props, contractProps);
  }
  if (token) {
    Object.assign(props, tokenProps);
  }
  if (attacker) {
    Object.assign(props, attackerProps);
  }
  if (victim) {
    Object.assign(props, victimProps);
  }
  if (middleAttacker) {
    Object.assign(props, attackerProps);
  }
  if (liquidator) {
    Object.assign(props, liquidatorProps);
  }
  if (liquidated) {
    Object.assign(props, liquidatedProps);
  }
  if (lendingUser) {
    Object.assign(props, lendingUserProps);
  }

  if (borrower) {
    Object.assign(props, {
      component: Link,
      to: `/${chain}/liquidation/borrower/${address}`,
    });
  }

  const displayAddress = (
    <Group spacing={0} noWrap>
      {showIcon ? (
        <Blockies
          seed={toChecksumAddress(address)}
          size={6}
          scale={3}
          className="blockie"
        ></Blockies>
      ) : null}
      {virtual ? (
        <TdText {...props}>
          {address.split(":")[0]}:{shrink(address.split(":")[1])}
        </TdText>
      ) : (
        <TdText {...props}>
          {name ?? (fullAddress ? address : shrink(address))}
        </TdText>
      )}
    </Group>
  );

  const renderTags = (tagType: string) => {
    return (
      <Tooltip label="MEV-BOT" withArrow>
        <Box
          sx={(theme) => ({
            marginLeft: "10px",
          })}
        >
          <Bot />
        </Box>
      </Tooltip>
    );
  };

  const allLabels = labelList
    ?.sort((p, n) => (/swap/.test(p) ? -1 : 1))
    ?.map((label) => {
      return ["mev-bot"].includes(label.toLowerCase()) ? (
        renderTags(label.toLowerCase())
      ) : (
        <Badge
          color="gray"
          variant="light"
          key={`bdg-${label}`}
          size="xs"
          sx={(theme) => ({
            marginLeft: "10px",
            fontFamily: '"Consolas", Menlo, monospace',
            fontWeight: 400,
            backgroundColor: "rgba(84,180,152,0.10)",
            color: "#54B498",
          })}
        >
          {label}
        </Badge>
      );
    });

  const LabelPopover = ({ target }: { target: React.ReactNode }) => (
    <EpPopover
      opened={opened}
      onClose={() => setOpened(false)}
      position={isSmallScreen ? "bottom" : "right"}
      // placement="center"
      withArrow
      transition="pop-top-left"
      target={target}
      // styles={{ body: { pointerEvents: "none" } }}
    >
      {allLabels}
    </EpPopover>
  );

  const haveProtocol =
    protocol?.showName && (protocol?.id || protocol?.icon) && protocol.website;

  const addressText = (
    <TdText
      sx={{
        whiteSpace: "nowrap",
      }}
    >
      {prefix}

      {showMoreLabelStyle === "hover-label" || !labelList?.length ? (
        displayAddress
      ) : (
        <LabelPopover
          target={
            <span
              onMouseEnter={() => setOpened(true)}
              onMouseLeave={() => setOpened(false)}
            >
              {displayAddress}
            </span>
          }
        />
      )}
    </TdText>
  );

  let label = `${chain === "bsc" ? "BscScan" : "EtherScan"}`;
  if (typeof external === "object") {
    label = `${external.etherscanName}`;
  }

  const wrap = (
    <Group spacing={0} noWrap={!isSmallScreen}>
      {external ? (
        <Tooltip
          label={`View on ${label}`}
          position={"top"}
          // placement="center"
          withArrow
          // styles={{
          //   body: {
          //     pointerEvents: "none",
          //     textAlign: "center",
          //   },
          // }}
        >
          {addressText}
        </Tooltip>
      ) : (
        addressText
      )}
      {haveProtocol ? (
        <>
          <Space w={6} />
          <Tooltip label={protocol?.showName} withArrow>
            <Anchor
              target="_blank"
              href={protocol.website}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <Image
                width={16}
                height={16}
                styles={{
                  image: {
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.white
                        : "transparent",
                  },
                }}
                radius={8}
                alt={protocol?.showName}
                src={
                  protocol?.id
                    ? `https://storage.googleapis.com/eigenphi-token-icons/protocols/${protocol?.id}.png`
                    : protocol.icon
                        ?.replace(
                          "https://eigenphi.io/images/tokens/protocols/",
                          "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                        )
                        ?.replace(
                          "/images/tokens/protocols/",
                          "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                        )
                }
              />
            </Anchor>
          </Tooltip>
        </>
      ) : null}
      {labelList?.length && !haveProtocol && !lpIcon ? (
        <Box sx={{ lineHeight: 1 }}>
          {showMoreLabelStyle === "hover-label" ? (
            showAllLabels ? (
              allLabels
            ) : labelList?.[0] ? (
              ["mev-bot"].includes(labelList[0].toLowerCase()) ? (
                renderTags(labelList[0].toLowerCase())
              ) : (
                <LabelPopover
                  target={
                    <Badge
                      color="gray"
                      variant="light"
                      size="xs"
                      sx={(theme) => ({
                        marginLeft: "6px",
                        fontFamily: '"Consolas", Menlo, monospace',
                        fontWeight: 400,
                        backgroundColor: "rgba(84,180,152,0.10)",
                        color: "#54B498",
                      })}
                      onMouseEnter={() =>
                        labelList?.length > 1 && setOpened(true)
                      }
                      onMouseLeave={() => setOpened(false)}
                    >
                      {labelList[0].toUpperCase() === "MINER" &&
                      !isMinerText(blockNumber!, chain)
                        ? "Fee Recipient"
                        : labelList[0]}{" "}
                      {labelList.length > 1 ? "..." : ""}
                    </Badge>
                  }
                />
              )
            ) : null
          ) : null}
        </Box>
      ) : null}
      {lpIcon ? (
        <Tooltip label={lpName} withArrow>
          <Image
            width={16}
            height={16}
            src={lpIcon}
            sx={{ marginLeft: isSmallScreen ? 5 : 10 }}
          />
        </Tooltip>
      ) : null}
      {tag ? <TdText size="xs">({tag})</TdText> : null}
    </Group>
  );

  return wrap;
});
