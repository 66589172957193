import { stringifyUrl } from "query-string";
import {
  MarketOverview,
  MarketOverviewParams,
  MarketOverviewResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchMarketOverview(params: MarketOverviewParams) {
  const url = "/api/v1/arbitrage/stat/overview/";

  const endpoint = stringifyUrl({ url, query: params });

  const overview = await normalizeResponse<
    MarketOverviewResponseJson,
    MarketOverview
  >(fetch(endpoint), (data) => {
    return data.result;
  });

  if (!overview.transactions) {
    throw new Error("market not found");
  }

  return overview;
}
