import {
  Text,
  Box,
  Group,
  Divider,
  Badge,
  Center,
  useMantineTheme,
  GroupPosition,
  Tooltip,
} from "@mantine/core";
import React, { memo, useEffect, useRef, useState } from "react";
import { Token } from "../api/type";
import { TdText } from "./EpTable";
import Icon from "./Icon";
import { LightMore, DarkMore, TokenIcon } from "./EpIcon";
import { shrink } from "./Utils";
import EpPopover from "./EpPopover";

export const Protocols = memo(
  ({
    dark,
    protocols,
  }: {
    dark: boolean;
    protocols: Array<{ id: number; icon: string }>;
  }) => {
    if (protocols.length < 12) {
      return (
        <Group spacing={2} sx={{ flex: "1 !important" }}>
          {protocols.map((protocol, idx) => {
            return (
              <TokenIcon
                key={idx}
                src={
                  protocol?.id
                    ? `https://storage.googleapis.com/eigenphi-token-icons/protocols/${protocol?.id}.png`
                    : protocol.icon
                        ?.replace(
                          "https://eigenphi.io/images/tokens/protocols/",
                          "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                        )
                        ?.replace(
                          "/images/tokens/protocols/",
                          "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                        )
                }
                alt=""
                size={16}
              />
            );
          })}
        </Group>
      );
    }

    const obj: { [key: string]: number } = {};
    protocols?.forEach(({ id, icon }) => {
      obj[id] = (obj[id] || 0) + 1;
      obj[icon] = (obj[icon] || 0) + 1;
    });

    return (
      <Group spacing={10} sx={{ flex: "1 !important" }}>
        {Object.keys(obj).map((icon, idx) => {
          return (
            <Group key={idx} noWrap spacing={2}>
              <TokenIcon key={idx} src={icon} alt="" size={16} />
              <Text size="xs" color={dark ? "#B5B5C3" : "#868E96"}>
                x
              </Text>
              <Text size="xs" color={dark ? "#B5B5C3" : "#868E96"}>
                {obj[icon]}
              </Text>
            </Group>
          );
        })}
      </Group>
    );
  }
);

export const TokenIcons = memo(
  ({
    tokens,
    chain,
    size,
    dark,
    maxCount = 4,
    noWrap,
    hideTip,
  }: {
    tokens: Array<Token>;
    chain: string;
    size?: number;
    dark?: boolean;
    maxCount?: number;
    noWrap?: boolean;
    hideTip?: boolean;
  }) => {
    const iconSize = size || 16;

    const isHide = tokens?.length > maxCount;
    const wrap = (
      <Box sx={{ display: "inline-block", marginLeft: 6 }}>
        <Box sx={{ display: "flex", flexWrap: noWrap ? "nowrap" : "wrap" }}>
          {tokens?.slice(0, maxCount)?.map((token, idx) => {
            const icon = token?.address ? (
              <Center
                sx={(theme) => ({
                  zIndex: idx,
                  height: iconSize + 3,
                  width: iconSize + 3,
                  marginLeft: -6,
                  borderRadius: "50%",
                  overflow: "hidden",
                  background:
                    theme.colorScheme === "dark" ? "#000000" : "#FFFFFF",
                })}
              >
                <TokenIcon
                  src={`https://storage.googleapis.com/eigenphi-token-icons/${chain}/${token.address}.png`}
                  alt={token?.name ?? shrink(token?.address)}
                  size={iconSize}
                />
              </Center>
            ) : null;

            return token?.address ? (
              hideTip ? (
                icon
              ) : (
                <Tooltip key={idx} label={token?.symbol}>
                  {icon}
                </Tooltip>
              )
            ) : null;
          })}
          {isHide ? (
            <Center
              sx={(theme) => ({
                width: iconSize + 3,
                height: iconSize + 3,
                marginLeft: -6,
                background:
                  theme.colorScheme === "dark" ? "#000000" : "#FFFFFF",
                borderRadius: "50%",
                zIndex: 100,
              })}
            >
              {dark ? <DarkMore /> : <LightMore />}
            </Center>
          ) : null}
        </Box>
      </Box>
    );

    return <Box sx={{ display: "flex", alignItems: "center" }}>{wrap}</Box>;
  }
);

const tokenMaxCount = 30;

export const TxTokenLps = memo(
  ({
    chain,
    dark,
    tokens,
    lpCount,
    tokenCount,
    tradingVenuesCount,
    isSandwich,
    isArbitrage,
    commonLpData,
    protocols,
  }: {
    chain: string;
    dark: boolean;
    tokens: Array<Token>;
    lpCount: number;
    tokenCount: number;
    tradingVenuesCount: number;
    isSandwich: boolean;
    isArbitrage: boolean;
    commonLpData: any;
    protocols: Array<{ id: number; icon: string }>;
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
      const height = (ref?.current as any)?.clientHeight || 0;
      setHeight(height);
    }, []);

    const isForceWrap = height > 30;

    const realTokenCount = tokens?.length || 0;

    return (
      <Group sx={{ flexDirection: "column" }} align="flex-start" spacing={8}>
        {!realTokenCount && !lpCount ? null : (
          <Group
            ref={ref}
            align="flex-start"
            sx={{ flexDirection: isForceWrap ? "column" : "row" }}
          >
            {tokens ? (
              <TokenIcons
                tokens={tokens}
                chain={chain}
                dark={dark}
                maxCount={tokenMaxCount}
              />
            ) : null}
            <Group
              noWrap
              sx={{ alignItems: isForceWrap ? "flex-start" : "center" }}
            >
              {realTokenCount && !isForceWrap ? (
                <Divider
                  variant="dashed"
                  color={dark ? "#868E96" : "#CED4DA"}
                  sx={{ width: 30 }}
                />
              ) : null}
              <Badge
                size="sm"
                radius={4}
                sx={{
                  padding: "0 4px",
                  background: dark ? "rgba(255,255,255,0.06)" : "#E9ECEF",
                }}
              >
                <Group spacing={2} noWrap>
                  <Icon
                    name="icon-location"
                    size={12}
                    color={dark ? "#868E96" : "#B5B5C3"}
                  />
                  {isSandwich ? (
                    <TokenIcon
                      src={
                        commonLpData?.protocol?.id
                          ? `https://storage.googleapis.com/eigenphi-token-icons/protocols/${commonLpData?.protocol?.id}.png`
                          : commonLpData?.protocol?.icon
                              ?.replace(
                                "https://eigenphi.io/images/tokens/protocols/",
                                "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                              )
                              ?.replace(
                                "/images/tokens/protocols/",
                                "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                              )
                      }
                      alt=""
                      size={16}
                    />
                  ) : (
                    <TdText sx={{ color: dark ? "#B5B5C3" : "#868E96" }}>
                      {tradingVenuesCount}
                    </TdText>
                  )}
                </Group>
              </Badge>
              {isArbitrage ? (
                <Protocols dark={dark} protocols={protocols} />
              ) : null}
            </Group>
          </Group>
        )}
        {realTokenCount && !isForceWrap ? (
          <TdText
            sx={{
              maxWidth: 260,
              whiteSpace: "normal",
              color: dark ? "#868E96" : "#B5B5C3",
            }}
          >
            {tokens
              .slice(0, tokenMaxCount)
              ?.map((token: any) =>
                token?.symbol?.length > 10
                  ? shrink(token?.symbol, 6, 4)
                  : token?.symbol
              )
              .join(" / ")}
            {realTokenCount > tokenMaxCount ? " /..." : ""}
          </TdText>
        ) : null}
        {isArbitrage ? (
          <TdText
            sx={{ color: dark ? "#B5B5C3" : "#464E5F", whiteSpace: "normal" }}
          >
            {tokenCount || 0} tokens swapped in {tradingVenuesCount || 0}{" "}
            trading venues.
          </TdText>
        ) : commonLpData?.protocol?.name ? (
          <TdText
            sx={{ color: dark ? "#B5B5C3" : "#464E5F", whiteSpace: "normal" }}
          >
            Sandwich attack in {commonLpData?.protocol?.name}
          </TdText>
        ) : null}
      </Group>
    );
  }
);

export const EpTxTypeNew = memo(
  ({
    chain,
    tx,
    noWrap,
    direction,
    withinPortal,
  }: {
    chain: string;
    tx: {
      type: string;
      tokens?: Array<Token>;
      useFlashloan?: boolean;
      poolsInfo?: Array<any>;
      lpCount: number;
      tokenCount: number;
    };
    noWrap?: boolean;
    direction?: "row" | "column";
    withinPortal?: boolean;
  }) => {
    const theme = useMantineTheme();

    const dark = theme.colorScheme === "dark";
    const { type, useFlashloan, poolsInfo = [], tokenCount } = tx;

    const isArbitrage = type.toLocaleLowerCase() === "arbitrage";
    const isSandwich = type.toLocaleLowerCase() === "sandwich";

    const commonLpData: any = poolsInfo[0] || {};

    let tokens = tx.tokens;
    if (isSandwich) {
      const tokenAddressArr = commonLpData.tokens;
      tokens = tokenAddressArr
        ?.map((address: string) => {
          const token = tx?.tokens?.find((item) => item.address === address);
          return token;
        })
        .filter(Boolean);
    }

    const lpCount = poolsInfo?.length;

    const typeIconName =
      {
        Arbitrage: "icon-arbitrage",
        Sandwich: "icon-sandwich",
        Liquidation: "icon-liquidation",
      }[type] || "";

    const wrap = (
      <Group
        spacing={5}
        noWrap
        sx={{
          marginLeft: -10,
          padding: "3px 10px",
          "&:hover": {
            background: "rgba(68,215,182,0.10)",
            borderRadius: 8,
          },
        }}
      >
        {tokens ? (
          <TokenIcons
            noWrap
            tokens={tokens}
            chain={chain}
            dark={dark}
            maxCount={4}
            hideTip
          />
        ) : null}
        <Divider
          variant="dashed"
          color={dark ? "#868E96" : "#CED4DA"}
          sx={{ width: 10 }}
        />
        <Badge
          size="sm"
          radius={4}
          sx={{
            padding: "0 4px",
            background: dark ? "rgba(255,255,255,0.06)" : "#F8F9FA",
          }}
        >
          <Group spacing={2} noWrap>
            <Icon
              name="icon-location"
              size={12}
              color={dark ? "#868E96" : "#B5B5C3"}
            />
            {isSandwich ? (
              <TokenIcon
                src={
                  commonLpData?.protocol?.id
                    ? `https://storage.googleapis.com/eigenphi-token-icons/protocols/${commonLpData?.protocol?.id}.png`
                    : commonLpData?.protocol?.icon
                        ?.replace(
                          "https://eigenphi.io/images/tokens/protocols/",
                          "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                        )
                        ?.replace(
                          "/images/tokens/protocols/",
                          "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                        )
                }
                alt=""
                size={16}
              />
            ) : (
              <TdText sx={{ color: dark ? "#B5B5C3" : "#868E96" }}>
                {tx.lpCount}
              </TdText>
            )}
          </Group>
        </Badge>
      </Group>
    );

    return (
      <Group spacing={4} noWrap align="flex-start">
        <Icon
          name={typeIconName}
          size={16}
          color={dark ? "#B5B5C3" : "#7E8299"}
        />
        <Group
          spacing={8}
          noWrap={noWrap}
          sx={{ flexDirection: direction || "row", alignItems: "flex-start" }}
        >
          <Group spacing={4} noWrap>
            <TdText sx={{ color: dark ? "#B5B5C3" : "#7E8299" }}>{type}</TdText>
            {useFlashloan ? (
              <Icon name="icon-flash" size={16} color="#FFC800" />
            ) : null}
          </Group>
          <EpPopover
            position="top"
            transition="pop-top-left"
            target={wrap}
            withArrow
            withinPortal={withinPortal}
          >
            <TxTokenLps
              chain={chain}
              dark={dark}
              tokens={tokens!}
              lpCount={lpCount}
              tokenCount={tokenCount}
              tradingVenuesCount={tx.lpCount}
              isSandwich={isSandwich}
              isArbitrage={isArbitrage}
              commonLpData={commonLpData}
              protocols={poolsInfo.map(({ protocol }) => protocol)}
            />
          </EpPopover>
        </Group>
      </Group>
    );
  }
);

export function EpTxType({
  tx,
  position = "right",
}: {
  tx: {
    type: string;
    lpCount: number;
    tokenCount: number;
  };
  position: GroupPosition;
}) {
  const { type, lpCount, tokenCount } = tx;

  const badgeStyle: React.CSSProperties = {
    border: "1px solid #54B498",
    color: "#54B498",
    fontSize: 10,
    padding: "1px 6px",
    borderRadius: "4px",
  };

  return (
    <Group
      align={position === "right" ? "flex-end" : "flex-start"}
      sx={{ flexDirection: "column" }}
      spacing={0}
    >
      <TdText>{type}</TdText>
      {type?.toLocaleLowerCase() === "arbitrage" ? (
        <Group noWrap spacing={4}>
          <EpPopover
            position="top"
            // placement="center"
            withArrow
            transition="pop-top-left"
            // styles={{ body: { pointerEvents: "none" } }}
            target={<span style={badgeStyle}>Tokens({tokenCount})</span>}
          >
            <Text
              size="xs"
              sx={(theme) => ({
                color:
                  theme.colorScheme === "dark"
                    ? theme.white
                    : theme.colors.dark[6],
              })}
            >
              {tokenCount} tokens swapped in {lpCount} trading venues.
            </Text>
          </EpPopover>

          <EpPopover
            position="top"
            withArrow
            transition="pop-top-left"
            target={
              <span style={{ ...badgeStyle, marginLeft: 5 }}>
                Venues({lpCount})
              </span>
            }
          >
            <Text
              size="xs"
              sx={(theme) => ({
                whiteSpace: "nowrap",
                color:
                  theme.colorScheme === "dark"
                    ? theme.white
                    : theme.colors.dark[6],
              })}
            >
              Trading venues
            </Text>
          </EpPopover>
        </Group>
      ) : null}
    </Group>
  );
}
