import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  TransactionLeaderBoardParams,
  TransactionLeaderBoardItem,
  TransactionLeaderBoardResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchTransactionLeaderboard(
  params: TransactionLeaderBoardParams
) {
  const url = "/api/v1/arbitrage/stat/txProfitLeaderboard/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    TransactionLeaderBoardResponseJson<typeof params.period>,
    Array<TransactionLeaderBoardItem>
  >(fetch(endpoint), (data) => {
    return (data?.result?.data ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
