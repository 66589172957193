import { stringifyUrl } from "query-string";
import type {
  LiquidationTrendsParams,
  LiquidationTrend,
  LiquidationTrendsResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationTrend(options: LiquidationTrendsParams) {
  const url = "/api/v1/dataservice/liquidation/trend/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationTrendsResponseJson,
    {
      rows: Array<LiquidationTrend>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: data?.result?.data,
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
