import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationTopAssetItem,
  LiquidationTopAssetParams,
  LiquidationTopAssetResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationTopAsset(
  options: LiquidationTopAssetParams
) {
  const url = "/api/v1/dataservice/liquidation/topLiquidatedAsset/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationTopAssetResponseJson,
    Array<LiquidationTopAssetItem>
  >(fetch(endpoint), (data) => {
    return (data?.result?.data ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
