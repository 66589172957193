import { stringifyUrl } from "query-string";
import type {
  ContractFavouriteTokenParams,
  ContractFavouriteTokenItem,
  ContractFavouriteTokenResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchContractFavouriteTokens(
  options: ContractFavouriteTokenParams
) {
  const url = "/api/v1/arbitrage/stat/favouriteTokens/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    ContractFavouriteTokenResponseJson,
    {
      rows: Array<ContractFavouriteTokenItem>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: data?.result?.data,
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
