import { ColorScheme, MantineTheme } from "@mantine/core";
import Highcharts from "highcharts";

const tooltip = {
  shared: true,
  useHTML: true,
  headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
  pointFormat:
    "<tr><td><span style='color: {point.color};font-size: 14px'>●</span> {series.name}:</td>" +
    '<td style="text-align: right"><b>{point.y}</b></td></tr>',
  footerFormat: "</table>",
};

export function HighchartOption({
  colorScheme,
  theme,
}: {
  colorScheme: ColorScheme;
  theme: MantineTheme;
}) {
  Highcharts.setOptions(
    colorScheme === "light"
      ? {
          lang: {
            thousandsSep: ",",
          },
          tooltip,
          chart: {
            backgroundColor: theme.white,
            animation: false,
          },
          colors: [
            "#F6BD16",
            "#69DFAE",
            "#7262fd",
            "#A5DE37",
            "#FF7B62",
            "#78D3F8",
            "#9661BC",
            "#F6903D",
            "#65789B",
            "#F08BB4",
            "#237ADF",
            "#BEC0E7",
            "#CC48B7",
            "#868644",
            "#4A4FB6",
            "#5B8FF9",
            "#2CAD81",
            "#F45B5B",
          ],
          loading: {
            style: {
              backgroundColor: theme.white,
            },
          },
          title: {
            style: {
              color: theme.colors.dark[6],
            },
          },
          subtitle: {
            style: {
              color: theme.colors.dark[7],
            },
          },
          legend: {
            itemStyle: {
              color: theme.colors.dark[7],
            },
            itemHoverStyle: {
              color: theme.colors.dark[1],
            },
          },
          xAxis: {
            lineColor: "#CFDDEE",
            gridLineColor: theme.colors.dark[2],
          },
          yAxis: {
            gridLineColor: theme.colors.gray[3],
          },
          credits: {
            enabled: false,
            href: `https://${window.location.host}`,
            text: "EigenPhi",
            style: {
              fontSize: "24px",
              opacity: 0.6,
            },
            position: {
              align: "center",
              verticalAlign: "middle",
            },
          },
          plotOptions: {
            series: {
              animation: false,
            },
            column: {
              borderWidth: 0,
            },
          },
        }
      : {
          lang: {
            thousandsSep: ",",
          },
          tooltip,
          chart: {
            backgroundColor: theme.colors.dark[7],
            animation: false,
          },
          colors: [
            "#F6BD16",
            "#69DFAE",
            "#7262fd",
            "#A5DE37",
            "#FF7B62",
            "#78D3F8",
            "#9661BC",
            "#F6903D",
            "#65789B",
            "#F08BB4",
            "#237ADF",
            "#BEC0E7",
            "#CC48B7",
            "#868644",
            "#4A4FB6",
            "#5B8FF9",
            "#2CAD81",
            "#F45B5B",
          ],
          loading: {
            style: {
              backgroundColor: theme.black,
            },
          },
          title: {
            style: {
              color: theme.colors.dark[0],
            },
          },
          subtitle: {
            style: {
              color: theme.colors.dark[1],
            },
          },
          legend: {
            itemStyle: {
              color: "#B5B5C3",
            },
            itemHoverStyle: {
              color: theme.colors.dark[1],
            },
          },
          xAxis: {
            lineColor: "#868E96",
            gridLineColor: theme.colors.dark[4],
            labels: {
              style: {
                color: theme.colors.dark[1],
              },
            },
          },
          yAxis: {
            gridLineColor: theme.colors.dark[4],
          },
          credits: {
            enabled: false,
            href: `https://${window.location.host}`,
            text: "EigenPhi",
            style: {
              fontSize: "24px",
              opacity: 0.6,
            },
            position: {
              align: "center",
              verticalAlign: "middle",
            },
          },
          plotOptions: {
            series: {
              animation: false,
            },
            column: {
              borderWidth: 0,
            },
          },
        }
  );

  return null;
}

export default HighchartOption;

export const chartColors = {
  blue: "#009CFA",
  green: "#49CE87",
  yellow: "#FFC800",
  red: "#F43E6A",
  purple: "#722DEE",
  lightBlue: "#39CED8",
  lightPurple: "#A370FF",
};

// 基础颜色
// const colors = [
//   chartColors.blue,
//   chartColors.green,
//   chartColors.yellow,
//   chartColors.red,
//   chartColors.purple,
//   chartColors.lightBlue,
//   "#58A3E4",
//   "#6083E3",
//   "#8478E3",
//   "#AD76E3",
//   "#EF6C93",
//   "#F06C71",
//   "#EE916F",
//   "#CCDF6B",
//   "#A5DF6C",
//   "#78E06C",
//   "#717171",
// ];

// 柱状图+1条线的颜色
export const columnColors = [
  chartColors.blue,
  chartColors.green,
  chartColors.yellow,
  chartColors.purple,
  chartColors.lightBlue,
  "#58A3E4",
  "#6083E3",
  "#8478E3",
  "#AD76E3",
  "#EF6C93",
  "#F06C71",
  "#EE916F",
  "#CCDF6B",
  "#A5DF6C",
  "#78E06C",
];
export const leftYAxisColor = "#717171";

// treemap颜色
export const treemapColors = [
  "#4DC3E2",
  "#58A3E4",
  "#8478E3",
  "#AD76E3",
  "#6083E3",
  "#F06C71",
  "#EE916F",
  "#EBB46E",
  "#E9D46B",
  "#CCDF6B",
  "#A5DF6C",
  "#78E06C",
  "#55E17C",
  "#47E3C2",
  "#FFEB80",
  "#EF6C93",
];

// 饼图颜色
export const pieColors = [
  chartColors.blue,
  chartColors.green,
  chartColors.yellow,
  chartColors.red,
  chartColors.purple,
  chartColors.lightBlue,
  "#58A3E4",
  "#6083E3",
  "#8478E3",
  "#AD76E3",
  "#EF6C93",
  "#F06C71",
  "#EE916F",
  "#CCDF6B",
  "#A5DF6C",
  "#78E06C",
];

// EigenTx
export const eigenTxLightColors = [
  "#009CFA",
  "#007138",
  "#C69B31",
  // "#F43E6A",
  "#722DEE",
  "#54A9B1",
  "#004474",
  "#4F77BF",
  "#494AA2",
  "#737CC1",
  "#BD0017",
  "#D45469",
  "#A8676C",
  "#948F40",
  "#817546",
  "#B49E85",
];
export const eigenTxDrakColors = [
  "#009CFA",
  "#49CE87",
  "#FFC800",
  // "#F43E6A",
  "#722DEE",
  "#39CED8",
  "#58A3E4",
  "#6083E3",
  "#8478E3",
  "#AD76E3",
  "#EF6C93",
  "#CB4838",
  "#EE916F",
  "#CCDF6B",
  "#A5DF6C",
  "#78E06C",
];
