import { stringifyUrl } from "query-string";
import {
  MevOverviewItem,
  MevOverviewResponseJson,
  MevOverviewParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchMevOverview(params: MevOverviewParams) {
  const url = "/api/v1/transfer/stat/mev/overview/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    MevOverviewResponseJson,
    Array<MevOverviewItem>
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
