import { stringifyUrl } from "query-string";
import {
  MarketTopProtocolsItem,
  MarketTopProtocolsParams,
  MarketTopProtocolsResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchMarketTopProtocols(
  params: MarketTopProtocolsParams
) {
  const url = "/api/v1/transfer/stat/protocol/leaderboard/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    MarketTopProtocolsResponseJson,
    Array<MarketTopProtocolsItem>
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
