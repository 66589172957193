import { stringifyUrl } from "query-string";
import {
  FeedbackLikeParams,
  FeedbackLikeResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchFeedbackLike(params: FeedbackLikeParams) {
  const url = "/api/v1/dataservice/feedback/like";

  
  const endpoint = stringifyUrl({ url, query: params });
  const likeCount = await normalizeResponse<
    FeedbackLikeResponseJson,
    number
  >(fetch(endpoint, {
    method: 'POST',
  }), (data) => {
    return data.result;
  });

  return likeCount;
}
