import { stringifyUrl } from "query-string";
import type {
  LiquidationLiquidatorOverviewResponseJson,
  LiquidationLiquidatorOverview,
  LiquidationLiquidatorOverviewParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationLiquidatorOverview(
  options: LiquidationLiquidatorOverviewParams
) {
  const url = "/api/v1/dataservice/liquidation/liquidatorOverview/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationLiquidatorOverviewResponseJson,
    LiquidationLiquidatorOverview
  >(fetch(endpoint), (data) => {
    return data?.result?.data;
  });
}
