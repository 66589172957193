import { memo } from "react";
import {
  useNavigate as useInterNavigate,
  Link as InterLink,
} from "react-router-dom";

export const ROUTER_BASE = "mev";

const getNewUrl = (url: string) => {
  if (url === "/" || !url) return "/";

  if (url.startsWith(`/${ROUTER_BASE}/`)) {
    return url;
  }

  return "/" + ROUTER_BASE + url;
};

export const useNavigate = () => {
  const navigate = useInterNavigate();

  return (url: string, ...rest: any) => {
    // console.log("url", url);

    const newUrl = getNewUrl(url);

    navigate(newUrl, ...rest);
  };
};

export const Link = memo(({ to, ...restProps }: any) => {
  // console.log("to", to);

  const newUrl = getNewUrl(to);

  return <InterLink to={newUrl} {...restProps} />;
});
