import { stringifyUrl } from "query-string";
import type {
  LParbitrageTypeDistributionItem,
  LPTransactionParams,
  LParbitrageTypeDistributionResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchLPArbitrageTypeDistribution(
  params: LPTransactionParams
) {
  const url = "/api/v1/arbitrage/stat/lp/arbitrageTypeDistribution/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    LParbitrageTypeDistributionResponseJson,
    {
      rows: Array<LParbitrageTypeDistributionItem>;
      updateTimestamp: number;
      updateBlockNumber: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      rows: data?.result?.data,
      updateTimestamp: data?.result?.updateTimestamp,
      updateBlockNumber: data?.result?.updateBlockNumber,
    };
  });
}
