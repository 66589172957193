import { stringifyUrl } from "query-string";
import type {
  SandwichDailyReport,
  SandwichDailyReportResponseJson,
  SandwichDailyReportParams,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchSandwichDailyReport(
  options: SandwichDailyReportParams
) {
  const url = "/api/v1/report/sandwichDailyReport";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichDailyReportResponseJson,
    SandwichDailyReport
  >(fetch(endpoint), (data) => {
    const { code, message } = data as any;
    return { ...data?.result, code, message } ?? { code, message };
  });
}
