import { stringifyUrl } from "query-string";
import { FundFlowItem, FundFlowParams, FundFlowResponseJson } from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchFundFlowAll(params: FundFlowParams) {
  const url = "/api/v1/protocol/stat/fund-flow/all";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<FundFlowResponseJson, Array<FundFlowItem>>(
    fetch(endpoint),
    (data) => {
      return data.result;
    }
  );
}
