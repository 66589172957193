import { stringifyUrl } from "query-string";
import type {
  GenericParams,
  LiquidPool,
  LiquidPoolResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchLp(options: GenericParams) {
  const url = "/api/v1/dataservice/lp/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidPoolResponseJson,
    LiquidPool
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
