import { stringifyUrl } from "query-string";
import {
  MarketTopTransfersItem,
  MarketTopTransfersParams,
  MarketTopTransfersResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchMarketTopTransfers(
  params: MarketTopTransfersParams
) {
  const url = "/api/v1/transfer/stat/leaderboard/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    MarketTopTransfersResponseJson,
    Array<MarketTopTransfersItem>
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
