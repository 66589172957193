import { stringifyUrl } from "query-string";
import {
  ProtocolBasicInfo,
  ProtocolBasicInfoParams,
  ProtocolBasicInfoResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchProtocolBasicInfo(params: ProtocolBasicInfoParams) {
  const url = "/api/v1/protocol/stat/profile/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    ProtocolBasicInfoResponseJson,
    ProtocolBasicInfo
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
