import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import {
  AppShell,
  Container,
  Box,
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
  useMantineTheme,
} from "@mantine/core";
import React, { useState, Suspense, useEffect } from "react";
import { QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { Footer } from "./Footer";
import { queryClient } from "./QueryClient";
import { useIsSmallScreen, useIsSmallerThanLG } from "./Components/Utils";
import { GlobalStatusProvider, useGlobalStatusContext } from "./GlobalStore";
import ScrollToTop from "./Components/ScrollToTop";
import StateFromURL from "./StateFromURL";
import Navbar, { NAVBAR_COLLAPSE_WIDTH, NAVBAR_EXPAND_WIDTH } from "./Navbar";
import Header from "./Header";
import { EpPageProvider } from "./Components/EpPage";
import HomePage from "./Home/HomePage";
import HighchartOption from "./Components/Highcharts";
import { PageLoadingFallback } from "./Components/LoadingFallback";
import BackTop from "./Components/BackTop";
import PageTracking from "./Components/PageTracking";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase-config";
import { ROUTER_BASE } from "./Components/Link";
import ScreenshotFlag from "./Components/ScreenshotFlag";
import { getAnalytics } from "firebase/analytics";
import { CompatibleEigentxRedirect } from "./EigenTx/CompatibleRedirect";

function App() {
  const isSmallScreen = useIsSmallScreen();
  const isSmallerThanLG = useIsSmallerThanLG();

  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    (localStorage.getItem("colorScheme") as ColorScheme) ?? "dark"
  );
  const toggleColorScheme = (value?: ColorScheme) => {
    const newColorScheme = value || (colorScheme === "dark" ? "light" : "dark");
    localStorage.setItem("colorScheme", newColorScheme);
    setColorScheme(newColorScheme);
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const theme = useMantineTheme();

  const Banner = React.lazy(() => import("./CommonWidget/Banner"));
  const ContractPage = React.lazy(() => import("./Contract/ContractPage"));
  const TransactionFlowPage = React.lazy(
    () => import("./Transaction/TransactionFlowPage")
  );
  const TokenDetailPage = React.lazy(() => import("./Token/TokenDetailPage"));
  const PoolDetailPage = React.lazy(() => import("./Pool/PoolDetailPage"));
  const PoolPage = React.lazy(() => import("./Pool/PoolPage"));
  const TokenPage = React.lazy(() => import("./Token/TokenPage"));
  const NotFoundPage = React.lazy(() => import("./OtherPage/NotFoundPage"));
  const ErrorPage = React.lazy(() => import("./OtherPage/ErrorPage"));
  const TransactionRealtimePage = React.lazy(
    () => import("./Transaction/TransactionRealtimePage")
  );
  const SandwichSubjectPage = React.lazy(
    () => import("./Sandwich/SubjectPage")
  );
  const SandwichAttackerDetailPage = React.lazy(
    () => import("./Sandwich/AttackerDetailPage")
  );
  const SandwichVictimDetailPage = React.lazy(
    () => import("./Sandwich/VictimDetailPage")
  );
  const SandwichSearchPage = React.lazy(() => import("./Sandwich/SearchPage"));
  const LiquidationMarketPage = React.lazy(
    () => import("./Liquidation/MarketPage")
  );
  const LiquidationProtocolPage = React.lazy(
    () => import("./Liquidation/ProtocolPage")
  );
  const LiquidationBorrowerPage = React.lazy(
    () => import("./Liquidation/BorrowerPage")
  );
  const LiquidationLiquidatorPage = React.lazy(
    () => import("./Liquidation/LiquidatorPage")
  );
  const LiquidationDetailPage = React.lazy(
    () => import("./Liquidation/TransactionFlowPage")
  );
  const FlashloanMarketPage = React.lazy(
    () => import("./Flashloan/MarketPage")
  );
  const LendingHomePage = React.lazy(() => import("./Lending/HomePage"));
  const LendingUserPage = React.lazy(() => import("./Lending/UserPage"));
  const SearchResultPage = React.lazy(() => import("./Search/ResultPage"));
  const DailyReportPage = React.lazy(
    () => import("./DailyReport/DailyReportPage")
  );
  const AwesomeTxPage = React.lazy(
    () => import("./Activity/AwesomeTx/AwesomeTxPage")
  );
  const BugImprovementBountyPage = React.lazy(
    () => import("./Activity/BugImprovementBounty/BugImprovementBountyPage")
  );
  const MemeContestPage = React.lazy(
    () => import("./Activity/MemeContest/MemeContestPage")
  );

  const EigenTxSearchPage = React.lazy(() => import("./EigenTx/SearchPage"));
  const EigenTxPage = React.lazy(() => import("./EigenTx/TxPage"));
  const EigenTxChartTweetPic = React.lazy(
    () => import("./EigenTx/TxChartTweetPic")
  );

  const CmsPage = React.lazy(() => import("./cms/HomePage"));
  const CmsContentPage = React.lazy(() => import("./cms/ContentPage"));
  const CmsListPage = React.lazy(() => import("./cms/ListPage"));

  function MainRouter() {
    const { navExpand, setNavExpand, navVisibleOnSmallScreen, chain } =
      useGlobalStatusContext();
    const isNavExpand = navExpand !== undefined ? navExpand : !isSmallerThanLG;
    const isNavVisible =
      !isSmallScreen || (isSmallScreen && navVisibleOnSmallScreen);

    const resize = () => {
      if (navExpand !== undefined && !isSmallScreen) {
        setNavExpand(undefined);
      }
    };
    useEffect(() => {
      window.addEventListener("resize", resize);
      return () => {
        window.removeEventListener("resize", resize);
      };
    });

    useEffect(() => {
      let classVal = document?.getElementById("root")?.getAttribute("class");

      // 删除root的class
      classVal = classVal?.replace("root-center", "");
      document?.getElementById("root")?.setAttribute("class", classVal || "");
    });

    const left = isNavVisible
      ? isNavExpand
        ? NAVBAR_EXPAND_WIDTH
        : NAVBAR_COLLAPSE_WIDTH
      : 0;

    return (
      <Router>
        <HighchartOption colorScheme={colorScheme} theme={theme} />
        <PageTracking />
        <ScrollToTop />
        <StateFromURL />
        <ScreenshotFlag />
        {isSmallScreen ? null : <BackTop />}
        <Routes>
          <Route
            path="/report-admin"
            element={
              <Suspense fallback={<PageLoadingFallback />}>
                <EpPageProvider pageId="CMS">
                  <CmsPage />
                </EpPageProvider>
              </Suspense>
            }
          />
          <Route
            path="/report-admin/:path"
            element={
              <Suspense fallback={<PageLoadingFallback />}>
                <EpPageProvider pageId="CMS">
                  <CmsPage />
                </EpPageProvider>
              </Suspense>
            }
          />
          <Route path={`${ROUTER_BASE}`} element={<Outlet />}>
            <Route
              path="sandwich/search"
              element={
                <Suspense fallback={<PageLoadingFallback />}>
                  <EpPageProvider pageId="Sandwich Overview">
                    <SandwichSearchPage />
                  </EpPageProvider>
                </Suspense>
              }
            />
            <Route
              path="search"
              element={
                <Suspense fallback={<PageLoadingFallback />}>
                  <EpPageProvider>
                    <SandwichSearchPage />
                  </EpPageProvider>
                </Suspense>
              }
            />
            <Route
              path="bounty/AwesomeTx"
              element={
                <Suspense fallback={<PageLoadingFallback />}>
                  <EpPageProvider>
                    <AwesomeTxPage />
                  </EpPageProvider>
                </Suspense>
              }
            />
            <Route
              path="bounty/BugImprovement"
              element={
                <Suspense fallback={<PageLoadingFallback />}>
                  <EpPageProvider>
                    <BugImprovementBountyPage />
                  </EpPageProvider>
                </Suspense>
              }
            />
            <Route
              path="bounty/MemeContest"
              element={
                <Suspense fallback={<PageLoadingFallback />}>
                  <EpPageProvider>
                    <MemeContestPage />
                  </EpPageProvider>
                </Suspense>
              }
            />
            <Route
              path="eigentx/tweetpic/:txhash"
              element={
                <Suspense fallback={<PageLoadingFallback />}>
                  <EpPageProvider>
                    <EigenTxChartTweetPic />
                  </EpPageProvider>
                </Suspense>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <AppShell
                fixed
                header={<Header isExtend={isNavExpand} />}
                navbar={
                  isNavVisible ? <Navbar isExtend={isNavExpand} /> : undefined
                }
                sx={(theme) => ({
                  main: {
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.colors.gray[0],
                    padding: 0,
                    overflow: "hidden",
                    // minWidth: isSmallScreen ? "" : 1366,
                  },
                })}
              >
                <Box
                  hidden={isSmallScreen && navVisibleOnSmallScreen}
                  sx={(theme) => {
                    return {
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[8]
                          : theme.colors.gray[0],
                      position: "relative",
                      zIndex: 10,
                      marginTop: 80,
                      marginLeft: left,
                      boxShadow:
                        "0 1px 3px rgb(0 0 0 / 5%), rgb(0 0 0 / 5%) 0px 10px 15px -5px, rgb(0 0 0 / 4%) 0px 7px 7px -5px",
                    };
                  }}
                >
                  <Suspense
                    fallback={() => {
                      return "";
                    }}
                  >
                    <Banner />
                  </Suspense>
                  <Container
                    id="arbitrage-container"
                    className="arbitrage-container"
                    fluid
                    sx={{
                      paddingTop: 24,
                      paddingBottom: 24,
                      minHeight: window.innerHeight - 230,
                    }}
                    px={isSmallScreen ? 0 : "xl"}
                  >
                    <Routes>
                      <Route path={`${ROUTER_BASE}`} element={<Outlet />}>
                        <Route
                          path="eigentx"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="EigenTxSearch">
                                <EigenTxSearchPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="eigentx/:txhash"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="EigenTx">
                                <EigenTxPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="research"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Cms List Content">
                                <CmsListPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route
                        path="/report/:path"
                        element={
                          <Suspense fallback={<PageLoadingFallback />}>
                            <EpPageProvider pageId="Cms Content">
                              <CmsContentPage />
                            </EpPageProvider>
                          </Suspense>
                        }
                      ></Route>
                      <Route
                        path="/"
                        element={
                          <EpPageProvider pageId="Market Overview">
                            <HomePage />
                          </EpPageProvider>
                        }
                      />
                      <Route
                        path="/index.html"
                        element={
                          <Suspense fallback={<PageLoadingFallback />}>
                            <EpPageProvider>
                              <HomePage />
                            </EpPageProvider>
                          </Suspense>
                        }
                      />
                      <Route
                        path="/error"
                        element={
                          <Suspense fallback={<PageLoadingFallback />}>
                            <EpPageProvider>
                              <ErrorPage />
                            </EpPageProvider>
                          </Suspense>
                        }
                      />
                      <Route
                        path="/404"
                        element={
                          <Suspense fallback={<PageLoadingFallback />}>
                            <EpPageProvider>
                              <NotFoundPage />
                            </EpPageProvider>
                          </Suspense>
                        }
                      />
                      <Route
                        path={`${ROUTER_BASE}/:chainId`}
                        element={<Outlet />}
                      >
                        <Route
                          path="eigentx/:txhash"
                          element={<CompatibleEigentxRedirect />}
                        />
                        <Route
                          path=""
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider>
                                <HomePage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        ></Route>
                        <Route
                          path=":period"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider>
                                <HomePage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        ></Route>
                        <Route
                          path="contract/:address"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Contract Portfolio">
                                <ContractPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="tx/:txHash"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Arbitrage Transaction">
                                <TransactionFlowPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="lp/:address"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Liquidity Pool Profile">
                                <PoolDetailPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="pools/:poolType"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider>
                                <PoolPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="tokens/:tokenType"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider>
                                <TokenPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="token/:address"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Token Profile">
                                <TokenDetailPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="txr"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="MEV Live-Stream">
                                <TransactionRealtimePage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="sandwich"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Sandwich Overview">
                                <SandwichSubjectPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="sandwich/attacker/:address"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Sandwich Attacker Profile">
                                <SandwichAttackerDetailPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="sandwich/victim/:address"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Sandwich Victim Profile">
                                <SandwichVictimDetailPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="liquidation"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Liquidation Overview">
                                <LiquidationMarketPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="liquidation/protocol/:protocol"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Protocol Overview">
                                <LiquidationProtocolPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="liquidation/borrower/:borrower"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Borrower">
                                <LiquidationBorrowerPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="liquidation/liquidator/:liquidator"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Liquidator">
                                <LiquidationLiquidatorPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="liquidation/tx/:txHash"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Liquidation Transaction">
                                <LiquidationDetailPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="flashloan"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Flashloan">
                                <FlashloanMarketPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="lending"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Lending Overview">
                                <LendingHomePage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="lending/user/:user"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="Lending User">
                                <LendingUserPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="search"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider pageId="GlobalSearch">
                                <SearchResultPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="dailyReport"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider>
                                <DailyReportPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="dailyReport/:type"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider>
                                <DailyReportPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                        <Route
                          path="dailyReport/:type/:date"
                          element={
                            <Suspense fallback={<PageLoadingFallback />}>
                              <EpPageProvider>
                                <DailyReportPage />
                              </EpPageProvider>
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route path="*" element={<p>404 not found</p>} />
                    </Routes>
                  </Container>
                </Box>
                <Footer
                  chain={chain}
                  left={left}
                  isSmallScreen={isSmallScreen}
                />
              </AppShell>
            }
          />
        </Routes>
      </Router>
    );
  }

  return (
    <React.StrictMode>
      <GTMProvider
        state={{
          id: process.env.REACT_APP_GTM_ID,
        }}
      >
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colorScheme,
              fontFamily: "Verdana, sans-serif",
              fontFamilyMonospace: "Monaco, Courier, monospace",
              headings: { fontFamily: "Verdana, sans-serif" },
              colors: {
                EigenPhiGreen: [
                  "#D9F7F0",
                  "#B4EFE1",
                  "#8EE7D3",
                  "#69DFC4",
                  "#44D7B6",
                  "#54B498",
                  "#44957D",
                  "#367764",
                  "#28594B",
                  "#1B3B32",
                ],
              },
              primaryColor: "EigenPhiGreen",
              components: {
                Anchor: {
                  styles: (theme) => ({
                    root: {
                      color:
                        theme.colorScheme === "dark"
                          ? theme.white
                          : theme.colors.dark[6],
                    },
                  }),
                },
                Menu: {
                  styles: (theme) => ({
                    root: { display: "flex" },
                    item: {
                      "&:hover": {
                        backgroundColor: "rgba(68,215,182,0.10) !important",
                        color: "#44D7B6",
                      },
                    },
                    itemLabel: {
                      width: "100%",
                    },
                    dropdown: {
                      boxShadow:
                        theme.colorScheme === "dark"
                          ? "0px 4px 12px 0px #000000"
                          : "0px 4px 12px 0px #e9ecef",
                      borderRadius: 8,
                    },
                  }),
                },
                Tabs: {
                  styles: (theme) => ({
                    tabsList: {
                      position: "relative",
                      zIndex: 100,
                    },
                  }),
                },
                Modal: {
                  styles: (theme) => ({
                    root: {
                      zIndex: 201,
                    },
                  }),
                },
              },
            }}
          >
            <QueryClientProvider client={queryClient}>
              <GlobalStatusProvider>
                <MainRouter />
              </GlobalStatusProvider>
            </QueryClientProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </GTMProvider>
    </React.StrictMode>
  );
}

export default App;
