import { stringifyUrl } from "query-string";
import {
  ProtocolOverview,
  ProtocolOverviewParams,
  ProtocolOverviewResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchProtocolOverview(params: ProtocolOverviewParams) {
  const url = "/api/v1/protocol/stat/overview/";

  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    ProtocolOverviewResponseJson,
    ProtocolOverview
  >(fetch(endpoint), (data) => {
    return data.result;
  });
}
