import React from "react";

const Icon = React.memo(
  ({
    name,
    size,
    color,
    style,
  }: {
    name: string;
    size?: number;
    color?: string;
    style?: React.CSSProperties;
  }) => {
    return (
      <i
        className={`iconfont ${name}`}
        style={{ fontSize: size || 16, color, ...style }}
      />
    );
  }
);

export default Icon;
