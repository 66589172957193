import { stringifyUrl } from "query-string";
import type {
  ArbitrageBehaviorParams,
  ArbitrageBehaviorResult,
  ArbitrageBehaviorResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchArbitrageBehavior(options: ArbitrageBehaviorParams) {
  const url = "/api/v1/arbitrage/stat/arbitrageBehavior/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    ArbitrageBehaviorResponseJson,
    ArbitrageBehaviorResult
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
