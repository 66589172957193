import { stringifyUrl } from "query-string";
import type {
  SandwichVictimOverviewParams,
  SandwichVictimOverview,
  SandwichVictimOverviewResponseJson,
} from "../type";
import { normalizeResponse } from "../_helpers";

export async function fetchSandwichVictimOverview(
  options: SandwichVictimOverviewParams
) {
  const url = "/api/v1/dataservice/sandwich/victim/overview/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    SandwichVictimOverviewResponseJson,
    SandwichVictimOverview
  >(fetch(endpoint), (data) => {
    return data?.result;
  });
}
