import React, { useState } from "react";
import { Box, Group, Tooltip } from "@mantine/core";
import { shrink } from "./Utils";
import { Link } from "./Link";
import { TdText } from "./EpTable";
import Icon from "./Icon";
import { FloatingPosition } from "@mantine/core/lib/Floating/types";

export function TxDisplay({
  children,
  chain,
  tx,
  fullTx,
  browser,
  prefix,
  failed,
  hideLink,
  name,
  liquidation,
  eigentx,
  isError,
  customLabel,
  position,
  targetBlank,
}: {
  tx: string;
  chain: string;
  children?: React.ReactNode;
  fullTx?: boolean;
  browser?: boolean | { etherscanName: string; etherscanUrlPrefix: string };
  prefix?: string;
  failed?: boolean;
  hideLink?: boolean;
  name?: string;
  liquidation?: boolean;
  eigentx?: boolean;
  isError?: boolean;
  customLabel?: string;
  position?: FloatingPosition;
  targetBlank?: boolean;
}) {
  const content = name ? name : fullTx ? tx : shrink(tx);
  const [externalTips, setExternalTips] = useState(false);

  const props = {};

  if (!hideLink) {
    if (browser || failed) {
      let href = `https://${
        chain === "bsc" ? "bscscan.com" : "etherscan.io"
      }/tx/${tx}`;

      if (typeof browser === "object") {
        href = `${browser.etherscanUrlPrefix}/tx/${tx}`;
      }

      Object.assign(props, {
        component: "a",
        rel: "noreferrer",
        target: "_blank",
        href,
      });
    } else if (liquidation) {
      Object.assign(props, {
        component: Link,
        to: `/${chain}/liquidation/tx/${tx}`,
      });
    } else if (eigentx) {
      Object.assign(props, {
        component: Link,
        to: `/eigentx/${tx}`,
        target: targetBlank ? "_blank" : "",
      });
    } else {
      Object.assign(props, {
        component: Link,
        to: `/${chain}/tx/${tx}`,
        target: targetBlank ? "_blank" : "",
      });
    }
  }

  const wrap = (
    <Group noWrap spacing={4}>
      {isError ? (
        <Tooltip
          label="Warning! Error encountered during contract execution [execution reverted]"
          position="top-start"
          withArrow
          multiline
          width={220}
        >
          <Box>
            <Icon name="icon-alert1" color="#F43E6A" />
          </Box>
        </Tooltip>
      ) : null}
      <Box
        onMouseEnter={() => setExternalTips(true)}
        onMouseLeave={() => setExternalTips(false)}
      >
        <TdText
          {...props}
          sx={{
            whiteSpace: "nowrap",
            fontFamily: '"Consolas", Menlo, monospace',
          }}
        >
          {children ? (
            children
          ) : (
            <>
              {prefix}
              {content}
            </>
          )}
        </TdText>
      </Box>
    </Group>
  );

  let label = `${chain === "bsc" ? "BscScan" : "EtherScan"}`;
  if (typeof browser === "object") {
    label = `${browser.etherscanName}`;
  }

  return failed || browser ? (
    <Tooltip
      opened={externalTips}
      label={`View on ${label}`}
      position={"top"}
      withArrow
    >
      {wrap}
    </Tooltip>
  ) : customLabel ? (
    <Tooltip
      opened={externalTips}
      label={customLabel}
      position={position || "top"}
      withArrow
    >
      {wrap}
    </Tooltip>
  ) : (
    wrap
  );
}
