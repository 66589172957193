import React, { useState, useEffect, useRef, forwardRef, memo } from "react";
import {
  Group,
  Autocomplete,
  ActionIcon,
  Loader,
  SelectItemProps,
  Box,
  Tooltip,
  Space,
  Image,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useIsSmallScreen } from "../Components/Utils";
import { useLocation } from "react-router-dom";
import { useGlobalStatusContext } from "../GlobalStore";
import { useNavigate } from "./Link";
import { useQuery } from "react-query";
import { GlobalSearchParams, GlobalSearchItem, Protocol } from "../api/type";
import { TokenDisplay } from "./TokenDisplay";
import { TdText, ThText } from "./EpTable";
import { AmountDisplay } from "./AmountDisplay";

export const getUrlPath = (types: Array<string>) => {
  if (types.includes("Token")) {
    return "token";
  }

  if (types.includes("LiquidityPool")) {
    return "lp";
  }

  if (types.includes("Liquidator")) {
    return "liquidation/liquidator";
  }

  if (types.includes("Liquidation")) {
    return "liquidation/tx";
  }

  if (types.includes("LiquidatedBorrower")) {
    return "liquidation/borrower";
  }

  if (types.includes("LendingBorrower")) {
    return "lending/user";
  }

  if (types.includes("SandwichVictim") && !types.includes("Transaction")) {
    return "sandwich/victim";
  }

  if (types.includes("SandwichAttacker") && !types.includes("Transaction")) {
    if (
      types.includes("Contract") &&
      !window.location.pathname.includes("sandwich")
    ) {
      return "contract";
    }
    return "sandwich/attacker";
  }

  if (types.includes("Transaction")) {
    return "tx";
  }

  if (types.includes("Contract")) {
    return "contract";
  }

  return "";
};

interface ItemProps extends SelectItemProps {
  chain: string;
  address: string;
  type: "Token" | "LiquidityPool";
  name: string;
  tokens: Array<{
    address: string;
    decimals: number;
    symbol: string;
    poolRemaining: number;
  }>;
  protocol: Protocol;
}

const LiquidityPoolItem = memo(
  ({
    tokens,
    protocol,
  }: {
    tokens: Array<{
      address: string;
      decimals: number;
      symbol: string;
      poolRemaining: number;
    }>;
    protocol: Protocol;
  }) => {
    const names = tokens.map((token) => token.symbol).join("/");

    const lpNames = (
      <Group noWrap spacing={0} sx={{ width: 88 }}>
        <Box style={{ width: 72, overflow: "hidden" }}>
          <TdText>{names}</TdText>
        </Box>
        {names.length > 10 ? "..." : ""}
      </Group>
    );

    return (
      <Group noWrap position="apart" sx={{ width: "100%" }}>
        <Box sx={{ whiteSpace: "nowrap" }}>
          {tokens.length ? null : "-"}
          <Group noWrap spacing="xs">
            {protocol?.website &&
            protocol?.showName &&
            (protocol.id || protocol.icon) ? (
              <Tooltip label={protocol?.showName} withArrow>
                <Image
                  radius={8}
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  styles={(theme) => ({
                    image: {
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.white
                          : "transparent",
                    },
                  })}
                  alt={protocol.showName}
                  src={
                    protocol.id
                      ? `https://storage.googleapis.com/eigenphi-token-icons/protocols/${protocol.id}.png`
                      : protocol.icon
                          ?.replace(
                            "https://eigenphi.io/images/tokens/protocols/",
                            "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                          )
                          ?.replace(
                            "/images/tokens/protocols/",
                            "https://storage.googleapis.com/eigenphi-token-icons/protocols/"
                          )
                  }
                />
              </Tooltip>
            ) : null}
            <Group noWrap spacing="xs">
              {names.length > 10 ? (
                <Tooltip label={names} withArrow>
                  {lpNames}
                </Tooltip>
              ) : (
                lpNames
              )}
            </Group>
          </Group>
        </Box>
        <Group noWrap>
          <ThText sx={{ whiteSpace: "normal" }}>Pool Remaining</ThText>
          <Group sx={{ flexDirection: "column" }} spacing={2}>
            <TdText>
              {tokens[0].symbol?.length > 6
                ? `${tokens[0].symbol.slice(0, 6)}...`
                : tokens[0].symbol}
              :
              {tokens[0].poolRemaining ? (
                <AmountDisplay digits={2} value={tokens[0].poolRemaining} />
              ) : (
                "-"
              )}
            </TdText>
            <TdText>
              {tokens[1].symbol?.length > 6
                ? `${tokens[1].symbol.slice(0, 6)}...`
                : tokens[1].symbol}
              :
              {tokens[1].poolRemaining ? (
                <AmountDisplay digits={2} value={tokens[1].poolRemaining} />
              ) : (
                "-"
              )}
            </TdText>
          </Group>
        </Group>
      </Group>
    );
  }
);

const TokenItem = memo(
  ({
    chain,
    address,
    symbol,
    name,
  }: {
    chain: string;
    address: string;
    symbol: string;
    name: string;
  }) => {
    const isSmallScreen = useIsSmallScreen();
    const nameCount = isSmallScreen ? 20 : 30;
    const symbolCount = isSmallScreen ? 12 : 15;

    return (
      <Group noWrap spacing={0}>
        <TokenDisplay
          token={{
            chain,
            address: address,
            name: symbol,
            symbol: symbol,
          }}
          showName={false}
        />
        <Space w={4} />
        {(name ?? "").length > nameCount ? (
          <Tooltip label={name}>
            <TdText>{`${name.slice(0, nameCount)}...`}</TdText>
          </Tooltip>
        ) : (
          <TdText>{name}</TdText>
        )}
        {(symbol ?? "").length > symbolCount ? (
          <Tooltip label={symbol}>
            <TdText>({`${symbol?.slice(0, symbolCount)}...`})</TdText>
          </Tooltip>
        ) : (
          <TdText>({symbol})</TdText>
        )}
      </Group>
    );
  }
);

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      chain,
      address,
      value,
      name,
      type,
      tokens,
      protocol,
      ...others
    }: ItemProps,
    ref
  ) => {
    const renderItemContent = () => {
      if (type === "Token") {
        return (
          <TokenItem
            name={name}
            address={address}
            symbol={value!}
            chain={chain}
          />
        );
      }

      if (type === "LiquidityPool") {
        return <LiquidityPoolItem tokens={tokens} protocol={protocol} />;
      }

      return null;
    };

    return (
      <div ref={ref} {...others}>
        {renderItemContent()}
      </div>
    );
  }
);

function GlobalSearch({
  onSearch,
}: {
  onSearch?: ({ query }: { query: string }) => void;
}) {
  const [query, setQuery] = useState<string>("");
  const [debounced] = useDebouncedValue(query, 500);
  const isSmallScreen = useIsSmallScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const { chain, setPeriod } = useGlobalStatusContext();

  const searchedRef = useRef(false); // 是否点击了搜索按钮

  const isHash = query?.toLowerCase().startsWith("0x");

  const resetState = () => {
    setQuery("");
  };

  useEffect(() => {
    resetState();
  }, [location]);

  useEffect(() => {
    if (debounced && !debounced.toLowerCase().startsWith("0x")) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced]);

  const { refetch, isLoading, data } = useQuery<
    {
      total: number;
      data: Array<GlobalSearchItem>;
    },
    GlobalSearchParams
  >(
    [
      "fetchGlobalSearchResult",
      {
        chain,
        q: query,
      },
    ],
    {
      enabled: false,
      refetchOnMount: false,
      retry: false,
      onSuccess(result) {
        // console.log("onSuccess");
        // 如果用户没有确认搜索只是联想数据，则不进入后面逻辑
        if (!searchedRef.current) return;

        searchedRef.current = false;

        if (
          result?.data[0]?.type.includes("Transaction") &&
          result?.data[0]?.dataAvailable === false
        ) {
          navigate(`/eigentx/${query}`);
        } else {
          if (result?.data?.length === 1 && result?.data[0]?.dataAvailable) {
            const [item] = result?.data || [];

            const urlPath = getUrlPath(item.type);

            if (urlPath) {
              if (
                [
                  "LiquidatedBorrower",
                  "SandwichVictim",
                  "LendingBorrower",
                ].includes(item.type[0])
              ) {
                setPeriod("30");
              }

              navigate(`/${chain}/${urlPath}/${item.redirect}`);
            } else {
              navigate(`/${chain}/search?q=${query}`);
            }
          } else {
            navigate(`/${chain}/search?q=${query}`);
          }
        }
      },
      onError() {
        searchedRef.current = false;
      },
    }
  );

  const handleSearch = () => {
    if (onSearch) {
      onSearch({ query });
      return;
    }

    searchedRef.current = true;

    if (!query) {
      navigate(`/${chain}/search?q=${query}`);
    } else {
      refetch();
    }
  };

  const options = data?.data?.map((item: any, idx: number) => {
    const { type } = item;
    const isToken = type[0] === "Token";

    return {
      key: idx,
      chain,
      value: (isToken ? item?.token?.symbol : item?.liquidityPool?.name) ?? "",
      address: isToken ? item?.token?.address : item?.liquidityPool?.address,
      type: type[0],
      tokens: isToken ? [] : item?.liquidityPool?.tokens,
      name: isToken ? item?.token?.name : "",
      protocol: isToken ? {} : item?.liquidityPool?.protocol,
      group: isToken ? "Token" : "Liquidity Pool",
    };
  });
  // console.log('options', options)
  return (
    <Group spacing={0} noWrap sx={{ flexGrow: 1, justifyContent: "center" }}>
      <Autocomplete
        data={isHash ? [] : options ?? []}
        itemComponent={AutoCompleteItem}
        filter={(value, item) => true}
        sx={() => (isSmallScreen ? { width: "100%" } : { flex: "0 1 440px" })}
        dropdownPosition="flip"
        value={query}
        limit={30}
        maxDropdownHeight={300}
        styles={{
          input: {
            "&::-webkit-input-placeholder": {
              fontSize: "12px",
            },
            "&::-moz-placeholder": {
              fontSize: "12px",
            },
          },
        }}
        size="sm"
        placeholder="Search by Address/Txn Hash/Symbol"
        onChange={(value) => {
          setQuery(value);
        }}
        // 注意：在对联想数据进行回车操作的时候，会触发onKeyDown和onItemSubmit这两个回调，然后还区分不了
        onKeyDown={(event) => {
          if (event.key === "Enter" && !isLoading) {
            handleSearch();
          }
        }}
        onItemSubmit={(item) => {
          const urlPath = getUrlPath(item.type);

          // 临时方案：因为上面区分不了是什么操作，所以需要重置【是否搜索过】这个标识
          if (searchedRef.current) {
            searchedRef.current = false;
          }

          navigate(`/${chain}/${urlPath}/${item.address}`);
        }}
        rightSection={
          !isLoading ? (
            <ActionIcon
              size="xs"
              onClick={() => {
                handleSearch();
              }}
            >
              <MagnifyingGlassIcon />
            </ActionIcon>
          ) : (
            <Loader className="mantine-loader" size="xs" />
          )
        }
      />
    </Group>
  );
}

export default React.memo(GlobalSearch);
