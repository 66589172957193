import React, { useRef, useEffect } from "react";
import { Space, Box } from "@mantine/core";
import { useQuery } from "react-query";
import {
  TPeriod,
  TransactionProfitDistributionDoc,
  TransactionProfitDistributionItem,
  TransactionProfitDistributionParams,
} from "../api/type";
import { useGlobalStatusContext } from "../GlobalStore";
import { EpAlert } from "../Components/EpMessage";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FromNow } from "../Components/FromNow";
import { chartColors, leftYAxisColor } from "../Components/Highcharts";
import { orderBy, limit, where } from "firebase/firestore";
import { useFireStoreQuery } from "../Components/useFireStoreQuery";

export const TranscationProfitDistribution = React.memo(function ({
  chain,
  address,
}: {
  chain?: string;
  address?: string;
}) {
  const { period, navExpand } = useGlobalStatusContext();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  let dbPath = `/${chain}/${period}d/tx_profit_distribution`;
  if (address) {
    dbPath = `/${chain}/${period}d/contract_tx_profit_distribution/default/${address}`;
  }

  const { data, isLoading } =
    useFireStoreQuery<TransactionProfitDistributionDoc>({
      path: dbPath,
    });
  /*
  const { data, isLoading } = useQuery<
    {
      rows: Array<TransactionProfitDistributionItem>;
      updateTimestamp: number;
      updateBlockNumber: number;
    },
    TransactionProfitDistributionParams
  >([
    "fetchTransactionProfitDistribution",
    {
      period,
      chain,
      toAddress: address,
    },
  ]);
  */

  useEffect(() => {
    if (isLoading) {
      chartComponentRef.current?.chart.showLoading();
    } else {
      chartComponentRef.current?.chart.hideLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    chartComponentRef.current?.chart.reflow();
  }, [navExpand]);

  if (!isLoading && !data?.data?.length) {
    return <EpAlert>Data Not Found!</EpAlert>;
  }

  const options: Highcharts.Options = {
    chart: {
      height: 402,
    },
    title: {
      style: {
        display: "none",
      },
    },
    plotOptions: {
      line: {},
      areaspline: {
        tooltip: {
          valueDecimals: 2,
          valuePrefix: "$",
        },
        dataLabels: {
          enabled: true,
        },
      },
    },
    tooltip: {
      shared: true,
    },
    xAxis: [
      {
        categories: data?.data?.map((item) => item.range),
      },
    ],
    yAxis: [
      {
        title: {
          text: "Avg profit in range($)",
          style: {
            color: chartColors.green,
          },
        },
        labels: {
          style: {
            color: chartColors.green,
          },
        },
        opposite: true,
      },
      {
        title: {
          text: "Tx count in range",
          style: {
            color: leftYAxisColor,
          },
        },
        labels: {
          style: {
            color: leftYAxisColor,
          },
        },
      },
    ],
    series: [
      {
        name: "Avg profit in range($)",
        type: "areaspline",
        data:
          data?.data?.map((item) => parseFloat(item.profit.toFixed(2))) ?? [],
        zIndex: 10,
        color: chartColors.green,
        negativeColor: chartColors.red,
      },
      {
        name: "Tx count in range",
        type: "spline",
        data: data?.data?.map((item) => parseInt(item.count + "")) ?? [],
        yAxis: 1,
        color: chartColors.blue,
      },
    ],
  };

  return (
    <>
      <Space h={20} />
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
      <Box
        sx={(theme) => ({
          padding: "9px 20px",
          borderTop:
            theme.colorScheme === "dark"
              ? `1px solid ${theme.colors.dark[6]}`
              : `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <FromNow
          isLoading={isLoading}
          updateTimestamp={data?.updateTimestamp!}
          updateBlockNumber={data?.updateBlockNumber!}
        />
      </Box>
    </>
  );
});
export default TranscationProfitDistribution;
