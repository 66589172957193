import { stringifyUrl } from "query-string";
import { v4 as uuid } from "uuid";
import type {
  LiquidationLeaderBoardParams,
  LiquidationLeaderBoardItem,
  LiquidationLeaderBoardResponseJson,
} from "./type";
import { normalizeResponse } from "../../api/_helpers";

export async function fetchLiquidationLeaderBoard(
  options: LiquidationLeaderBoardParams
) {
  const url = "/api/v1/dataservice/liquidation/profit/leaderboard/";
  const endpoint = stringifyUrl({ url, query: options });

  return await normalizeResponse<
    LiquidationLeaderBoardResponseJson,
    Array<LiquidationLeaderBoardItem>
  >(fetch(endpoint), (data) => {
    return (data?.result?.data ?? []).slice(0, 10).map((item) => ({
      ...item,
      key: uuid(),
    }));
  });
}
