import { stringifyUrl } from "query-string";
import type {
  LPTransactionItem,
  LPTransactionParams,
  LPTransactionLatestV2ResponseJson,
} from "./type";
import { normalizeResponse } from "./_helpers";

export async function fetchLPLatestTransaction(params: LPTransactionParams) {
  const url = "/api/v2/arbitrage/lp/tx/";
  const endpoint = stringifyUrl({ url, query: params });

  return await normalizeResponse<
    LPTransactionLatestV2ResponseJson,
    {
      list: Array<LPTransactionItem>;
      total: number;
    }
  >(fetch(endpoint), (data) => {
    return {
      list: data?.result?.realTimeTransactionDtos,
      total: data?.result?.totalCount,
    };
  });
}
